import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { result } from '@showtime/utility';
import './_tabs.scss';

import { TAB_TYPES, TAB_TYPE_MATERIAL } from './constants';
import TabsNav from './TabsNav';

export class TabsComponent extends Component {
  static propTypes = {
    type: PropTypes.oneOf(TAB_TYPES),
    // Content placed next to Tab navigation
    tabActions: PropTypes.node,
    // Content placed at the end of Tabs header container
    actions: PropTypes.node,
    active: PropTypes.number,
    onChangeTab: PropTypes.func,
    // Children must be nodes of type Tab, i.e. <Tab />
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    // Render header element only, i.e. the Tabs bar. Skip tab content rendering.
    tabsOnly: PropTypes.bool,
    // Content or render function in case 'active' does not match any tab
    fallbackTo: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]),
  };

  static defaultProps = {
    type: TAB_TYPE_MATERIAL,
    actions: false,
    tabActions: false,
    active: 0,
    onChangeTab: _noop,
    className: '',
    tabsOnly: false,
    fallbackTo: undefined,
  };

  state = {
    active: this.props.active,
    activeMemo: this.props.active,
  };

  static getDerivedStateFromProps({ active }, { activeMemo }) {
    if (active !== activeMemo) {
      return {
        active,
        activeMemo: active,
      };
    }

    return null;
  }

  getTabs() {
    const tabs = [];
    React.Children.forEach(this.props.children, (tab) => {
      if (React.isValidElement(tab)) {
        tabs.push(tab);
      }
    });

    return tabs;
  }

  selectTab = (active) => {
    const prevActive = this.state.active;
    this.setState({ active }, () => this.props.onChangeTab(this.state.active, prevActive));
  }

  renderTabs() {
    const tabs = this.getTabs();

    return (
      <TabsNav
        type={this.props.type}
        tabs={tabs}
        active={this.state.active}
        onChangeTab={this.selectTab}
      />
    );
  }

  renderContent() {
    const { fallbackTo } = this.props;
    const { active } = this.state;
    // TODO - just render active OR render all and toggle display?
    // For now, just render active...
    const tabs = this.getTabs();
    const tab = tabs[active];

    if (!tab && fallbackTo) {
      return (
        <div className="sta-tabs__container fill-content">
          {result(fallbackTo, active)}
        </div>
      );
    }

    return <div className="sta-tabs__container fill-content">{tab || tabs[0]}</div>;
  }

  render() {
    const { type, className, tabActions, actions, tabsOnly } = this.props;

    return (
      <section className={`sta-tabs sta-tabs--${type} ${className}`}>
        <header className="sta-tabs__header">
          <div className="sta-tabs__header-left">
            <div className="sta-tabs__tabs-holder">
              {this.renderTabs()}
            </div>
            {tabActions}
          </div>
          {actions && <div className="sta-tabs__actions">{actions}</div>}
        </header>
        {!tabsOnly && this.renderContent()}
      </section>
    );
  }
}

export default TabsComponent;
