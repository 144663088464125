import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import FormField from '../FormField';
import Input from '../../Input';
import { withMessages, messagesType } from '../../../utility';

const defaultMessages = {
  invalidUrl: 'Oops - It looks like you entered an invalid URL. Please check the URL and try again.',
  requiredField: 'This field is required',
};

// Match: /^(?!https?\:\/\/)(.*)/gi
// Replace: http://$1
const propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onValid: PropTypes.func,
  customErrorMessage: PropTypes.func,
  formGroupClassName: PropTypes.string,
  subText: PropTypes.string,
  errorText: PropTypes.string,
  inline: PropTypes.bool,
  labelWidth: PropTypes.string,
  placeholder: PropTypes.string,
  messages: messagesType.isRequired,
  status: PropTypes.string,
  inputDescription: PropTypes.node,

  autoAddProtocol: PropTypes.bool,
};

const defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  onValid: _noop,
  customErrorMessage: _noop,
  formGroupClassName: '',
  subText: '',
  errorText: '',
  placeholder: '',
  inline: false,
  labelWidth: null,
  status: '',
  inputDescription: null,

  autoAddProtocol: true,
};

/**
 * The replacement value should default to HTTP as HTTPS is not supported by all websites
 * and those that do should redirect from HTTP to HTTPS automatically if they support it.
*/
const URL_DEFAULT_PREFIX = 'http://';

const hasUrlProtocol = url => url.match(/^https?:\/\//);

/**
* addProtocol
* @param {string} rawUrl directly from input.
* @param {boolean} autoAddProtocol
* Checks if the current rawUrl has http:// or https://
* If autoAddProtocol = true and if it does not already
* have http:// or https:// - `http://` will be added.
* Otherwise nothing will be added
* @returns {string}
*/
const addProtocol = (rawUrl, autoAddProtocol = true) => {
  if (rawUrl && autoAddProtocol && !hasUrlProtocol(rawUrl)) {
    return URL_DEFAULT_PREFIX.concat(rawUrl);
  }
  return rawUrl;
};

/**
 * UrlField
 * TODO Unit Tests would be very beneficial here for the validation
 * @param {boolean} autoAddProtocol- default true. Auto adds the protocol to given urls.
 * @param {callbackFunc} onValid- string url value. DEFAULT_PREFIX will be added if autoAddProtocol = true.
*/
class UrlField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorText: props.errorText,
      inputFieldValue: props.value,
    };

    this.input = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorText !== this.props.errorText) {
      this.setErrorText();
    }
  }

  setErrorText = () => this.setState({ errorText: this.props.errorText });

  /**
   * @param {string} rawUrl - url directly from input.
   */
  validate = (rawUrl) => {
    const { autoAddProtocol } = this.props;

    const urlToValidate = addProtocol(rawUrl, autoAddProtocol);
    const validity = this.input.getInputNode().validity;

    let errorText = '';
    if (!rawUrl) {
      errorText = (this.props.required) ? this.props.messages.requiredField : '';
    } else if (!hasUrlProtocol(urlToValidate)) {
      errorText = this.props.messages.invalidUrl;
    } else if (!autoAddProtocol && !validity.valid) {
      // Can only check validity.valid when !autoAddProtocol
      // because url input types enforce the existance of a protocol in order to be valid
      errorText = this.props.messages.invalidUrl;
    } else {
      errorText = this.props.customErrorMessage(urlToValidate);
    }

    this.setState({ errorText });
    if (!errorText) {
      this.setState({ inputFieldValue: rawUrl });
      this.props.onValid(urlToValidate);
    }
  }

  checkStatus = () => {
    if (this.props.status) {
      return this.props.status;
    }
    return '';
    // this pattern isn't being used right now but may be in the future
    // return (!this.state.errorText && this.props.value) ? 'success' : '';
  }

  handleChangeInput = (url) => {
    this.props.onChange(url);
    this.validate(url);
  }

  render() {
    const {
      // Properties we know about (...yes, even if we're applying them to the Input!)
      label,
      subText,
      value,
      onChange,
      inputDescription,
      formGroupClassName,
      required,
      inline,
      labelWidth,
      placeholder,

      // Assume any other props are intended for the Input
      ...inputProps
    } = this.props;

    return (
      <FormField
        className={formGroupClassName}
        label={label}
        subText={subText}
        errorText={this.state.errorText}
        hasError={!!this.state.errorText}
        descriptionText={inputDescription}
        required={required}
        inline={inline}
        labelWidth={labelWidth}
      >
        <Input
          {...inputProps}
          required={required}
          status={this.checkStatus()}
          value={this.state.inputFieldValue}
          placeholder={placeholder}
          onChange={this.handleChangeInput}
          ref={(input) => {
            this.input = input;
          }}
          type="url"
        />
      </FormField>
    );
  }
}
UrlField.propTypes = propTypes;
UrlField.defaultProps = defaultProps;

export default withMessages('urlField', defaultMessages)(UrlField);
