import React, { Component } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';
import SortableItem from './SortableItem';
import './_sortable.scss';

/**
 * Sortable Item Component
 *
 * This component adds the prop items to it's internal state.
 * onHover over every sortableItem will trigger a reordering of this internal state.
 * Only when an item has finished dragging ( when endDrag fires) will the onChange method be fired to update outer prop.items
 * This is too limit the number of lifecycle changes:
   - we wouldn't want to update the outer props everytime an item has been hovered over, only when an item has been dropped.
 * @param {string} containerClassName - Add specific className to list container.
 * @param {array} items - array of items to be sorted including each child template.
 */
class SortableComponent extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    containerClassName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]).isRequired, // Unique Id used to reorder the list items
        template: PropTypes.oneOfType([ // The child template to be used.
          PropTypes.arrayOf(PropTypes.node),
          PropTypes.node,
        ]).isRequired,
      }),
    ).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.items, this.props.items)) {
      this.setState({ items: nextProps.items });
    }
  }

  /**
   * When the order has changed this returns an array of the ids in their new order.
   */
  onChangeListOrder = () => {
    this.props.onChange(this.state.items.map(item => item.id));
  }

  moveItem = (id, atIndex) => {
    this.setState((prevState) => {
      const { item, index } = this.findItem(id, prevState.items);
      return update(this.state, {
        items: {
          $splice: [[index, 1], [atIndex, 0, item]],
        },
      });
    });

  }

  findItem = (id, items = this.state.items) => {
    const index = _findIndex(items, ['id', id]);
    if (index === -1) {
      return null;
    }

    return {
      item: items[index],
      index,
    };
  }

  render() {
    const { connectDropTarget, containerClassName } = this.props;

    return connectDropTarget(
      <ul className={`spr-sortable ${containerClassName}`}>
        {this.state.items.map(item => (
          <SortableItem
            key={item.id}
            id={item.id}
            moveItem={this.moveItem}
            findItem={this.findItem}
            onChangeListOrder={this.onChangeListOrder}
          >
            {item.template}
          </SortableItem>
        ))}
      </ul>,
    );
  }
}

export default DropTarget(
  'sortableItem',
  {
    drop() {
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(SortableComponent);
