import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES, BUTTON_SIZES } from '../Button/Button';
import { getUid } from './utils';
import { withMessages, messagesType } from '../../utility';

class FileProvider extends Component {
  static propTypes = {
    accept: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node,
    onSelect: PropTypes.func.isRequired,
    buttonClassName: PropTypes.string,
    buttonType: PropTypes.oneOf(BUTTON_TYPES),
    buttonSize: PropTypes.oneOf(BUTTON_SIZES),
    messages: messagesType.isRequired,
  };

  static defaultProps = {
    children: undefined,
    buttonClassName: 'primary',
    buttonType: 'link',
    buttonSize: 'xs',
  };

  static defaultMessages = {
    uploadFile: 'Upload file',
  };

  state = {
    uid: getUid(),
  };

  handleClick = (e) => {
    e.stopPropagation();
    const el = this.fileInput;
    if (!el) {
      return;
    }
    el.click();
  };

  uploadFiles = (files) => {
    const postFiles = Array.prototype.slice.call(files);
    postFiles.forEach((file) => {
      this.props.onSelect({ file, filename: file.name });
    });
  };

  render() {
    const {
      accept,
      buttonClassName,
      buttonType,
      buttonSize,
      messages,
    } = this.props;

    return (
      <Fragment>
        <input
          type="file"
          ref={(el) => {
            this.fileInput = el;
          }}
          key={this.state.uid}
          style={{ display: 'none' }}
          accept={accept.join(',')}
          multiple={false} // TODO
          onChange={(e) => {
            const files = e.target.files;
            this.uploadFiles(files);
          }}
        />
        <Button
          type={buttonType}
          className={buttonClassName}
          size={buttonSize}
          onClick={this.handleClick}
        >
          {messages.uploadFile}
        </Button>
      </Fragment>
    )
  }
}

export default withMessages('uploader.file')(FileProvider);
