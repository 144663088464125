import React, { Component }from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend'
import { withMessages, messagesType } from '../../utility';
import './_uploader.scss';

const { FILE } = NativeTypes;

class DragDropProvider extends Component {
  static propTypes = {
    accept: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    messages: messagesType.isRequired,
  };

  static defaultMessages = {
    dragToUpload: 'Drag files to upload!',
  };

  render() {
    const {
      children,
      connectDropTarget,
      isOver,
      canDrop,
      messages,
    } = this.props;

    return connectDropTarget(
      <div className={classnames('spr-uploader__drag-drop', {
        'spr-uploader__drag-drop--can-drop': canDrop,
        'spr-uploader__drag-drop--is-over': canDrop && isOver,
      })}>
        <div className="spr-uploader__drag-drop__dropzone"></div>
        {!!children && (
          <div className="spr-uploader__drag-drop__content">
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withMessages('uploader.dragDrop'),
  DropTarget(
    [FILE],
    {
      drop(props, monitor) {
        if (props.onSelect) {
          monitor.getItem().files.forEach((file) => {
            props.onSelect({
              file,
              filename: file.name,
            });
          });
        }
      }
    },
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  ),
)(DragDropProvider);
