import React, {
  forwardRef,
  createContext,
  useContext,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { rootContainerCtx } from './RootContainer';

const ContainerContext = createContext(rootContainerCtx);

export const ContainerConsumer = ContainerContext.Consumer;

export const useContainer = () => useContext(ContainerContext);

/**
 * A plain element component with additional context information of ancestor element ref.
 * Primary use case is rendering portals to relevant ancestors. e.g.:
 * - tooltips inside popups like modals/popoverlays
 */
export const Container = forwardRef(function Container(props, ref) {
  const { tag: Tag, children, ...rest } = props;
  const [container, setContainer] = useState(null);

  useImperativeHandle(ref, () => container, [container]);

  const ctx = useMemo(
    () => ({
      container,
      renderAtContainer: element =>
        (element && container ? createPortal(element, container) : null),
    }),
    [container],
  );

  return (
    <Tag {...rest} ref={setContainer}>
      <ContainerContext.Provider value={ctx}>{children}</ContainerContext.Provider>
    </Tag>
  );
});

Container.propTypes = {
  /**
   * Must be a html element. (If a component, the ref must be forwarded to html element).
   */
  tag: PropTypes.elementType,
  children: PropTypes.node,
};

Container.defaultProps = {
  tag: 'div',
  children: null,
};
