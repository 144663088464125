import React from 'react';
import PropTypes from 'prop-types';
import paths from './paths';
import KawaiiFace from '../face/KawaiiFace';
import KawaiiWrapper from '../wrapper/KawaiiWrapper';
import { KAWAII_MOODS } from '../constants';
import './../_kawaii.scss';

const KawaiiTicket = ({
  size,
  color,
  mood,
}) =>
  <KawaiiWrapper
    style={{ width: size, height: size * 0.75 }}
    width={size}
    height={size * 0.75}
    color={color}
  >
    <svg
      width={size}
      height={size * 0.75}
      viewBox="0 0 162 121"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="kawaii-ticket">
        <g id="kawaii-ticket__body" transform="translate(48 8)">
          <path id="body" fill={color} d={paths.body} />
          <g id="separator" fill={color} transform="translate(0 70.344)">
            <path d={paths.separator1} />
            <path d={paths.separator2} />
            <path d={paths.separator3} />
            <path d={paths.separator4} />
            <path d={paths.separator5} />
          </g>
        </g>
        <g id="stars" fill={color} fillRule="nonzero">
          <path d={paths.star1} />
          <path d={paths.star2} />
          <path d={paths.star3} />
          <path d={paths.star4} />
          <path d={paths.star5} />
          <path d={paths.star6} />
        </g>
        <KawaiiFace
          mood={mood}
          color={color}
          transform="translate(58 44)"
        />
      </g>
    </svg>
  </KawaiiWrapper>;

KawaiiTicket.propTypes = {
  size: PropTypes.number,
  mood: PropTypes.oneOf(KAWAII_MOODS),
  color: PropTypes.string,
};

KawaiiTicket.defaultProps = {
  size: 200,
  mood: 'happy',
  color: '#C3C9D2',
};

export default KawaiiTicket;
