import React, { createContext, useContext } from 'react';
import { createPortal } from 'react-dom';

// If no context provider found. Document.Body serves as the 'base' container.
export const rootContainerCtx = {
  container: document.body,
  renderAtContainer: element => (element ? createPortal(element, document.body) : null),
};

const RootContainerContext = createContext(rootContainerCtx);

export const useRootContainer = () => useContext(RootContainerContext);

export const RootContainerConsumer = RootContainerContext.Consumer;
