import React from 'react';
import PropTypes from 'prop-types';
import _bindAll from 'lodash/bindAll';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';
import './_item-picker-field.scss';

import Button from '../Button';
import Modal from '../Modal';
import Input from '../Input';
import ListGroup from '../ListGroup';
import NoResult from '../NoResult';
import Spinner from '../Spinner';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  buttonLabel: 'Select',
  dialogTitle: 'Select',
  cancelDialog: 'Cancel',
  confirmDialog: 'Select',
};

/**
 * @deprecated -> DO NOT USE!
 * No longer fit for purpose - newer implementations have been created within products.
 *
 * In future a common replacement component should be created.
 *
 * Purpose of component is a button that opens a modal with a list of selectable options with ability to search the options.
 */
export class ItemPickerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
      selected: props.value,
      filteredOptions: props.options,
      searchTerm: '',
    };

    _bindAll(this, [
      'openDialog',
      'closeDialog',
      'removeItem',
      'handleSelect',
      'renderDialog',
      'renderSelected',
    ]);
    this.filterOptions = _debounce(this.filterOptions.bind(this), 300);

    this.searchInput = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && (this.props.isOpen !== nextProps.isOpen)) {
      this.openDialog();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      (!nextState.isOpen && this.state.isOpen) ||
      (!_isEqual(nextProps.value, this.props.value))
    ) {
      this.setState({
        selected: nextProps.value,
        filteredOptions: nextProps.options,
      });
    }

    if (!_isEqual(nextProps.options, this.props.options)) {
      this.setState({ filteredOptions: nextProps.options });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // focus the search field when showing the dialog or after loading occurred
    if ((!prevState.isOpen && this.state.isOpen)
      || (prevProps.isLoading && !this.props.isLoading)) {
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.getInputNode().focus();
        }
      });
    }
  }

  closeDialog() {
    this.setState({ isOpen: false });
    this.props.onRequestClose();
  }

  openDialog() {
    this.setState({ isOpen: true });
  }

  filterOptions(searchTerm) {
    this.setState({ searchTerm });
    this.props.onSearch(searchTerm);
  }

  removeItem(item) {
    const {
      value,
      valueKey,
      onChange,
    } = this.props;
    const selected = [...value];
    const index = selected.indexOf(item[valueKey]);
    if (index !== -1) {
      selected.splice(index, 1);
      onChange(selected);
    }
  }

  handleSelect() {
    this.props.onChange(this.state.selected);
    this.closeDialog();
  }

  renderDialog() {
    const {
      dialogSize,
      messages,
    } = this.props;

    return (
      <Modal
        className="item-picker"
        title={messages.dialogTitle}
        size={dialogSize}
        open={this.state.isOpen}
        onRequestClose={this.closeDialog}
        hasCloseAction
        actions={[
          <Button
            key="action.cancel"
            onClick={this.closeDialog}
            type="tertiary"
            size="md"
          >
            {messages.cancelDialog}
          </Button>,
          <Button
            key="action.confirm"
            type="primary"
            size="md"
            onClick={this.handleSelect}
          >
            {messages.confirmDialog}
          </Button>,
        ]}
      >
        {this.renderOptions()}
      </Modal>
    );
  }

  renderOptions() {
    const {
      nameKey,
      valueKey,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="item-picker-field__dialog__content">
        <Input
          className="text-field--search item-picker-field__search"
          onChange={this.filterOptions}
          ref={(el) => {
            this.searchInput = el;
          }}
          value={this.state.searchTerm}
        />
        {!this.state.filteredOptions.length && (
          <NoResult
            size="xs"
            bodyText={false}
          />
        )}
        {!!this.state.filteredOptions.length && (
          <ListGroup
            className="item-picker-field__options"
            multiple
            options={this.state.filteredOptions.map(option => ({
              text: option[nameKey],
              value: option[valueKey],
            }))}
            value={this.state.selected}
            onChange={selected => this.setState({ selected })}
          />
        )}
      </div>
    )
  }

  renderSelected() {
    const {
      value,
      options,
      nameKey,
      valueKey,
    } = this.props;

    const items = options.filter(option => value.indexOf(option[valueKey]) !== -1);

    return (
      <ul className="item-picker-field__selected">
        {items.map(item => (
          <li
            className="item-picker-field__selected__item"
            key={`list-item-${item[valueKey]}`}
          >
            <Button
              icon="icon-cross"
              type="link"
              onClick={() => this.removeItem(item)}
            />
            <span className="item-picker-field__selected__item__name">
              {item[nameKey]}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      messages,
      buttonIcon,
      buttonType,
      value,
      className,
    } = this.props;

    return (
      <div className={`item-picker-field ${className}`}>
        <Button
          onClick={() => this.setState({ isOpen: true })}
          type={buttonType}
          icon={buttonIcon}
        >
          {messages.buttonLabel}
        </Button>
        {this.renderDialog()}
        {!!value.length && this.renderSelected()}
      </div>
    );
  }
}

ItemPickerComponent.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  dialogSize: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nameKey: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  valueKey: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonType: PropTypes.string,
  isLoading: PropTypes.bool,
  onRequestClose: PropTypes.func,
  messages: messagesType.isRequired,
};

ItemPickerComponent.defaultProps = {
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  dialogSize: 'md',
  nameKey: 'name',
  value: [],
  valueKey: 'id',
  buttonIcon: null,
  buttonType: 'primary',
  isLoading: false,
  onRequestClose: null,
};

export default withMessages('itemPicker', defaultMessages)(ItemPickerComponent);
