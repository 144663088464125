import React from 'react';
import PropTypes from 'prop-types';
import './_topbar.scss';

const Topbar = ({ title, subTitle, buttons }) => (
  <header className="topbar cell-row cell-row--center">
    <section className="topbar__main cell cell-column">
      <h2 className="topbar__main__title">{ title }</h2>
      {subTitle && <h3 className="topbar__main__subtitle">{subTitle}</h3> }
    </section>
    <section className="topbar__buttons">
      {buttons}
    </section>
  </header>
);

Topbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subTitle: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Topbar.defaultProps = {
  title: '',
  subTitle: '',
  buttons: [],
};

export default Topbar;
