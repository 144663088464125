import Fuse from 'fuse.js';

/**
 * Gives ability to do a fuzzy search on the frontend.
 * http://fusejs.io/
 * @param list
 * @param searchValue
 * @param options
 * @returns {object} filtered list.
 */
const fuzzySearch = (list, searchValue, options) => {
  const fuse = new Fuse(list, options);
  return fuse.search(searchValue);
};

export default fuzzySearch;
