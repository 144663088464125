import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_loading.scss';

const Spinner = ({ className, size, message, dark, overlay, show, inline }) => {
  const Element = inline ? 'span' : 'div';
  return (
    <Element
      className={classnames('loading--spinner', className, {
        'loading--spinner--dark': dark,
        'loading--spinner--inline': inline && !overlay,
        'loading--spinner--overlay': !inline && overlay,
        'loading--spinner--show': !inline && overlay && show,
      })}
    >
      <svg
        style={size || size >= 0 ? { width: size, height: size } : null}
        className="spinner__circle"
        viewBox="25 25 50 50"
      >
        <circle
          className="spinner__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
      {message && (
        <Element className="spinner__message">{message}</Element>
      )}
    </Element>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  message: PropTypes.node,
  dark: PropTypes.bool,
  overlay: PropTypes.bool,
  show: PropTypes.bool,
  inline: PropTypes.bool,
};
Spinner.defaultProps = {
  className: '',
  size: null,
  message: null,
  dark: true,
  overlay: false,
  show: false,
  inline: false,
};

export default Spinner;
