import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import _last from 'lodash/last';
import Button from '../Button';
import { withMessages, messagesType } from '../../utility';
import './_pagination.scss';

const defaultMessages = {
  total(first, last, totalItems) {
    return `${first} - ${last} of ${totalItems}`;
  },
};

const getShiftNumber = maxPages => (maxPages - 1) / 2;

/**
 * MVP pagination component. Things that we should probably add:
 *  * implement First/Last links if "showStartEndLinks" is true
 *
 * @constructor
 */
export class PaginationComponent extends Component {
  getPages = () => {
    const {
      pages,
      totalItems,
      itemsPerPage,
    } = this.props;

    if (pages !== null) {
      return pages;
    }

    return Math.ceil(totalItems / itemsPerPage);
  }

  getSliceStartIndex = () => {
    const { currentPage, maxPages } = this.props;
    const pages = this.getPages();
    const shift = getShiftNumber(maxPages);

    if (currentPage <= maxPages) {
      return 1;
    }

    if (pages - currentPage < shift) {
      return pages - maxPages + 1;
    }

    return currentPage - shift;
  }

  getSliceEndIndex = () => {
    const { currentPage, maxPages } = this.props;
    const pages = this.getPages();
    const shift = getShiftNumber(maxPages);

    if ((pages <= maxPages) || (pages - currentPage < maxPages)) {
      return pages;
    }

    if (currentPage + shift <= maxPages) {
      return maxPages;
    }

    return currentPage + shift;
  }

  getActivePageSlice = () => {
    const pages = this.getPages();

    const startIndex = this.getSliceStartIndex();
    const endIndex = this.getSliceEndIndex();

    if (pages === 1) {
      return _range(1, 2);
    }

    return _range(startIndex, endIndex + 1);
  }

  renderTotals = () => {
    const {
      currentPage,
      itemsPerPage,
      totalItems,
      messages,
    } = this.props;


    const first = ((currentPage - 1) * itemsPerPage) + 1;
    let last = (first + itemsPerPage) - 1;
    if (last > totalItems) {
      last = totalItems;
    }

    return (
      <div className="st-pagination__totals">
        {messages.total(first, last, totalItems)}
      </div>
    );
  }

  renderPageItem = (page) => {
    const { pages, currentPage, onChange } = this.props;

    const isCurrentPage = page === currentPage;
    return (
      <li
        key={`page-${page}-link`}
        className={classnames('st-pagination__page', {
          'st-pagination__page--current': isCurrentPage,
          'st-pagination__page--last': page === pages,
        })}
      >
        <Button
          onClick={() => onChange(page)}
          disabled={isCurrentPage}
        >
          {`${page}`}
        </Button>
      </li>
    );
  }

  render() {
    const {
      currentPage,
      onChange,
      className,
      showPrevNextLinks,
      maxPages,
      totalItems,
      itemsPerPage,
    } = this.props;

    const pages = this.getPages();

    const activePages = this.getActivePageSlice();
    const visiblePages = activePages.map(this.renderPageItem);

    return (
      <div
        className={classnames('st-pagination', className, {
          'st-pagination--empty': pages <= 1,
        })}
      >
        {!(totalItems === null || itemsPerPage === null) && this.renderTotals()}

        <ul className="st-pagination__pager">
          {showPrevNextLinks && (
            <li className="st-pagination__prev">
              <Button
                onClick={() => onChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
            </li>
          )}

          {currentPage > maxPages && [
            this.renderPageItem(1),
            <li key="page-placeholder-min" className="st-pagination__page st-pagination__placeholder">...</li>,
          ]}
          {visiblePages}
          {_last(activePages) <= (pages - 2) && (
            <li key="page-placeholder-max" className="st-pagination__page st-pagination__placeholder">...</li>
          )}
          {(pages - currentPage) >= maxPages && this.renderPageItem(pages)}
          {showPrevNextLinks && (
            <li className="st-pagination__next">
              <Button
                onClick={() => onChange(currentPage + 1)}
                disabled={currentPage === pages}
              />
            </li>
          )}
        </ul>
      </div>
    );
  }
}


PaginationComponent.propTypes = {
  pages: PropTypes.number,
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  showPrevNextLinks: PropTypes.bool,
  maxPages(props, propName, componentName) {
    if (props[propName] % 2 !== 1) {
      return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`);
    }
  },
  messages: messagesType.isRequired,
};

PaginationComponent.defaultProps = {
  pages: null,
  currentPage: 1,
  className: '',
  showPrevNextLinks: true,
  showStartEndLinks: false,
  maxPages: 5,
  totalItems: null,
  itemsPerPage: null,
};


export default withMessages('pagination', defaultMessages)(PaginationComponent);
