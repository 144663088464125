import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import './_button.scss';

export const BUTTON_TYPES = [
  'primary',
  'secondary',
  'ghost',
  'popover',
  'link',
  'tertiary',
  '',
];

export const BUTTON_SIZES = [
  'xs',
  'sm',
  'md',
  'lg',
  '',
];

const renderChildren = (children) => {
  if (typeof children === 'number' || !_isEmpty(children)) {
    return (
      <span className="button__text">{children}</span>
    );
  }

  return null;
};

/**
 * Primary UI component for user interaction
 *
 * Abstract: https://share.goabstract.com/239d7437-ae2d-43d0-926c-77b12a95caf5?mode=design&sha=d2590fadb77bc95bcd969e36c4f94a7baa8a6fb5
 */
const Button = (props) => {
  const {
    icon,
    iconPosition,
    children,
    className,
    onClick,
    onFocus,
    onBlur,
    selected,
    disabled,
    dark,
    size,
    type,
    htmlType,
    ...buttonAttributes
  } = props;

  return (
    <button
      {...buttonAttributes}
      className={classnames('button', className, {
        [`button--${type}`]: type,
        'button--is-selected': selected,
        'button--dark': dark,
        [`button--${size}`]: size,
        'button--icon': icon && _isEmpty(children),
        'button--icon-text': icon && !_isEmpty(children),
        [`button--icon-text__pos--${iconPosition}`]: icon && !_isEmpty(children),
      })}
      type={htmlType}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    >
      {!!icon && <i className={`icon ${icon}`} />}
      {renderChildren(children)}
    </button>
  );
};

// Common button props that should be used for other components that use a button
export const ButtonPropTypes = {
  /**
   * Specify icon name to add to button
   */
  icon: PropTypes.string,
  /**
   * Specify icon position relative to the button text
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Specify an optional className to be applied to the wrapper node
   */
  className: PropTypes.string,
  /**
   * `true` for dark theme scheme
   */
  dark: PropTypes.bool,
  /**
   * Specify different size option
   */
  size: PropTypes.oneOf(BUTTON_SIZES),
  /**
   * Specify different button style types
   */
  type: PropTypes.oneOf(BUTTON_TYPES),
  /**
   Specify if the control should be disabled, or not
  */
  disabled: PropTypes.bool,
};

// Common button props that should be used for other components that use a button
export const ButtonPropDefaults = {
  icon: null,
  iconPosition: 'left',
  className: null,
  dark: false,
  size: 'md',
  type: '',
  disabled: false,
};

Button.propTypes = {
  ...ButtonPropTypes,
  /**
   * Specify the type attribute value to be set onto the button node
   */
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Current selected state
   */
  selected: PropTypes.bool,
  /**
   * To associate a tooltip with a button add
   */
  'data-tip': PropTypes.string,
  /**
   * To associate a tooltip with a button add
  */
  'data-for': PropTypes.string,
};
Button.defaultProps = {
  ...ButtonPropDefaults,
  children: [],
  selected: false,
  onFocus: _noop,
  onBlur: _noop,
  onClick: _noop,
};

export default Button;
