import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_product-sidebar-nav.scss';

const ProductSidebarNavItems = ({ items, Link, activePath }) =>
  items.map((item, key) => {
    const isActive = (activePath === item.url);

    return (
      <li key={key} className={classnames('product-sidebar__link', { 'product-sidebar__link--active': isActive })}>
        <Link
          to={item.url}
          className={classnames('product-sidebar__link-text', { 'product-sidebar__link-text--active': isActive })}
        >
          {item.title}
        </Link>
      </li>
    );
  });

ProductSidebarNavItems.propTypes = {
  items: PropTypes.array,
  activePath: PropTypes.string,
  Link: PropTypes.func,
};

export default ProductSidebarNavItems;
