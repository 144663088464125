import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import _bindAll from 'lodash/bindAll';
import { Picker } from 'emoji-mart';
import './_text-field.scss';
import {
  findAncestor,
  withMessages,
  messagesType,
} from '../../utility';
import Button from '../Button';

export const INPUT_STATUS = ['success', 'error', 'loading', ''];

const defaultMessages = {
  emoji: {
    search: 'Search',
    notfound: 'No Emoji Found',
    categories: {
      search: 'Search results',
      recent: 'Frequently Used',
      people: 'Smileys & People',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
  }
};

class Input extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showEmojiPicker: false,
    };

      _bindAll(this, [
        'getInputNode',
        'checkValue',
        'onChange',
        'onDocumentClick',
        'onFocus',
        'onSelectEmoji',
      ]);

    this.input = null;
  }

  componentDidMount() {
    if (!this.props.multiline) {
      this.input.value = this.props.value;
    }

    if (this.props.emoji) {
      document.addEventListener('click', this.onDocumentClick);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.input.value = nextProps.value;
    }
  }

  componentWillUnmount() {
    if (this.props.emoji) {
      document.removeEventListener('click', this.onDocumentClick);
    }
  }

  onChange(event) {
    this.props.onChange(this.checkValue(event.target.value), event);
  }

  onDocumentClick(e) {
    const pattern = new RegExp(`emoji-field|emoji-mart`);
    if (this.state.showEmojiPicker && e.target.parentElement && !findAncestor(e.target, pattern)) {
      this.setState({ showEmojiPicker: false });
    }
  }

  onFocus(event) {
    this.props.onFocus(this.checkValue(event.target.value), event);
  }

  onSelectEmoji(emoji) {
    const text = emoji.native;
    const input = this.getInputNode();
    const scrollPos = input.scrollTop;
    let pos = 0;
    const browser = (
      (input.selectionStart || input.selectionStart == '0')
        ? 'ff'
        : (document.selection ? 'ie' : false)
    );
    if (browser === 'ie') {
      input.focus();
      const range = document.selection.createRange();
      range.moveStart('character', -input.value.length);
      pos = range.text.length;
    } else if (browser === 'ff') {
      pos = input.selectionStart;
    }

    const front = (input.value).substring(0, pos);
    const back = (input.value).substring(pos, input.value.length);
    input.value = front + text + back;
    pos += text.length;

    if (browser === 'ie') {
      input.focus();
      const range = document.selection.createRange();
      range.moveStart('character', -input.value.length);
      range.moveStart('character', pos);
      range.moveEnd('character', 0);
      range.select();
    } else if (browser === 'ff') {
      input.selectionStart = pos;
      input.selectionEnd = pos;
      input.focus();
    }

    // Hide the emoji picker as soon as the max characters is reached/exceeded
    // (Waiting to do this after the value has been updated allows more emojis
    // to picked in quick succession)
    if (this.props.maxLength && input.value.length >= this.props.maxLength) {
      this.setState({ showEmojiPicker: false });
    }

    input.scrollTop = scrollPos;

    this.props.onChange(input.value);
  }

  getInputNode() {
    return this.input;
  }

  /**
   * Number Inputs should return a number.
   * Text Inputs should return a string.
   */
  checkValue(value) {
    let currentValue = value;
    if (this.props.type === 'number') {
      currentValue = parseInt(currentValue, 10);
    }
    return currentValue;
  }

  render() {
    const {
      id,
      type,
      name,
      value,
      className,
      placeholder,
      disabled,
      required,
      onBlur,
      status,
      dark,
      min,
      max,
      step,
      multiline,
      maxLength,
      minLength,
      onClick,
      onKeyUp,
      onKeyDown,
      emoji,
      messages,
      field
    } = this.props;

    return (
      <div
        className={classnames('text-field', className, {
          [`text-field--${type}`]: type,
          [`text-field--${status}`]: status,
          'text-field--dark': dark,
          'emoji-field': emoji,
        })}
        onClick={() => this.input}
      >
        {!multiline && (
          <input
            id={id}
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            className="text-field__input sta-form-element"
            onBlur={(event) => {
              onBlur(event.target.value, event);
            }}
            onChange={this.onChange}
            onClick={onClick}
            onFocus={this.onFocus}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            ref={(input) => {
              this.input = input;
            }}
            min={min}
            max={max}
            maxLength={maxLength}
            minLength={minLength}
            step={step}
            {...field}
          />
        )}
        {!multiline && emoji && (
          <Button
            className={classnames('emoji-field__toggle', {
              'emoji-field__toggle--active': this.state.showEmojiPicker,
            })}
            type="link"
            onClick={() => {
              this.setState(prevState => ({ showEmojiPicker: !prevState.showEmojiPicker}));
            }}
            disabled={maxLength && value.length >= maxLength}
          >
            <svg
              className="emoji-field__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path>
              <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path>
            </svg>
          </Button>
        )}
        {this.state.showEmojiPicker && (
          <Picker
            title=""
            className="emoji-field__picker"
            onSelect={this.onSelectEmoji}
            i18n={messages.emoji}
          />
        )}

        {multiline && (
          <textarea
            className="text-field__input--multiline"
            disabled={disabled}
            defaultValue={value}
            required={required}
            placeholder={placeholder}
            onChange={this.onChange}
            onFocus={this.onFocus}
            ref={(input) => {
              this.input = input;
            }}
          />
        )}
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  status: PropTypes.oneOf(INPUT_STATUS),
  emoji: PropTypes.bool,

  // Number props
  min: PropTypes.number,
  max: PropTypes.number,

  maxLength: PropTypes.number,
  minLength: PropTypes.number,

  step: PropTypes.number,

  // Multiline props
  multiline: PropTypes.bool,

  messages: messagesType.isRequired,

  // field is Injected in by Formik, it is optional and allows the Input to be used in Formik Components
  field: PropTypes.shape({})
};

Input.defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  type: 'text',
  placeholder: '',
  disabled: false,
  onBlur: _noop,
  onClick: _noop,
  onFocus: _noop,
  onKeyUp: _noop,
  onKeyDown: _noop,
  status: '',
  min: null,
  max: null,
  maxLength: null,
  minLength: null,
  step: 1,
  multiline: false,
  emoji: false,
  field: {}
};

export default withMessages('input', defaultMessages)(Input);
