import React from 'react';
import PropTypes from 'prop-types';
import './_product-sidebar-nav.scss';
import ProductSidebarSectionItems from './ProductSidebarNavItems';

const ProductSidebarNav = ({ Link, children, className, sections, activePath }) => (
  <nav className="product-sidebar__nav">
    {sections.map(section => (
      <section key={section.sectionTitle} className="product-sidebar__section">
        <h4 className="product-sidebar__section-title">{section.sectionTitle}</h4>
        <ul>
          <ProductSidebarSectionItems items={section.sectionItems} Link={Link} activePath={activePath} />
        </ul>
        {children}
      </section>
    ))}
  </nav>
);

ProductSidebarNav.propTypes = {
  activePath: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  sections: PropTypes.array,
  Link: PropTypes.func,
};

ProductSidebarNav.defaultProps = {
  className: '',
  activePath: '',
  sections: [],
};

export default ProductSidebarNav;
