import React from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';
import classnames from 'classnames';
import _lastIndexOf from 'lodash/lastIndexOf';
import _noop from 'lodash/noop';
import _isNaN from 'lodash/isNaN';
import _isNumber from 'lodash/isNumber';
import _toString from 'lodash/toString';
import _clamp from 'lodash/clamp';

import './_number-input.scss';
import { ARROW_UP_KEY, ARROW_RIGHT_KEY, ARROW_DOWN_KEY, ZERO_KEY } from '../../utility';
import Button from '../Button';
import InputWrapper from './InputWrapper';

const INPUT_STATUS = ['success', 'error', 'loading', ''];

const DIR = {
  INC: 1,
  DEC: -1,
};

const MAX_SAFE_INTEGER = 2147483647; // max value of 32 bit integer
const MIN_SAFE_INTEGER = -2147483648; // min value of 32 bit integer

/**
 * isValidNumber
 * @param {number} value
 * @returns {boolean}
 */
const isValidNumber = value => !_isNaN(value) && _isNumber(value);

/**
 * alterValueByDir
 * @param {number} value value to be altered
 * @param {number} increment direction depending, increment number will be added to or substracted from value.
 * @param {number} direction 1 or -1 indicates positive or negative.
 * @returns {number} number incremented or decremented by step value
 */
const alterValueByDir = (value, increment, direction) => value + (increment * direction);

/**
 * This component is used for entering numeric values and includes controls for incrementally increasing or decreasing the value.
 *
 * NOTE: Number inputs use the same style classnames as Input
 *
 * @returns {number || null} Outputs a number or null if input was cleared
 */
class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setValue(this.props.value);
    }

    if (prevState.value !== this.state.value) {
      // Ensure the caret is in a valid place, at the end of the current number
      setTimeout(this.fixCaretPosition);

      if (this.props.value !== this.state.value) {
        // Trigger the onChange callback if the new value is different
        this.props.onChange(this.state.value);
      }
    }
  }

  /**
   * onChange
   * Fired when typing into input or pasting in.
   * It will always try to cast to a number.
   * If parser is passed it will use this to parse.
   *
   * @param {number || string} value
   * @param {object} event
   * Should only setState value state if value is a valid Number
   */
  onChange = (value) => {
    if (value === '') {
      this.setState({ value: null });
    } else {
      const parser = this.props.parser;
      const parsedValue = +(parser ? parser(value) : value);
      this.setValue(parsedValue);
    }
  };

  onKeyDown = (event) => {
    let direction = null;
    if (event.keyCode === ARROW_UP_KEY && !this.isArrowUpDisabled()) {
      direction = DIR.INC;
    } else if (event.keyCode === ARROW_DOWN_KEY && !this.isArrowDownDisabled()) {
      direction = DIR.DEC;
    } else if (event.keyCode === ARROW_RIGHT_KEY) {
      setTimeout(this.fixCaretPosition);
    } else if (event.keyCode >= ZERO_KEY) {
      if (
        // Prevent numeric entry that is outside the possible range
        (/[0-9]/.test(event.key) && event.target.selectionStart > this.getEndCaretPosition()) ||
        // Ignore non-numeric keys unless a meta key is pressed (e.g. Ctrl/Command)
        (!/[0-9]/.test(event.key) && !event.metaKey)
      ) {
        event.preventDefault();
        return false;
      }
    }

    // Up or down arrow keys have been pressed
    if (direction) {
      this.onArrowMove(direction);
      event.preventDefault();
      return false;
    }
  };

  /**
   * onArrowClick - Number Input Buttons.
   * @param {number} direction 1 or -1 indicates whether to plus or minus from current value
   */
  onArrowClick(direction = DIR.INC) {
    this.onArrowMove(direction);
  }

  onArrowMove(direction) {
    this.setState((prevState) => {
      const { value } = prevState;
      if (!isValidNumber(value)) {
        /**
         * If initial start value is empty/invalid, assign a start value.
         * Default to zero unless the min is above zero.
         * TODO in future perhaps we allow user to set this with additional prop.
         */
        let startValue = 0;
        if (this.props.min > 0 && this.props.max > 0) {
          startValue = this.props.min;
        } else if (this.props.min < 0 && this.props.max < 0) {
          startValue = this.props.max;
        }
        return { value: startValue };
      }

      const newValue = this.getNewValue(alterValueByDir(value, this.props.step, direction));
      return { value: newValue };
    });
  }

  setValue = (value) => {
    if (isValidNumber(value)) {
      this.setState({ value: this.getNewValue(value) });
    }
  };

  getNewValue(value) {
    return _clamp(value, this.props.min, this.props.max);
  }

  getEndCaretPosition = () => {
    const inputValue = this.inputRef.current.value;
    const valueString = _toString(this.state.value);
    const lastNumber = valueString.length ? valueString.substring(valueString.length - 1) : '0';
    const position = _lastIndexOf(inputValue, lastNumber) + 1;
    return Math.min(position, inputValue.length);
  };

  isArrowUpDisabled() {
    return this.state.value >= this.props.max || this.props.disabled;
  }

  isArrowDownDisabled() {
    return this.state.value <= this.props.min || this.props.disabled;
  }

  fixCaretPosition = () => {
    const lastPos = this.getEndCaretPosition();
    const el = this.inputRef.current;
    if (el.selectionStart > lastPos) {
      el.setSelectionRange(lastPos, lastPos);
    }
  };

  formatValue = () => {
    const formatter = this.props.formatter;
    const value = this.state.value === null ? '' : this.state.value;

    return formatter ? formatter(value) : value;
  };

  focusAfterNumber = () => {
    setTimeout(this.fixCaretPosition);
  };

  render() {
    const { className, inputClassName, formatter, parser, step, ...inputProps } = this.props;

    return (
      <div className={classnames(className, 'sta-number-input')}>
        <InputWrapper
          {...inputProps}
          className={inputClassName}
          value={this.formatValue()}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onClick={this.focusAfterNumber}
          onFocus={this.focusAfterNumber}
          ref={this.inputRef}
        />

        <div className="sta-number-input__arrows">
          <Button
            className="sta-number-input__arrows__button"
            type="secondary"
            onClick={() => this.onArrowClick(DIR.INC)}
            icon="icon-chevron-up"
            disabled={this.isArrowUpDisabled()}
          />
          <Button
            className="sta-number-input__arrows__button"
            type="secondary"
            onClick={() => this.onArrowClick(DIR.DEC)}
            icon="icon-chevron-down"
            disabled={this.isArrowDownDisabled()}
          />
        </div>
      </div>
    );
  }
}

NumberInput.propTypes = {
  /**
   * Specify an optional className to be applied to the wrapper node
   */
  className: PropTypes.string,
  /**
   * Specify an optional className to be applied to the input wrapper node
   */
  inputClassName: PropTypes.string,
  /**
   `true` to use the dark theme version.
  */
  dark: PropTypes.bool,
  /**
   Specify if the control should be disabled, or not
  */
  disabled: PropTypes.bool,
  /**
   * Specify the name of the input element. Used to reference form data on form submit
   */
  name: PropTypes.string,
  /**
   * Callback function which returns current Number chosen
   */
  onChange: PropTypes.func,
  /**
   * Specify custom placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Specifify that an input field must be filled out before submitting the form
   */
  required: PropTypes.bool,
  /**
   * Specify how much the values should increase/decrease upon clicking on up/down button
   */
  step: PropTypes.number,
  /**
   * Specify the value of the input
   */
  value: PropTypes.number,
  /**
   * Specify an additonal method to format the value being added to the input. To be used in conjunction with `parser`
   */
  formatter: isRequiredIf(PropTypes.func, props => typeof props.parser === 'function'),
  /**
   * Specify an additonal method to parse the onChange value. To be used in conjunction with `formatter`
   */
  parser: isRequiredIf(PropTypes.func, props => typeof props.formatter === 'function'),
  /**
   The maximum value
  */
  max: PropTypes.number,
  /**
   The minimum value
  */
  min: PropTypes.number,
  /**
   * Specify the current validation / loading status of the input
   */
  status: PropTypes.oneOf(INPUT_STATUS),
};

NumberInput.defaultProps = {
  className: '',
  inputClassName: '',
  dark: false,
  disabled: false,
  name: '',
  onChange: _noop,
  required: false,
  step: 1,
  value: null,
  formatter: null,
  parser: null,
  placeholder: '',
  min: MIN_SAFE_INTEGER,
  max: MAX_SAFE_INTEGER,
  status: '',
};

export default NumberInput;
