import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import _constant from 'lodash/constant';
import _isBoolean from 'lodash/isBoolean';
import { result } from '@showtime/utility';
import './_element-popover.scss';

const componentName = 'element-popover';

/**
 * ElementPopover Component.
 *
 * Available Props:
 * @param {function} toggleElement - Toggle element or function that renders one.
 *    function takes isOpen state as it's only paramerter.
 * @param {function} contentElement - Content element or function that renders one.
 *    function is called only when popover is open, with close method as parameter.
 * @param {function} contentClassName -
 *    className passed to element-popover__content - the direct parent of contentElement.
 * @param {boolean} isOpen - Controls wether popover is open.
 *    Changing the prop does not trigger onOpen/onClose events.
 * @param {function} onOpen - Callback function when the popover opens.
 * @param {function} onClose - Callback function when the popover closes.
 * @param {function} onOutsideClick - Callback function when clicked outside of popover elements.
 *    Takes current event as a parameter. Returning `false` will prevent component close.
 * @param {boolean} safeClose - When true outside click will only close the component,
 *    without triggering other click events.
 * @param {boolean} hasArrow - boolean controlling rendering of popover arrow.
 * @param {'left'|'right'} attachment - controls wether content will attach itself
 *  to parent's left or right boundary.
 */
class ElementPopover extends Component {
  static propTypes = {
    toggleElement: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
    contentElement: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
    contentClassName: PropTypes.string,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onOutsideClick: PropTypes.func,
    safeClose: PropTypes.bool,
    hasArrow: PropTypes.bool,
    attachment: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
  };

  static defaultProps = {
    toggleElement: null,
    contentElement: null,
    contentClassName: '',
    isOpen: false,
    onOpen: _noop,
    onClose: _noop,
    onOutsideClick: _noop,
    safeClose: false,
    hasArrow: false,
    attachment: 'left',
    className: '',
  };

  static getDerivedStateFromProps({ isOpen }, { isOpenMemo }) {
    // Memo is a state property holding the last value coming from props.
    // used to sync component state with incoming props.
    if (isOpen !== isOpenMemo) {
      return {
        isOpen,
        isOpenMemo: isOpen,
      };
    }

    return null;
  }

  state = {
    isOpen: this.props.isOpen,
    isOpenMemo: this.props.isOpen,
  };

  componentDidMount() {
    this.setupEvents();
  }

  componentDidUpdate(_, prevState) {
    const { isOpen } = this.state;

    if (isOpen !== prevState.isOpen) {
      this.setupEvents();
    }
  }

  componentWillUnmount() {
    this.setupEvents(false);
  }

  onToggle = () => {
    const { isOpen } = this.state;
    const { onOpen, onClose } = this.props;

    if (isOpen) {
      onOpen();
    } else {
      onClose();
    }
  }

  onDocumentClick = (event) => {
    const { onOutsideClick, safeClose } = this.props;
    const noRef = { contains: _constant(false) };
    const content = this.popoverContent.current || noRef;
    const toggle = this.popoverToggle.current || noRef;
    if (
      !content.contains(event.target) &&
      !toggle.contains(event.target) &&
      onOutsideClick(event) !== false
    ) {
      this.close();

      if (safeClose) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  setupEvents(isOpen = this.state.isOpen) {
    const listenerParams = ['click', this.onDocumentClick, true];

    if (isOpen) {
      document.addEventListener(...listenerParams);
    } else {
      document.removeEventListener(...listenerParams);
    }
  }

  popoverContent = React.createRef();
  popoverToggle = React.createRef();

  /**
   * Toggles popover's isOpen state, or sets it to a given value.
   * @param {boolean} shouldOpen Optional. Forces desired state.
   */
   toggle = (shouldOpen) => {
     this.setState(
       ({ isOpen }) => ({
         isOpen: _isBoolean(shouldOpen) ? shouldOpen : !isOpen,
       }),
       this.onToggle,
     );
   };

  open = () => this.toggle(true);
  close = () => this.toggle(false);

  renderToggleElement() {
    const element = result(this.props.toggleElement, this.state.isOpen);

    return element && (
      <span
        ref={this.popoverToggle}
        role="button"
        className={`${componentName}__toggle`}
        onClick={this.toggle}
      >
        {element}
      </span>
    );
  }

  renderContent() {
    const { hasArrow, attachment, contentElement, contentClassName } = this.props;
    const className = `${componentName}__content`;
    const classNames = classnames(
      contentClassName,
      className,
      `${className}--attach-${attachment}`,
      {
        [`${className}--has-arrow`]: hasArrow,
      },
    );

    return this.state.isOpen && (
      <div
        ref={this.popoverContent}
        className={classNames}
      >
        {result(contentElement, this.close)}
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { isOpen } = this.state;
    const classNames = classnames(
      className,
      componentName,
      {
        [`${componentName}--open`]: isOpen,
      },
    );

    return (
      <span className={classNames}>
        {this.renderToggleElement()}
        {this.renderContent()}
      </span>
    );
  }
}

export default ElementPopover;
