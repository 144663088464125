import React from 'react';
import PropTypes from 'prop-types';
import _bindAll from 'lodash/bindAll';
import _clone from 'lodash/clone';
import _includes from 'lodash/includes';
import _noop from 'lodash/noop';
import classnames from 'classnames';
import Button from '../Button';

/**
 * ButtonGroupField
 *
 * Available Props -
 *
 * Multiple - default is false.
 * Value - If Multiple this takes an Array.
 If Single select this takes a String or a Boolean
 * onChange - Function returning either a String or Array of selectedOptions.
 * options - Array.
 e.g [
 {
   value: 'bold', --> saved key
   icon: 'bold',  --> icon name (optional)
   text: should already be translated (optional - otherwise value used as label)
 },
 ];

 A button can have an icon, text or both.

 * Size (optional) Allowed different sizes
 * .button-group--field--small
 */
class ButtonGroupField extends React.Component {
  constructor(props) {
    super(props);

    _bindAll(this, [
      'renderOptions',
      'handleSelection',
      'isSelected',
    ]);
  }

  isSelected(value) {
    const {
      multiple,
      value: selectedValue,
    } = this.props;

    if (multiple) {
      return _includes(selectedValue, value);
    }

    // Cropper Aspect ratio returns NaN as it's default so need to highligh this.
    if (typeof value !== 'string' && isNaN(selectedValue) && isNaN(value)) {
      return true;
    }

    return selectedValue === value;
  }

  /**
   * If Single Selection return a string to onChange.
   * If multiple - return an array of selected items.
   */
  handleSelection(value) {
    const {
      multiple,
      value: selectedValue,
      onChange,
    } = this.props;
    if (multiple) {
      const selectedOptions = _clone(selectedValue);
      const index = selectedOptions.indexOf(value);

      // If already in array remove, otherwise add.
      if (index !== -1) {
        selectedOptions.splice(index, 1);
      } else {
        selectedOptions.push(value);
      }
      onChange(selectedOptions);
    } else {
      this.props.onChange(value);
    }
  }

  renderOptions() {
    const {
      options,
      type,
      size,
      buttonClass,
      selectedClass,
    } = this.props;

    return options.map((option, index) => {
      const icon = (option.icon) ? `icon-${option.icon}` : null;
      const keys = Object.keys(option);
      const isSelected = this.isSelected(option.value);

      let children = null;
      if (_includes(keys, 'text')) {
        children = option.text;
      } else if (!_includes(keys, 'icon')) {
        children = option.value;
      }

      return (
        <Button
          className={classnames({
            [buttonClass]: !!buttonClass,
            [selectedClass]: isSelected && !!selectedClass,
          })}
          onClick={() => {
            this.handleSelection(option.value);
          }}
          selected={isSelected}
          type={type}
          icon={icon}
          key={index}
          size={size}
          disabled={!!option.disabled}
        >
          {children}
        </Button>
      );
    });
  }

  render() {
    const {
      dark,
      fullWidth,
      type,
      className,
    } = this.props;

    return (
      <div
        className={classnames('button-group', 'button-group--field', className, {
          'button-group--dark': dark,
          'button-group--full-width': fullWidth,
          [`button-group--${type}`]: type,
        })}
        role="group"
      >
        {this.renderOptions()}
      </div>
    );
  }
}

ButtonGroupField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  multiple: PropTypes.bool,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  buttonClass: PropTypes.string,
  selectedClass: PropTypes.string,
};

ButtonGroupField.defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  multiple: false,
  fullWidth: false,
  type: 'primary',
  buttonClass: null,
  selectedClass: null,
};

export default ButtonGroupField;
