import React from 'react';
import PropTypes from 'prop-types';
import _bindAll from 'lodash/bindAll';
import _noop from 'lodash/noop';
import FormField from '../FormField';
import Input from '../../Input';
import { withMessages, messagesType } from '../../../utility';

const defaultMessages = {
  requiredField: 'This field is required',
  invalidEmail: 'Oops - it looks like you entered an invalid email. Please check the email and try again.',
};

const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class EmailField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorText: '',
      requiredText: props.messages.requiredField,
    };

    _bindAll(this, [
      'validate',
      'isValidEmail',
      'updateErrorText',
    ]);
    this.input = null;
  }

  componentWillReceiveProps(nextProps) {
    // errorText beigns as empty but if there is an error it needs to update to that error
    if (this.props.hasError !== nextProps.hasError) {
      this.updateErrorText(nextProps.hasError);
    }
  }

  updateErrorText(hasError) {
    if (hasError && this.props.required) {
      this.setState({
        errorText: this.state.requiredText,
      });
    }
  }

  isValidEmail(email) {
    return VALID_EMAIL_REGEX.test(email);
  }

  validate(email) {
    let errorText = '';
    const validity = this.input.getInputNode().validity;
    if (!email) { // and required
      errorText = (this.props.required) ? this.state.requiredText : '';
    } else if (!this.isValidEmail(email) || !validity.valid) {
      errorText = this.props.messages.invalidEmail;
    } else {
      errorText = '';
    }

    this.setState({ errorText });
    if (!errorText) {
      this.props.onValid(email);
    }
  }

  render() {
    const {
      label,
      subText,
      value,
      onChange,
      inputDescription,
      formGroupClassName,
      required,
      hasError,

      // Assume any other props are intended for the Text
      ...textProps
    } = this.props;

    return (
      <FormField
        className={formGroupClassName}
        label={label}
        subText={subText}
        errorText={this.state.errorText}
        hasError={hasError || !!this.state.errorText}
        descriptionText={inputDescription}
        required={required}
      >
        <Input
          {...textProps}
          required={required}
          value={value}
          onChange={(email) => {
            onChange(email);
            this.validate(email);
          }}
          ref={(input) => {
            this.input = input;
          }}
          type="email"
        />
      </FormField>
    );
  }
}

EmailField.propTypes = {
  value: PropTypes.string, // NOTE: may not always be a string, but can be overridden in such cases
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onValid: PropTypes.func,
  formGroupClassName: PropTypes.string,
  subText: PropTypes.string,
  messages: messagesType.isRequired,
};

EmailField.defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  onValid: _noop,
  formGroupClassName: '',
  subText: '',
};

export default withMessages('emailField', defaultMessages)(EmailField);
