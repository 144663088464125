import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_form-field.scss';

const appendControlClass = (el) => {
  let existingClass = '';
  if (el.props) {
    existingClass = el.props.className || '';
  }
  if (!existingClass || existingClass.indexOf('form-field__control') === -1) {
    return React.cloneElement(el, {
      className: classnames(existingClass, 'form-field__control'),
    });
  }

  return el;
};

const renderMessages = (messages, type) => {
  if (!Array.isArray(messages)) {
    return renderMessages([messages]);
  }

  return messages.map((message, index) => <li key={`${type}-${index}`}>{message}</li>);
};

/**
 * FormFieldComponent
 * This Component wraps a form element and handles form elements label,
 * descriptive and error text as well as error, warning and success states.
 *
 * @param className Adds additional class to .form-field
 * @param label Adds label formatted string or children elements
 * @param labelClass Adds additional class to .form-field__label
 * @param labelSize Specific label styling. (Default: "md")
 * @param labelWidth Specific label width (Default: "md")
 * @param labelAlign Adds an additional class to .form__field
 * @param subtext Text to appear directly below a label.
 * @param descriptionText - Text to appear directly below an input.
 * @param required - Requied boolean. Will add a * to label.
 * @param inline Specific styling to make label and input sit inline.
 * @param spaceBottom margin sizing bottom.
 * @param errorText - one or an array of error strings.
 *
 * @returns {XML}
 */

const FormFieldComponent = ({
  children,
  inline,
  className,
  label,
  labelClass,
  labelSize,
  labelWidth,
  labelAlign,
  required,
  warningText,
  hasWarning,
  errorText,
  hasError,
  descriptionText,
  subText,
  spaceBottom,
}) => (
  <div
    className={classnames('form-field', className, {
      'form-field--inline': inline,
      'form-field--warning': hasWarning,
      'form-field--error': hasError,
      'form-field--empty-label': label === '',
      [`form-field--label-${labelWidth}`]: labelWidth,
      'form-field--no-label': label === null,
      [`form-field--space-bottom--${spaceBottom}`]: spaceBottom,
      [`form-field--label-${labelAlign}`]: labelAlign === 'right',
    })}
  >
    <div className="form-field__row">
      {label && (
        <label
          className={classnames('form-field__label', labelClass, {
            [`form-field__label--size-${labelSize}`]: labelSize,
          })}
        >
          {label}
        </label>
      )}

      {(!inline && subText) && (
        <span className="form-field__sub-text">{subText}</span>
      )}

      <div className="form-field__content">
        {appendControlClass(children)}
      </div>
    </div>
    {(warningText || errorText || descriptionText) && (
      <div className="form-field__row--meta">
        {warningText && (
          <ul className="form-field__messages form-field__messages--warning form-field__toggle-warning">
            {renderMessages(warningText, 'warning')}
          </ul>
        )}

        {errorText && (
          <ul className="form-field__messages form-field__messages--danger form-field__toggle-error">
            {renderMessages(errorText, 'error')}
          </ul>
        )}

        {descriptionText && (
          <span className="form-field__description">{descriptionText}</span>
        )}
      </div>
    )}
  </div>
);

FormFieldComponent.propTypes = {
  inline: PropTypes.bool,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.element.isRequired,
  warningText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasWarning: PropTypes.bool,
  errorText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasError: PropTypes.bool,
  labelWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  labelSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  labelAlign: PropTypes.oneOf(['left', 'right']),
  descriptionText: PropTypes.node,
  required: PropTypes.bool,
  subText: PropTypes.node,
  spaceBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

FormFieldComponent.defaultProps = {
  inline: false,
  className: '',
  label: null,
  labelClass: '',
  warningText: null,
  hasWarning: false,
  errorText: null,
  hasError: false,
  labelWidth: null,
  labelSize: 'md',
  labelAlign: 'left',
  descriptionText: '',
  required: false,
  subText: '',
  spaceBottom: 'md',
};

export default FormFieldComponent;
