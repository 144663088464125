import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {
  className: '',
};

const componentClass = 'card__footer';

const CardFooter = ({ children, className, ...props }) =>
  <div {...props} className={classnames(componentClass, className)}>
    { children }
  </div>

CardFooter.propTypes = propTypes;
CardFooter.defaultProps = defaultProps;

export default CardFooter;
