import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';
import _noop from 'lodash/noop';

import './_switch-toggle.scss';

const SwitchComponent = ({
  value,
  onChange,
  checkedChildren,
  unCheckedChildren,
  disabled,
}) => {
  return (
    <div className="switch-toggle">
      <Switch
        checked={value}
        onChange={onChange}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        disabled={disabled}
      />
    </div>
  );
};

SwitchComponent.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  checkedChildren: PropTypes.string,
  unCheckedChildren: PropTypes.string,
};

SwitchComponent.defaultProps = {
  onChange: _noop,
  className: '',
  required: false,
  value: true,
  checkedChildren: '',
  unCheckedChildren: '',
  disabled: false,
};

export default SwitchComponent;
