import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classnames from 'classnames';

const INPUT_STATUS = ['success', 'error', 'loading', ''];

const propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  // field Injected in by Formik - is optional & allows Input to be used in Formik Components
  field: PropTypes.shape({}),
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  status: PropTypes.oneOf(INPUT_STATUS),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  className: '',
  dark: false,
  disabled: false,
  field: {},
  id: null,
  name: '',
  onBlur: _noop,
  onChange: _noop,
  onClick: _noop,
  onFocus: _noop,
  onKeyDown: _noop,
  placeholder: '',
  required: false,
  status: '',
  value: '',
};

const InputWrapper = React.forwardRef(
  function NumberInputWrapper(props, ref) {
    const {
      className,
      status,
      dark,
      field,
      onChange,
      onFocus,
      onBlur,
      ...inputProps
    } = props;

    const handleBlur = event => onBlur(event.target.value, event);

    const handleChange = event => onChange(event.target.value, event);

    const handleFocus = event => onFocus(event.target.value, event);

    return (
      <div
        className={classnames('text-field text-field--text', className, {
          [`text-field--${status}`]: status,
          'text-field--dark': dark,
        })}
      >
        <input
          className="text-field__input sta-form-element"
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={ref}
          {...inputProps}
          {...field}
          type="text" // Note: Originally text in order to be able to match designs
        />
      </div>
    );
  },
);

InputWrapper.propTypes = propTypes;
InputWrapper.defaultProps = defaultProps;

export default InputWrapper;
