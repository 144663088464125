import React, { cloneElement } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import './_field-group.scss';

const parseFields = (children, fieldOverrides = {}) => {
  if (_isEmpty(fieldOverrides)) {
    return children;
  }

  return React.Children.map(children, (field) => {
    if (!field) {
      return;
    }

    // Prevent injecting invalid props into HTML elements, such as when field.type === 'div'
    const fieldProps = (typeof field.type !== 'string')
      ? { ...field.props, ...fieldOverrides }
      : field.props
    ;

    return cloneElement(field, fieldProps);
  });
};

const FieldGroupHeader = ({ header }) => {
  if (!header) {
    return null;
  }
  const hasHeaderElements = React.isValidElement(header);
  return (
    <div className="field-group__header">
      {hasHeaderElements && header}
      {!hasHeaderElements && (
        <h4>{header}</h4>
      )}
    </div>
  );
};
FieldGroupHeader.propTypes = {
  header: PropTypes.node.isRequired,
};

/**
 * @deprecated - DO NOT USE! Should be ported back for use in Engage Only.
 * 
 * This components purpose is to create a block with padding, heading and description around a group of form elements.
 */
const FieldGroup = ({
  header,
  description,
  children,
  className,
  gutterSize,
  ...fieldOverrides
}) => (
  <div
    className={classnames('field-group', className, {
      [`field-group--gutter-${gutterSize}`]: gutterSize,
      'field-group--no-header': !header,
    })}
  >
    <FieldGroupHeader header={header} />
    {description && (
      <p className="field-group__description">{description}</p>
    )}
    <div className="field-group__fields">
      {parseFields(children, fieldOverrides)}
    </div>
  </div>
);

FieldGroup.propTypes = {
  /**
   * header area of group. Translation string or child element
   */
  header: PropTypes.node,
  /**
   * description text for group
   */
  description: PropTypes.string,
  /**
   * additional className added to component
   */
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * adjusts the left/right margins.
   */
  gutterSize: PropTypes.oneOf(['sm', 'md', 'lg']),
};

FieldGroup.defaultProps = {
  header: '',
  description: '',
  className: '',
  gutterSize: null,
};

export default FieldGroup;
