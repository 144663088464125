const TAB_TYPE_BUTTON_GROUP = 'button-group';
const TAB_TYPE_MATERIAL = 'material';
const TAB_TYPE_PILLS = 'pills';

const TAB_TYPES = [TAB_TYPE_BUTTON_GROUP, TAB_TYPE_PILLS, TAB_TYPE_MATERIAL];

export {
  TAB_TYPE_BUTTON_GROUP,
  TAB_TYPE_MATERIAL,
  TAB_TYPE_PILLS,
  TAB_TYPES,
}
