import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import _isEqual from 'lodash/isEqual';
import { withMessages, messagesType } from '../../utility';
import RadioOption, { RadioOptionPropTypes } from './RadioOption';
import './_radio-button.scss';

const defaultMessages = {
  yes: 'Yes',
  no: 'No',
};

/**
 * Custom Radio Button component.
 * Contains a .radio-button-group wrapper with a number of RadioOption components.
 * If no options are passed it will default to a boolean - yes or no.
 * Can have multiple options, using label & value as the option keys.
 *
 * @param className       - Extra className to be added to .radio-button-group--{className}
 * @param defaultSelected - Set the value here of the option that should be selected by default.
 * @param inline          - Options stack vertically by default. To inline set to true.
 * @param name            - Unique name for Radio button group.
 * @param onChange        - Callback function fired when a radio input has changed.
 * @param options         - Array of option objects containing the following:
 *        @param label       - Label to add beside each option.
 *        @param value       - Value of each option
 *        @param description - Description for each option to show below label.
 *        @param content     - Can pass child content to be shown when radio option is selected
 *        @param disabled    - Boolean to determine if radio should be disabled or not
 * @param value              - The currently selected radio option
 */

class RadioButtonComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    inline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape(RadioOptionPropTypes)),
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    messages: messagesType.isRequired,
  };

  static defaultProps = {
    className: '',
    defaultSelected: null,
    inline: false,
    onChange: _noop,
    options: null,
    value: null,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.defaultOptions = [
      {
        label: props.messages.yes,
        value: true,
      },
      {
        label: props.messages.no,
        value: false,
      },
    ];

    const value = !_isNull(props.value)
      ? props.value
      : _get(props, 'options[0].value', this.defaultOptions[0].value);

    this.state = {
      checkedValue: !_isNull(props.value) ? props.value : props.defaultSelected,
      valueType: typeof value,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props.value, prevProps.value)) {
      this.updateCheckedValue();
    }
  }

  updateCheckedValue = () => {
    this.setState({
      checkedValue: this.props.value,
      valueType: typeof this.props.value,
    });
  };

  /**
   * @param {object} event
   * @param {string} event.currentTarget.value - Radio inputs send back their value as a string.
   * @returns {string | number | boolean} - depending on the initial input value type.
   */
  handleChange = (event) => {
    let selectedValue;
    switch (this.state.valueType) {
      case 'boolean':
        selectedValue = event.currentTarget.value.toLowerCase() === 'true';
        break;
      case 'number':
        selectedValue = parseInt(event.currentTarget.value, 10);
        break;
      case 'string':
      default:
        selectedValue = event.currentTarget.value;
    }

    this.setState({ checkedValue: selectedValue });
    this.props.onChange(selectedValue, event);
  };

  renderRadioOption = option => (
    <RadioOption
      {...option}
      key={option.value}
      onChange={this.handleChange}
      name={this.props.name}
      disabled={this.props.disabled || option.disabled}
      checked={this.state.checkedValue === option.value}
    />
  );

  render() {
    const { options, inline, className } = this.props;

    const radioOptions = options || this.defaultOptions;

    return (
      <div
        className={classnames('radio-button-group', {
          [`radio-button-group--${className}`]: className,
          'radio-button-group--inline': inline,
        })}
      >
        {radioOptions.map(this.renderRadioOption)}
      </div>
    );
  }
}

export default withMessages('radioButton', defaultMessages)(RadioButtonComponent);
