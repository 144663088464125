import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_popoverlay.scss';

import { Provider } from './Context';

export default class PopoverlayProvider extends Component {
  static propTypes = {
    // className passed to popoverlay container
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    children: null,
  };

  componentDidMount() {
    // After component mounts, schedule a rerender,
    // so that children can read an initialized provider
    // Equivalent to setState for refs.
    this.forceUpdate();
  }

  popoverlayRef = React.createRef();

  render() {
    const { className, children } = this.props;
    const ref = this.popoverlayRef.current;

    return (
      <Fragment>
        <div
          ref={this.popoverlayRef}
          className={classnames('popoverlay__container', className)}
        />
        {ref && (
          <Provider value={ref}>
            {children}
          </Provider>
        )}
      </Fragment>
    );
  }
}
