import React from 'react';
import PropTypes from 'prop-types';
import { result } from '@showtime/utility';

/**
  * Simulates Array.prototype.join for React elements.
  * For cases like:
  *  - joining arrays of items into a readable string, or
  *  - conditionally render pieces of a large element while still maintaining separation
  *
  * @returns children in below format:
  *    `{leading}{child0}{separator}{child1}{separator}{...}{separator}{childN}{trailing}`
  */
const propTypes = {
  /**
   * rendered between siblings. Takes effect only if there are at least 2 children.
   * string | element or function(index, children) that returns such.
   * node used to support bool expressions.
   */
  separator: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * rendered before children. Takes effect only if there is at least 1 child.
   * string | element. node used to support bool expressions.
   */
  leading: PropTypes.node,
  /**
   * rendered after children. Takes effect only if there is at least 1 child.
   * string | element. node used to support bool expressions.
   */
  trailing: PropTypes.node,
  children: PropTypes.node,
};

function addSeparator(separator, index, childArray) {
  if (separator) {
    if (React.isValidElement(separator)) {
      childArray.push(React.cloneElement(separator, { key: `separator-${index}` }));
    } else if (typeof separator === 'string') {
      childArray.push(separator);
    }
  }
}

const Join = ({ separator, leading, trailing, children }) => {
  const joined = [];

  React.Children.toArray(children).forEach((child, index, childArray) => {
    if (index === 0) {
      addSeparator(leading, 'leading', joined);
    }

    joined.push(child);

    if (index < childArray.length - 1) {
      const sep = result(separator, index, childArray);
      addSeparator(sep, index, joined);
    } else {
      addSeparator(trailing, 'trailing', joined);
    }
  });

  return joined;
};

Join.propTypes = propTypes;

export default Join;
