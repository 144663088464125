import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';

import './_modal.scss';

import Button from '../Button';
import { RootContainerConsumer, Container } from '../Container';
import { findAncestor } from '../../utility';

/**
 * IMPROVEMENTS TODO
 *
 * Add animation / motion.
 * Add additioanal Accessibility
 * Alter colours, fonts and buttons based on new designs.
 * Add a bodyAlign props as well?
 * Allow for textAlignment prop??
 * All Modals are being rendered to DOM on page load - check best react practice of this :(.
 * The Material-ui and Ant Design ones do the same.
 */

/**
 * Modal Component
 *
 * @param className      - Extra className to be added to .modal-ctn--{className}
 * @param children       - Passed Elements rendered into modal body.
 * @param customSize     - Set number in pixels. Use ONLY when modal NEEDS to be a
                           very specific size. Ideally Size should always be used over this option.
 * @param footerAlign    - Horizontal alignment of footer contents.
 * @param hasCloseAction - Whether to show x close button in top right corner.
 * @param hasFooter      - Whether to show footer Area.
 * @param hasHorizGutter - Whether to add left/right padding around modal contents.
 * @param hasHeader      - Whether to show header Area.
 * @param headerAlign    - Horizontal alignment of header contents.
 * @param headerContent  - Custom elements to pass through to header. Note: This overrides title.
 * @param onRequestClose - Callback func fired when closing modal.
 * @param open           - Determines whether modal is open or not.
 * @param scrollable     - Whether the contents of modal can scroll.
 *                       - For .centered & .full-screen - .modal__body will scroll by default.
 *                       - To override .modal__body scroll: set scrollable={false} & add own styles.
 * @param size           - Set max-width modal can be.
 * @param title          - Modal title that gets added to header.
 * @param disableBodyPadding - removes all padding around modal__body
 */

export const MODAL_SIZES = ['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'full'];

const HORIZ_ALIGNMENTS = ['left', 'right', 'center'];

class ModalComponent extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    className: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    onOutsideClick: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    scrollable: PropTypes.bool,
    size: PropTypes.oneOf(MODAL_SIZES),
    customSize: PropTypes.number,
    title: PropTypes.node,
    hasHorizGutter: PropTypes.bool,
    hasCloseAction: PropTypes.bool,
    hasFooter: PropTypes.bool,
    footerAlign: PropTypes.oneOf(HORIZ_ALIGNMENTS),
    hasHeader: PropTypes.bool,
    headerAlign: PropTypes.oneOf(HORIZ_ALIGNMENTS),
    headerContent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disableBodyPadding: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    children: [],
    actions: [],
    scrollable: true,
    size: 'md',
    customSize: null,
    title: '',
    hasHorizGutter: true,
    hasCloseAction: false,
    hasFooter: true,
    footerAlign: 'right',
    hasHeader: true,
    headerAlign: 'left',
    headerContent: null,
    disableBodyPadding: false,
    onOutsideClick: _noop,
  };

  bodyEl = document.body;

  componentDidMount() {
    // fire once onMount for the scenario that modal is already open.
    this.toggleUpdateBody();
  }

  componentWillReceiveProps(nextProps) {
    // update body between opening and closing.
    if (!_isEqual(this.props.open, nextProps.open)) {
      this.toggleUpdateBody(nextProps.open);
    }
  }

  componentWillUnmount() {
    this.toggleUpdateBody();
  }

  /**
   * If a customSize was specified for the modal, convert to rem and add as an inline style.
   */
  getModalStyles = () => {
    const { customSize } = this.props;

    if (!customSize) {
      return null;
    }

    return {
      maxWidth: `${customSize / 16}rem`,
    };
  };

  /**
   * Add overflow:hidden to body element when modal is open.
   * @param {bool} isOpen
   */
  toggleUpdateBody = (isOpen) => {
    // TODO: This should not happen here.
    this.bodyEl.style.overflow = isOpen ? 'hidden' : 'unset';
  };

  /**
   * Close modal ONLY if outer mask was clicked.
   */
  handleMaskClick = (e) => {
    e.stopPropagation();

    const clickedContainer = e.target.classList.contains('js-modal-ctn');
    // ONLY close modal if click event happened from within this container.
    // For e.g A modal with a dropdown inserted into it.
    // On click of the dropdown opens a dropdown list which is added as a direct child of <body>
    // On Click of any of these list items would be a click event happening outside of the .js-modal-ctn.
    // In these cases we don't want to close the modal - hence the extra check.
    const clickedWithinModalContainer =
      clickedContainer || !!findAncestor(e.target, 'js-modal-ctn');

    // We don't want to close if click event happened inside the .js-modal area however.
    const clickedWithinModal = !!findAncestor(e.target, 'js-modal');

    if (clickedWithinModalContainer && !clickedWithinModal) {
      this.props.onOutsideClick(e);

      // closeModal is the default outsideClick action.
      // fire only if default hasnt been prevented.
      if (!e.isDefaultPrevented()) {
        this.closeModal();
      }
    }
  };

  closeModal = () => {
    this.props.onRequestClose();
  };

  renderModal = () => {
    const {
      actions,
      children,
      className,
      footerAlign,
      hasCloseAction,
      hasFooter,
      hasHeader,
      hasHorizGutter,
      headerAlign,
      headerContent,
      onRequestClose,
      open,
      scrollable,
      size,
      title,
      disableBodyPadding,
    } = this.props;

    return (
      <div>
        <Container
          className={classnames('modal-ctn js-modal-ctn', {
            [`modal-ctn--${className}`]: className,
            'modal-ctn--is-open': open,
            'modal-ctn--centered': size !== 'full',
            'modal-ctn--full': size === 'full',
            'modal-ctn--scroll': scrollable,
          })}
          tabIndex="-1"
          role="dialog"
          onClick={this.handleMaskClick}
        >
          <div
            className={classnames('modal js-modal', {
              [`modal--${size}`]: size,
            })}
            role="document"
            style={this.getModalStyles()}
          >
            <div
              tabIndex={0}
              className={classnames('modal__content', {
                'modal__content--horiz-gutter': hasHorizGutter,
              })}
            >
              {hasCloseAction && (
                <Button
                  aria-label="Close"
                  className="modal__btn--close"
                  icon="icon-cross"
                  onClick={onRequestClose}
                />
              )}

              {hasHeader && (
                <div
                  className={classnames('modal__header', {
                    [`modal--align-${headerAlign}`]: headerAlign,
                  })}
                >
                  {headerContent && headerContent}
                  {!headerContent && <h1 className="modal__title">{title}</h1>}
                </div>
              )}

              <div
                className={classnames('modal__body', {
                  'modal__body--no-padding': disableBodyPadding,
                })}
              >
                {children}
              </div>
              {hasFooter && (
                <div
                  className={classnames('modal__footer', {
                    [`modal--align-${footerAlign}`]: footerAlign,
                  })}
                >
                  {actions}
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  };

  /**
   * Ensure Modal is added as a direct child of body,
   * rather than inside it's parent DOM element.
   * Using createPortal ensures though that it is still treated like a real child.
   */
  render() {
    return (
      <RootContainerConsumer>
        {({ renderAtContainer }) => renderAtContainer(this.renderModal())}
      </RootContainerConsumer>
    );
  }
}
export default ModalComponent;
