import React, { Fragment } from 'react';
import _isNaN from 'lodash/isNaN';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import _isArray from 'lodash/isArray';
import './progress-bar.scss';

const uniqKey = () => Math.random().toString(36).substr(2, 9);

const PROGRESS_BAR_COLORS = ['#5765D2', '#8DD3F1'];

function getPercentage(value, total) {
  return (total !== 0 && value !== 0) ? (value / total) * 100 : 0;
}

/**
 * Progress Bar component.
 *
 * @param type            - Determines the layout of the bar items.
 * @param value           - The value or values to be displayed
 * @param total           - The total number that should be used with value to calculate the percentage width.
 * @param maxTotal        - This is the maxTotal of a range of totals. This is used for the stacked type in order
 *                          to calculate the percentage width of .progress-bar__inner
 * @param fillColor       - The background color of the bar item, or an array of colours for each item.

 * @param tooltipContents - The tooltip contents to be displayed in tooltip on hover of bar
 * @param tooltipPosition - AsbolutionPosition tooltip will default too if there is room, based on bar.
 * @param vertSize        - Height of progress bars - one of sm, md
 *
 * TODO react tooltip position alter
 * TODO vert size seems unneccesary - need to figure out why a percentage height won't work.
 * TODO no tooltip on mobile
 */

const ProgressBarItem = ({ vertSize, backgroundColor, width }) => (
  <div
    className={classnames('progress-bar__item', {
      [`progress-bar__item--${vertSize}`]: vertSize,
    })}
    style={
      {
        backgroundColor,
        width: `${width}%`,
      }
    }
  />
);

ProgressBarItem.propTypes = {
  vertSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.number,
};

ProgressBarItem.defaultProps = {
  vertSize: 'md',
  backgroundColor: PROGRESS_BAR_COLORS[0],
  width: 0,
};

const ProgressBar = ({
  type,
  value,
  fillColor,
  tooltipContents,
  tooltipPosition,
  vertSize,
  maxTotal,
  total,
}) => {
  const tooltipKey = uniqKey();
  const hasValue = (value > 0 && !_isNaN(value));

  let maxDataWidth = 100;
  if (type === 'stacked' && maxTotal) {
    const sumValueTotal = !_isArray(value) ? value : value.reduce((x, num) => x + num);
    maxDataWidth = getPercentage(sumValueTotal, maxTotal);
  }

  if (!_isArray(value) && !hasValue) {
    return null;
  }

  return (
    <div
      className={classnames(`progress-bar progress-bar--${type}`)}
    >
      <div
        className="progress-bar__inner"
        role="presentation"
        data-tip
        data-for={`progress-bar-tooltip-${tooltipKey}`}
        style={
          {
            width: `${maxDataWidth}%`,
          }
        }
      >
        {_isArray(value) && (
          <Fragment>
            {value.map((val, index) => {
              const hasVal = (val > 0 && !_isNaN(val));

              if (!hasVal) {
                return null;
              }

              return (
                <ProgressBarItem
                  key={index}
                  vertSize={vertSize}
                  backgroundColor={fillColor ? fillColor[index] : PROGRESS_BAR_COLORS[index]}
                  width={getPercentage(val, total)}
                />
              );
            })}
          </Fragment>
        )}

        {!_isArray(value) && (
          <ProgressBarItem
            vertSize={vertSize}
            backgroundColor={fillColor || PROGRESS_BAR_COLORS[0]}
            width={getPercentage(value, total)}
          />
        )}
      </div>
      {tooltipContents && (
        <ReactTooltip
          className="progress-bar__tooltip"
          id={`progress-bar-tooltip-${tooltipKey}`}
          place={tooltipPosition}
          effect="solid"
          light
        >
          {tooltipContents}
        </ReactTooltip>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  fillColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  tooltipContents: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  vertSize: PropTypes.oneOf(['sm', 'md']),
  tooltipPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  type: PropTypes.oneOf(['clustered', 'stacked', 'stackedFull', 'layered']),
  maxTotal: PropTypes.number,
  total: PropTypes.number,
};

ProgressBar.defaultProps = {
  fillColor: null,
  tooltipContents: '',
  vertSize: 'md',
  tooltipPosition: 'top',
  type:"stacked",
  maxTotal: null,
  total: null,
};

export default ProgressBar;
