import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classnames from 'classnames';
import update from 'immutability-helper';
import { SketchPicker } from 'react-color';
import { result } from '@showtime/utility';

import Button from '../Button';
import { withMessages, messagesType } from '../../utility';
import './_color-field.scss';

const defaultMessages = {
  remove: 'Remove',
};

const componentToHex = (component) => {
  const hex = component.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = rgb => `#${componentToHex(+rgb.r)}${componentToHex(+rgb.g)}${componentToHex(+rgb.b)}`;

const rgba = color => `rgba(${color.r},${color.g},${color.b},${color.a})`;

const propTypes = {
  beforeOpen: PropTypes.func,
  className: PropTypes.string,
  dark: PropTypes.bool,
  disableAlpha: PropTypes.bool,
  enableTransparentOption: PropTypes.bool,
  messages: messagesType.isRequired,
  onChange: PropTypes.func,
  presetColors: PropTypes.arrayOf(PropTypes.node),
  toggleElement: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      r: PropTypes.number,
      g: PropTypes.number,
      b: PropTypes.number,
      a: PropTypes.number,
    }),
    PropTypes.string.isRequired,
  ]),
};

const defaultProps = {
  beforeOpen: _noop,
  className: '',
  dark: false,
  disableAlpha: false,
  enableTransparentOption: false,
  onChange: _noop,
  presetColors: [],
  value: '',
  toggleElement: null,
};

// TODO Colorpicker to return rgb or hex versions?

/**
 * Color Field CustomColorPicker:
 *
 * Available Props:
 * @param {func}  beforeOpen - Callback to fire, to do something externally before color picker opens,
 * @param {str||ob}  className
 * @param {str||obj} dark
 * @param {bool}  disableAlpha - Toggles whether to show the Alpha color channel or not.
 * @param {bool}  enableTransparentOption - Adds a removeColor option if color was selected. default false.
 * @param {obj}   messages - Translated copy for component
 * @param {func}  onChange - Callback when a change happened
 *    returns {rgb} object.
 * @param {bool}  presetColors - Default is none but there is the option to add preset colors
 * @param {node}  toggleElement - Toggle element or function that renders one.
 *    function takes isOpen state as it's only paramerter.
 * @param {str||obj} value - hex or rgba representation of current color.
 */
class ColorPicker extends Component {
  state = {
    isOpen: false,
    popoverPosition: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
  };

  handlebgColorPickerOpen = (e) => {
    this.props.beforeOpen();
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpen: true });

    /*
    Getting the colorField element's position and positioning the
    the colorPicker on the bottom left of it.
    */
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = this.colorField.getBoundingClientRect();
    const bottomOffset = (elemRect.bottom - bodyRect.bottom) * -1;
    const headerHeight = 50;
    const colorPickerHeight = 225;
    const inputHeight = 36;

    /*
    If the bottomOffset plus the height of the header and color picker is bigger
    than the size of the screen it means that the color picker is getting cut on
    top of the page. So we need to adjust it.
    */
    const bottomPos =
      bottomOffset + headerHeight + colorPickerHeight > bodyRect.bottom
        ? bottomOffset + inputHeight - colorPickerHeight
        : bottomOffset;

    this.setState(({ popoverPosition }) => ({
      popoverPosition: update(popoverPosition, {
        $merge: {
          bottom: bottomPos,
          left: this.colorField.getBoundingClientRect().left,
        },
      }),
    }));
  };

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ isOpen: false });
  };

  handleSelection = (swatch) => {
    this.props.onChange(swatch.rgb);
  };

  handleClearColorSelection = () => {
    this.props.onChange('');
  };

  renderToggleElement = () => {
    const element = result(this.props.toggleElement, this.state.isOpen);

    return (
      element && (
        <span
          role="button"
          onClick={this.handlebgColorPickerOpen}
        >
          {element}
        </span>
      )
    );
  }

  renderDefaultToggle = () => {
    const { value, messages, enableTransparentOption } = this.props;

    const bgColor = value ? rgba(value) : '';
    const hexColor = value ? rgbToHex(value) : '';
    return (
      <Fragment>
        <button
          className="color-field__select"
          onClick={this.handlebgColorPickerOpen}
        >
          <div
            className={classnames('picker__swatch', {
              'is-transparent': !value,
            })}
            style={{ background: bgColor }}
          />

          {value && <span className="picker__text">{hexColor}</span>}
        </button>

        {(enableTransparentOption && value) && (
          <Button
            type="link"
            className="color-field__clear"
            onClick={this.handleClearColorSelection}
          >
            {messages.remove}
          </Button>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      disableAlpha,
      presetColors,
      dark,
      value,
      toggleElement,
      className,
    } = this.props;

    const currentColor = value || '';

    return (
      <section
        ref={(node) => (this.colorField = node)}
        className={classnames(className, {
          'sta-form-element color-field': !toggleElement,
          'color-field--dark': dark,
        })}
      >
        {toggleElement && this.renderToggleElement()}
        {!toggleElement && this.renderDefaultToggle()}

        {this.state.isOpen && (
          <div className="popover" style={this.state.popoverPosition}>
            <div className="cover" onClick={this.handleClose} />
            <SketchPicker
              disableAlpha={disableAlpha}
              presetColors={presetColors}
              color={currentColor}
              onChange={this.handleSelection}
            />
          </div>
        )}
      </section>
    );
  }
}

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;

export default withMessages('colorPicker', defaultMessages)(ColorPicker);
