import React from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import Button from '../Button';
import './_alert.scss';

const ICON_STATUS_MAPPINGS = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

export const ALERT_TYPE_OPTIONS = ['embedded', 'banner', 'toast'];
export const ALERT_STATUS_OPTIONS = ['success', 'error', 'info', 'warning'];

/**
 * Alert Component
 *
 * Abstract Link: https://tinyurl.com/ycsduonv
 * Sprocket Guidelines: https://showtimeanalytics.atlassian.net/wiki/spaces/FE/pages/1101692931/Alert
 *
 * TODO - update to correct status icons when upicons are updated.
 * @param {string} status - Determines the style of the alert.
 * @param {string} type - Determines the layout of the alert
 * @param {string} className - Additional classname that may be applied.
 * @param {node} children - content of Alert.
 * @param {function} onRequestClose - Callback Function when Alert close button selected.
 */

class Alert extends React.Component {
   onRequestClose = () => {
     if (_isFunction(this.props.onRequestClose)) {
       this.props.onRequestClose();
     } else {
       console.warn('Toast Alert detected with no corresponding onRequestClose');
     }
   };

   showCloseAction = () => {
     const { onRequestClose, type } = this.props;
     return onRequestClose || type === 'toast';
   };

   render() {
     const { status, type, className, children } = this.props;
     return (
       <section className={`alert alert--${status} alert--${type} ${className}`}>
         <i className={`alert__icon icon-${ICON_STATUS_MAPPINGS[status]}`} />
         <div className="alert__content">{children}</div>
         {this.showCloseAction() && (
           <Button
             size="lg"
             type="link"
             aria-label="Close"
             className="alert__action--close"
             icon="icon-cross"
             onClick={this.onRequestClose}
           />
         )}
       </section>
     );
   }
}

Alert.propTypes = {
  status: PropTypes.oneOf(ALERT_STATUS_OPTIONS),
  type: PropTypes.oneOf(ALERT_TYPE_OPTIONS),
  className: PropTypes.string,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
};

Alert.defaultProps = {
  className: '',
  children: null,
  type: 'embedded',
  status: 'info',
  onRequestClose: undefined,
};

export default Alert;
