import React from 'react';
import PropTypes from 'prop-types';
import './../_kawaii.scss';

const KawaiiWrapper = ({
  style,
  width,
  height,
  children,
}) => (
  <div
    className="kawaii-wrapper"
    style={style}
    width={width}
    height={height}
  >
    {children}
  </div>
);

KawaiiWrapper.propTypes = {
  style: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default KawaiiWrapper;
