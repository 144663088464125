import formatNumber from './formatNumber';

/**
 * Format Short Number.
 * @param integer val: value to be formatted
 * @param object intl: eg:  {locale: 'en-GB'}
 * @param integer precision: length of decimal, default is 1.
 * @param integer minTier: a number describing the order of magnitude.
 *    Follows 10 ^ (3 * minTier) formula.
 *    Value is only abbreviated when it's magnitude greater or equal to minTier.
 *
 * @returns {string}
 */

const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
const formatAbbreviatedNumber = (val, intl, precision = 1, minTier = 2) => {
  let output = 0;
  // what tier? (determines SI symbol)
  let tier;
  if (val < 0) {
    tier = (Math.log10(-val) / 3) | 0;
  } else {
    tier = (Math.log10(val) / 3) | 0;
  }

  // only adding suffix when the value is greater than a 10 ^ (3 * minTier)
  if (tier < minTier) {
    output = formatNumber(val, intl, precision);
  } else {
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the val
    const scaled = val / scale;

    // format val and add suffix
    output = `${formatNumber(scaled, intl, precision).replace(/\.0$/, '')}${suffix}`;
  }

  return output;
};

export default formatAbbreviatedNumber;
