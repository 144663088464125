import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _constant from 'lodash/constant';
import _identity from 'lodash/identity';
import _noop from 'lodash/noop';
import _escapeRegExp from 'lodash/escapeRegExp';
import _isString from 'lodash/isString';
import { result } from '@showtime/utility';

import Input from '../Input';
import Checkbox from '../Checkbox';
import { withMessages, messagesType } from '../../utility';

const componentName = 'checkboxes';

const enhance = withMessages(componentName);

/**
 * Checkboxes Component.
 * Takes an array of values and emit its subset based on checked checkboxes.
 *
 * Available Props:
 * @param {array} source - An array of all possible values.
 * @param {array} value - A subset of source.
 * @param {function} onChange - A function called with subset of source,
 *   whenever state changes.
 * @param {bool} dark - theme prop passed to each checkbox.
 * @param {string} className - className modifier for the component.
 * @param {bool|function} itemDisabled - disabled state of checkbox
 *   or function that returns disabled state.
 * @param {string|function} itemClassName - className for each checkbox
 *   or function, that returns className.
 * @param {function} itemLabel - Label render function.
 *   Useful when source is an array of objects!
 * @param {function} itemKey - Function that returns unique identifier for source item.
 *   Used for checkbox's key and checked state checks.
 *   Important when source is an array of objects!
 * @param {function} shouldItemRender - a predicate run against each item.
 *   When false the item will not render.
 * @param {bool} hasCheckAll - When true displays check all checkbox.
 * @param {string} checkAllLabel - Label for check all checkbox if hasCheckAll is true.
 * @param {bool|function} checkAllDisabled - disabled state of check all checkbox
 *   or function that returns disabled state.
 * @param {bool} hasSearch - Will display a search input between select all and checkbox list
 * @param {func} itemSearchBy - Function that returns a string value which acts as filter criteria
 *   for the list
 */

class Checkboxes extends Component {
  static propTypes = {
    source: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    dark: PropTypes.bool,
    className: PropTypes.string,
    itemDisabled: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    itemIndeterminate: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    itemClassName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    itemLabel: PropTypes.func,
    itemContent: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]),
    itemKey: PropTypes.func,
    shouldItemRender: PropTypes.func,
    hasCheckAll: PropTypes.bool,
    hasSearch: PropTypes.bool,
    itemSearchBy: PropTypes.func,
    checkAllLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    checkAllDisabled: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    messages: messagesType.isRequired,
  };

  static defaultProps = {
    value: [],
    dark: false,
    className: '',
    itemDisabled: _constant(false),
    itemClassName: '',
    itemLabel: _identity,
    itemKey: _identity,
    itemContent: null,
    itemIndeterminate: _constant(false),
    shouldItemRender: _constant(true),
    itemSearchBy: _identity,
    onChange: _noop,
    hasCheckAll: false,
    hasSearch: false,
    checkAllLabel: undefined,
    checkAllDisabled: _constant(false),
  };

  static defaultMessages = {
    checkAllLabel: 'Select All',
  };

  static getDerivedStateFromProps({ value, itemKey }, { valueMemo }) {
    // valueMemo is a state property holding the last value coming from props.
    // used to sync component state with incoming props.
    if (value !== valueMemo) {
      return {
        valueMemo: value,
        value: new Set(value.map(itemKey)),
      };
    }

    return null;
  }

  state = {
    valueMemo: this.props.value,
    value: new Set(this.props.value.map(this.props.itemKey)),
    searchText: '',
  };

  onChange = key => (checked) => {
    this.setState(
      ({ value }) => {
        const newValue = new Set(value);
        if (checked) {
          newValue.add(key);
        } else {
          newValue.delete(key);
        }

        return { value: newValue };
      },
      this.valueChanged,
    );
  }

  onCheckAll = checked =>
    this.setState(
      (_, { source, itemKey }) => ({
        value: checked ? new Set(source.map(itemKey)) : new Set(),
      }),
      this.valueChanged,
    )

  valueChanged = () => {
    const { source, onChange, itemKey } = this.props;
    const value = source.filter(item => this.isChecked(itemKey(item)));

    onChange(value);
  }

  isChecked = key => this.state.value.has(key);

  areAllChecked = () =>
    this.props.source.every(item => this.isChecked(this.props.itemKey(item)))

  isAnyChecked = () =>
    this.props.source.some(item => this.isChecked(this.props.itemKey(item)))

  renderCheckAllCheckbox = () => {
    const {
      messages,
      dark,
      checkAllLabel = messages.checkAllLabel,
      checkAllDisabled,
    } = this.props;
    const isChecked = this.areAllChecked();
    const isIndeterminate = this.isAnyChecked();
    const isDisabled = result(checkAllDisabled, { isChecked });

    return (
      <Checkbox
        dark={dark}
        className="check-all"
        label={result(checkAllLabel, { isChecked, isDisabled })}
        value={isChecked}
        disabled={isDisabled}
        indeterminate={isIndeterminate}
        onChange={this.onCheckAll}
      />
    );
  }

  renderCheckbox = (item) => {
    const {
      dark, itemKey, itemLabel, itemClassName, itemDisabled, itemIndeterminate, itemContent,
    } = this.props;
    const key = itemKey(item);
    const isChecked = this.isChecked(key);
    const isDisabled = result(itemDisabled, item, { isChecked });
    const isIndeterminate = result(itemIndeterminate, item, { isChecked, isDisabled });
    const content = result(itemContent, item, { isChecked, isDisabled });
    const className = result(itemClassName, item, { isChecked, isDisabled });

    return (
      <Checkbox
        key={key}
        dark={dark}
        className={className}
        label={itemLabel(item, { isChecked, isDisabled })}
        value={isChecked}
        disabled={isDisabled}
        indeterminate={isIndeterminate}
        onChange={this.onChange(key)}
        content={content}
      />
    );
  };

  setSearchState = (text) => {
    this.setState({
      searchText: text,
    });
  }

  renderSearchInput = () => {
    const searchText = this.state.searchText;
    const setSearchState = this.setSearchState;

    return (
      <Input
        type="search"
        value={searchText}
        onChange={setSearchState}
      />
    );
  }

  filterBySearchText = (item, index) => {
    const hasSearch = this.props.hasSearch;
    const search = this.state.searchText;
    const test = this.props.itemSearchBy(item, index);

    if (hasSearch && search.length > 0 && _isString(test)) {
      const regexSearch = new RegExp(_escapeRegExp(search), 'i');

      return test.search(regexSearch) > -1;
    }

    return true;
  }

  render() {
    const { dark, className, source, shouldItemRender, hasCheckAll, hasSearch } = this.props;
    const classNames = classnames(
      componentName,
      {
        [`${componentName}--dark`]: dark,
        [`${componentName}--${className}`]: className,
      },
    );

    return (
      <div className={classNames}>
        {hasCheckAll && this.renderCheckAllCheckbox()}
        {hasSearch && this.renderSearchInput()}
        <div className={`${componentName}__list`}>
          {source
            .filter(shouldItemRender)
            .filter(this.filterBySearchText)
            .map(this.renderCheckbox)}
        </div>
      </div>
    );
  }
}

export default enhance(Checkboxes);
