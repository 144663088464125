import { hasRule, setScreenRules } from '@showtime/permissions';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './_sidebar-nav.scss';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _identity from 'lodash/identity';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  support: 'Support',
  circuit_admin: 'Circuit Admin',
  settings: 'Settings',
  help_center: 'Help',
  logout: 'Logout',
};

const MENU_ITEMS = [
  {
    app: 'insights',
    title: 'Insights',
    rule: 'showtime.insights',
  },
  {
    app: 'engage',
    title: 'Engage',
    rule: 'showtime.engage',
  },
  {
    app: 'benchmark',
    title: 'Benchmark',
    rule: 'showtime.benchmark',
  },
  {
    app: 'community',
    title: 'Community',
    rule: 'showtime.community',
  },
  {
    app: 'beta',
    title: 'Beta',
    rule: 'showtime.beta',
  },
  {
    app: 'playback',
    title: 'Playback',
    rule: 'showtime.playback',
  },
  {
    app: 'gates',
    title: 'Gates',
    rule: 'showtime.gates',
  },
  {
    app: 'gower',
    title: 'Gower Street Analytics',
    rule: 'showtime.gower',
  },
  {
    app: 'customer-analytics',
    title: 'Customer Analytics',
    rule: 'showtime.customer-analytics',
  },
];

class SidebarNav extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    onAlertMessage: PropTypes.func.isRequired,
    hasIntercom: PropTypes.bool.isRequired,
    userModel: PropTypes.shape({
      screenRules: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    messages: messagesType.isRequired,
    menuActions: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      render: PropTypes.func,
    })),
  };

  static defaultProps = {
    menuActions: [],
  }

  componentWillMount() {
    // Initializing the showtime-permissions for sidebar menuItems
    const screenRules = this.props.userModel.screenRules;
    setScreenRules(screenRules);
  }

  handleLogout = () => this.props.onLogout();

  handleAlertMessage = () => {
    const messengerOpen = document.querySelectorAll('.intercom-messenger-frame').length > 0;

    try {
      if (messengerOpen) {
        window.Intercom('hide');
      } else {
        window.Intercom('show');
      }
    } catch (e) {
      console.warn(
        '⚠️ You need to have intercom initialized in the application ⚠️ \n' +
        '⚠ If it is not required set hasIntercom prop to false ⚠',
      );
      console.error(e);
    }
  };

  predefinedMenuActions = [
    {
      key: 'showtime.circuitAdmin',
      render: () => hasRule('showtime.circuitAdmin') && (
        <a href="/circuit-admin">{this.props.messages.circuit_admin}</a>
      ),
      group: 'circuit',
    },
    {
      key: 'showtime.settings',
      render: () => <a href="/user/settings">{this.props.messages.settings}</a>,
      group: 'user',
    },
    {
      key: 'showtime.help',
      render: () => !hasRule('showtime.disable.help') && (
        <a href="/help" target="_blank">{this.props.messages.help_center}</a>
      ),
      group: 'user',
    },
  ]

  renderMenuItems = () => {
    // Get the path of the current product
    const [, appPath] = window.location.pathname.split('/');

    return MENU_ITEMS
      .filter(item => hasRule(item.rule))
      .map(item => (
        <div
          key={item.rule}
          className={
            classNames({
              'sidebar-main-navigation__app-icon-item': true,
              'sidebar-main-navigation__app-icon-item--active': appPath === item.app,
            })
          }
        >
          <a href={`/${item.app}`} className={`icon-${item.app}`} data-app-icon="true" />
          <div className="sidebar-main-navigation__tooltip">{item.title}</div>
        </div>
      ));
  };

  renderMenuAction = ({ key, render = _noop }) => {
    const element = render();

    return element && (
      <div className="user-info-box__item" key={key}>
        {element}
      </div>
    );
  };

  renderMenuActionGroup = (actions, groupKey) => {
    const groupActions = actions.map(this.renderMenuAction).filter(_identity);

    return groupActions.length ? (
      <div key={groupKey || 'null_group'} className="user-info-box__bordered">
        {groupActions}
      </div>
    ) : null;
  };

  renderMenuActions() {
    const { menuActions } = this.props;
    const {
      circuit: circuitGroup,
      user: userGroup,
      ...otherGroups
    } = _groupBy([...this.predefinedMenuActions, ...menuActions], 'group');

    return (
      <Fragment>
        {/* Predefined groups are rendered first */}
        {this.renderMenuActionGroup(circuitGroup, 'circuit')}
        {this.renderMenuActionGroup(userGroup, 'user')}
        {_map(otherGroups, this.renderMenuActionGroup)}
      </Fragment>
    );
  }

  render() {
    const {
      userModel,
      hasIntercom,
      messages,
    } = this.props;

    const {
      renderMenuItems,
    } = this;


    if (_isEmpty(userModel)) {
      return null;
    }

    return (
      <aside id="sidebar-main-navigation" className="sidebar-main-navigation">
        <nav className="sidebar-main-navigation__app-icons">
          {renderMenuItems()}
        </nav>

        <div className="sidebar-main-navigation__bottom-section">

          {hasIntercom  && !hasRule('showtime.disable.intercom') && (
            <div
              className="sidebar-main-navigation__app-icon-item"
              onClick={this.handleAlertMessage}
            >
              <span className="icon icon-intercom" />
              <div className="sidebar-main-navigation__tooltip">{messages.support}</div>
            </div>
          )}

          <div className="sidebar-main-navigation__user" data-user-icon="user-icon">
            <i className="sidebar-main-navigation__user__icon icon-user" />
            <div className="info-box-wrapper">
              <div className="user-info-box">
                <div className="user-info-box__bordered">
                  <span className="sidebar-main-navigation__user-wrapper">
                    <i className="sidebar-main-navigation__user__icon icon-user" />
                    <div className="user-info-box__user-info">
                      <div className="user-info-box__user--name">
                        <a href="/user/settings">
                          {userModel.name.firstName}
                          &nbsp;
                          {userModel.name.lastName}
                        </a>
                      </div>
                      <div className="user-info-box__user--email">{userModel.email}</div>
                    </div>
                  </span>
                </div>

                {this.renderMenuActions()}

                <div className="user-info-box__item" onClick={this.handleLogout}>{messages.logout}</div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

export default withMessages('sidebarNav', defaultMessages)(SidebarNav);
