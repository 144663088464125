import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _bindAll from 'lodash/bindAll';
import _noop from 'lodash/noop';
import classnames from 'classnames';
import './_accordion.scss';

export const HEADER_SIZES = ['xs', 'sm', 'md', 'lg'];

/**
 * Accordian Item Component
 *
 * @param {boolean} allowMultipleOpen - By default only one accoridionItem is
 * open at one time. Set this to true to allow for multiple to be open at the same time.
 */
class AccordionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isOpen,
    };

    _bindAll(this, [
      'onSelect',
      'isItemOpen',
    ]);
  }

  onSelect() {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.onClick(this.props.id);
  }

  /**
   * By default accordianItems will rely on props.isOpen to know their open state.
   * However if allowMultipleOpen then each item will rely on their own isOpen
   * state so that multiple items can be open at the same time.
   */
  isItemOpen() {
    if (this.props.allowMultipleOpen) {
      return this.state.isOpen;
    }

    return this.props.isOpen;
  }

  render() {
    const {
      label,
      className,
      children,
      headerSize,
    } = this.props;

    const isItemOpen = this.isItemOpen();

    return (
      <div
        className={classnames('accordion__item', className, {
          'accordion__item--open': isItemOpen,
        })}
      >
        <div
          className={`accordion__item__header accordion__item__header--${headerSize}`}
          role="tab"
          onClick={() => this.onSelect()}
        >
          {label}
        </div>
        <div
          className="accordion__item__content"
          role="tabpanel"
          aria-expanded={isItemOpen}
        >
          {isItemOpen && children}
        </div>
      </div>
    );
  }
}

AccordionItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerSize: PropTypes.oneOf(HEADER_SIZES),
  allowMultipleOpen: PropTypes.bool,
};

AccordionItem.defaultProps = {
  dark: false,
  className: '',
  isOpen: false,
  id: null,
  onClick: _noop,
  headerSize: 'md',
  allowMultipleOpen: false,
};

export default AccordionItem;
