import React, { Component } from 'react';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { MessagesConsumer } from '../components/provider';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';


const withMessages = (componentName, defaults = {}) => {
  return (Component) => {
    const defaultMessages = { ...defaults, ..._get(Component, 'defaultMessages', {}) };

    const WrappedComponent = ({ forwardedRef, messages = {}, ...props }) => (
      <MessagesConsumer>
        {(contextMessages) => (
          <Component
            {...props}
            ref={forwardedRef}
            messages={{
              // Default messages, which may be defined statically on the component
              // or as an optional argument to withMessages. (Can also be used this way to
              // override class defaults if recomposing an existing component)
              ...defaultMessages,

              // First, apply messages from top-level context
              // (Restricting to the defined set of keys from default messages)
              ..._pick(contextMessages, Object.keys(defaultMessages)),

              // Next, apply messages from component specific context
              ..._get(contextMessages, componentName, {}),

              // Finally, apply props passed directly to the component
              ...messages,
            }}
          />
        )}
      </MessagesConsumer>
    );

    return forwardRef((props, ref) => {
      return <WrappedComponent {...props} forwardedRef={ref} />;
    });
  };
};

const messageTypes = [PropTypes.string, PropTypes.func, PropTypes.node, PropTypes.objectOf(PropTypes.string)];

export const messagesType = PropTypes.objectOf(PropTypes.oneOfType([
  ...messageTypes,
  PropTypes.objectOf(PropTypes.oneOfType([...messageTypes])),
]));

export default withMessages;
