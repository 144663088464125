import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disableGutter: PropTypes.bool,
  disablePadding: PropTypes.bool
};

const defaultProps = {
  className: '',
  disableGutter: false,
  disablePadding: false,
};

const componentClass = 'card__content';

/**
 * The content of a card
 * @param {node} children
 * @param {string} className        - Able to add custom classnames
 * @param {boolean} disablePadding  - modifies css with no padding
 * @param {boolean} disableGutter   - modifies css with no left-right margin in content
 * @param {object} props            - Dynamic props to add to spread across the main div.
 * */
const CardContent = ({ children, className, disablePadding = false, disableGutter = false, ...props }) =>
  <div {...props} className={classnames(componentClass, className, {
    [`${componentClass}--no-gutter`]: disableGutter,
    [`${componentClass}--no-padding`]: disablePadding,
  })}>
    {children}
  </div>

CardContent.propTypes = propTypes;
CardContent.defaultProps = defaultProps;

export default CardContent;
