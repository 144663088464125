import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TimePickerReact from 'rc-time-picker';
import moment from 'moment';
import FormField from '../FormField';
import './_time-picker.scss';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  timezone(timezone, zone, offset) {
    return `${timezone} Time (${zone} ${offset})`;
  },
  time: 'Time',
  invalidTime: 'Please select a valid time',
};

const HOURS_IN_DAY = 24;
const MINS_IN_HOUR = 60;
/**
 * Main TimePicker Component.
 *
 * Add reset button via react time-pickers add-on.
 * @param { date object} value
 * @returns { date object}
*/
class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorText: props.errorText || props.messages.invalidTime,
    };
  }

  isTodaySelected = () => {
    return moment(this.props.value).isSame(Date.now(), 'day');
  }

  getTimeZoneMessage = () => {
    if (this.props.showTimeZone) {
      // TODO figure a way to get GMT, EST etc. as moment (z) is deprecated,
      // need to use moment-timezone to get it. Or check if splicing the end of
      // Date.s.toUTCString() will work for every timezone --> "Fri, 25 Aug 2017 11:24:15 GMT"
      const zone = '';
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const offset = moment(new Date()).format('ZZ');

      return this.props.messages.timezone(timeZone, zone, offset);
    }
  }

  handleSelection = (selectedDate) => {
    this.props.onChange(moment(selectedDate).toDate());
  }

  generateOptions = (length, excludedOptions) => {
    // if empty nothing is being excluded show eveything.
    if (!excludedOptions) {
      return;
    }

    // exclude all values except those in array.
    const arr = [];
    for (let value = 0; value < length; value++) {
      if (excludedOptions.indexOf(value) < 0) {
        arr.push(value);
      }
    }
    return arr;
  }

  /**
   * @param {array} restricted array of allowed minutes.
   */
  restrictMinuteOptions = (restrictedOptions) => {
    const restricted = restrictedOptions || [];
    // if nothing is restricted it means show all.
    if (!restrictedOptions) {
      for (let i = 0; i < MINS_IN_HOUR; i++) {
        restricted.push(i);
      }
    }

    if (this.isTodaySelected() && !this.props.use12Hours) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes(); // 18
      const selectedHour = new Date(this.props.value).getHours();

      if (currentHour === selectedHour) {
        // if i is within quarter && less than currentMinutes - remove from array.
        for (let i = 0; i < MINS_IN_HOUR; i++) {
          const index = restricted.indexOf(i);
          if (index > -1 && i < currentMinutes) {
            restricted.splice(index, 1);
          }
        }
      }
    }
    return restricted;
  }

  disabledMinutes = (disableBy) => {
    switch (disableBy) {
      case 'quarter':
        return this.generateOptions(MINS_IN_HOUR, this.restrictMinuteOptions([0, 15, 30, 45]));
      case 'half':
        return this.generateOptions(MINS_IN_HOUR, this.restrictMinuteOptions([0, 30]));
      default:
        return this.generateOptions(MINS_IN_HOUR, this.restrictMinuteOptions());
    }
  }

  /**
   * If today is selected, get current time and disable hours before this.
   * only works for properly for 24hour time mode.
   */
  disabledHours = () => {
    if (this.isTodaySelected() && !this.props.use12Hours) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      const includeArray = [];
      for (let i = currentHour; i < HOURS_IN_DAY; i++) {
        includeArray.push(i);
      }
      return this.generateOptions(HOURS_IN_DAY, includeArray);
    }
  }

  render() {
    const {
      messages,
      showSecond,
      showMinute,
      use12Hours,
      className,
      disabledMinutesFormat,
      value,
      hasError,
      label,
      subText,
      id,
      hideLabel,
      hideTimezone,
      currentValue,
    } = this.props;

    return (
      <FormField
        label={hideLabel ? null : (label || messages.time)}
        subText={subText}
        className="time-picker-ctn"
        descriptionText={hideTimezone ? null : this.getTimeZoneMessage()}
        errorText={this.state.errorText}
        hasError={hasError}
      >
        <TimePickerReact
          id={id}
          className={classnames('time-picker', className, {
            'time-picker--error': hasError,
          })}
          showSecond={showSecond}
          showMinute={showMinute}
          use12Hours={use12Hours}
          defaultValue={moment(value)}
          disabledMinutes={() => this.disabledMinutes(disabledMinutesFormat)}
          disabledHours={() => this.disabledHours()}
          onChange={this.handleSelection}
          allowEmpty={false}
          {...(currentValue && { value: moment(currentValue) })}
        />
      </FormField>
    );
  }
}


TimePicker.propTypes = {
  label: PropTypes.node,
  subText: PropTypes.node,
  // default value
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  showSecond: PropTypes.bool,
  showMinute: PropTypes.bool,
  showTimeZone: PropTypes.bool,
  use12Hours: PropTypes.bool,
  className: PropTypes.string,
  disabledMinutesFormat: PropTypes.string,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideTimezone: PropTypes.bool,
  messages: messagesType.isRequired,
};

TimePicker.defaultProps = {
  label: null,
  subText: null,
  showSecond: false,
  showMinute: true,
  use12Hours: true,
  className: '',
  disabledMinutesFormat: '',
  showTimeZone: true,
  errorText: '',
  hasError: false,
  id: null,
  hideLabel: false,
  hideTimezone: false,
  currentValue: null,
};

export default withMessages('timePicker', defaultMessages)(TimePicker);
