import _isRegExp from 'lodash/isRegExp';
import _reduce from 'lodash/reduce';

const hasClass = (classList, selector) => {
  const matcher = item => (_isRegExp(selector) ? selector.test(item) : selector === item);
  return _reduce(classList, (match, className) => (
    match || (className && matcher(className))
  ), false);
};

/**
 * Find an ancestor for a given element that matches a class name
 * @param element
 * @param selector
 * @returns {*}
 */
const findAncestor = (element, selector) => {
  let currentElement = element;
  do {
    currentElement = currentElement.parentElement;
  } while (currentElement && !hasClass(currentElement.classList, selector));

  return currentElement;
};

export default findAncestor;
