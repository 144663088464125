import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_button-group.scss';

/**
 * @deprecated - DO NOT USE! Should replace with an ElementGroup
 */
const ButtonGroup = ({ children, dark, vertical, popover, className }) => (
  <div
    className={classnames(className, {
      'button-group': !popover,
      'button-group--dark': dark,
      'button-group--vertical': vertical,
      'button-group--popover': popover,
    })}
  >
    {children}
  </div>
);

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dark: PropTypes.bool,
  vertical: PropTypes.bool,
  popover: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  className: null,
  dark: false,
  vertical: false,
  popover: false,
};

export default ButtonGroup;
