import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import Uploader, { propTypes, defaultProps } from './Uploader';
import { withMessages, messagesType } from '../../utility';

const ImageUploaderPropTypes = {
  ...propTypes,
  appendImageData: PropTypes.func,
  children: PropTypes.func,
  messages: messagesType.isRequired,
  excludedImageTypes: PropTypes.arrayOf(PropTypes.string),
};

const ImageUploaderDefaultProps = {
  ...defaultProps,
  appendImageData: undefined,
  excludedImageTypes: null,
};
/**
 * @param {array} excludedImageTypes - By default all imageTypes are allowed to be uploaded.
 * If a feature is limited to certain types only add the excluded ones to this array.
 */
class ImageUploader extends Component {
  static defaultMessages = {
    dragToUpload: null, // Allow overwriting via context, but don't by default
  };

  render() {
    const {
      children,
      appendImageData,
      excludedImageTypes,
      messages,
      ...uploaderProps
    } = this.props;
    return (
      <Uploader
        {...uploaderProps}
        messages={_omitBy(messages, _isEmpty)}
        transformUploadData={(data) => {
          // Throw an error if there was an attempt to upload a prohibited fileType.
          if (excludedImageTypes) {
            const imageType = data.file.type; // e.g 'image/svg+xml'
            let invalidImageType = null;
            excludedImageTypes.forEach((excludedType) => {
              if (imageType.includes(excludedType)) {
                invalidImageType = excludedType;
              }
            });

            if (invalidImageType) {
              this.props.onError({
                errorMessage: messages.errorInvalidImageType(invalidImageType),
                invalidImageType,
              });
              return false;
            }
          }

          if (typeof appendImageData !== 'function') {
            return data;
          }

          return new Promise((resolve) => {
            let img = new Image();
            img.onload = () => {
              const appendData = appendImageData(img) || {};
              img = null;
              resolve({ ...data, ...appendData });
            };

            if (data.url) {
              img.src = data.url;
            } else {
              // Uploading a file, need to parse it's contents
              const reader = new FileReader();
              reader.onload = (e) => {
                img.src = e.target.result;
              };
              reader.readAsDataURL(data.file);
            }
          });
        }}
      >
        {children}
      </Uploader>
    );
  }
}

ImageUploader.propTypes = ImageUploaderPropTypes;
ImageUploader.defaultProps = ImageUploaderDefaultProps;

export default withMessages('uploader.image')(ImageUploader);
