import React from 'react';
import PropTypes from 'prop-types';
import { withMessages, messagesType } from '../../utility';
import './_char-length-indicator.scss';

const defaultMessages = {
  lengthText(length) {
    return `${length} characters`;
  },
};

const defaultProps = {
  value: null,
};

const propTypes = {
  value: PropTypes.string,
  messages: messagesType.isRequired,
};

/**
 * CharLengthIndicator
 */
const CharLengthIndicator = ({ value, messages }) => {
  const length = value ? value.length : 0;
  return (
    <div className="char-length-indicator">{messages.lengthText(length)}</div>
  );
};

CharLengthIndicator.propTypes = propTypes;
CharLengthIndicator.defaultProps = defaultProps;

export default withMessages('charLengthIndicator', defaultMessages)(CharLengthIndicator);
