import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './_icon.scss';

export const ICON_SIZES = ['xs', 'sm', 'md', 'lg'];

const BASE_CLASS = 'icon';
/**
 * Use `<Icon>` Component to show an icon based on the upicon iconFont classname.
 *
 * Example Usuage:
 * `<Icon icon="favourite" className="specific-className" />`
 *
 * @returns icon node with neccessary classNames for styling.
 * e.g `<i className="icon icon--favourite specific-className-passed-as-prop" />`
 *
 * - Uses forwardRef `ref` to pass a reference to the i element. Could be used for animation purposes etc.
 * - To change icon color you can use `color` css property
 * - if no size value is passed it should inherit from it's parent font-size
 */
const IconComponent = React.forwardRef(function Icon({ icon, size, className, ...rest }, ref) {
  return (
    <i
      ref={ref}
      className={classnames(`${BASE_CLASS} ${BASE_CLASS}-${icon}`, className, {
        [`${BASE_CLASS}--${size}`]: size,
      })}
      {...rest}
    />
  );
});

IconComponent.propTypes = {
  /**
   * Optional. More specific className provided for specific styling.
   */
  className: PropTypes.string,
  /**
   * Name of icon. e.g 'favourite' or 'copy-and-paste'
   */
  icon: PropTypes.string.isRequired,
  /**
   * Optional: Adds a set font-size to icon
   *
   * If no size value is passed it should inherit from it's parent font-size
   */
  size: PropTypes.oneOf(ICON_SIZES),
};

IconComponent.defaultProps = {
  size: null,
  className: '',
};

export default IconComponent;
