import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import './_tabs.scss';

import Button from '../Button';
import ButtonGroupField from '../ButtonGroupField';
import { TAB_TYPES, TAB_TYPE_MATERIAL, TAB_TYPE_BUTTON_GROUP } from './constants';

export default class TabsNav extends Component {
  static propTypes = {
    type: PropTypes.oneOf(TAB_TYPES),
    tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
    active: PropTypes.number,
    onChangeTab: PropTypes.func,
  }

  static defaultProps = {
    type: TAB_TYPE_MATERIAL,
    active: 0,
    onChangeTab: _noop,
  }

  componentDidMount() {
    if (this.props.type === TAB_TYPE_MATERIAL) {
      // Force tab highlight to rerender (activeTab ref should be updated at this point)
      this.forceUpdate();
    }
  }

  componentDidUpdate({ active: prevActive }) {
    const { type, active } = this.props;

    if (type === TAB_TYPE_MATERIAL && active !== prevActive) {
      // Force tab highlight to rerender (activeTab ref should be updated at this point)
      this.forceUpdate();
    }
  }

  activeTab = createRef();

  renderTabHighlight() {
    if (this.props.type === TAB_TYPE_MATERIAL) {
      const tab = this.activeTab.current;
      let style = { display: 'none' };

      if (tab) {
        style = {
          left: `${tab.offsetLeft}px`,
          width: `${tab.clientWidth}px`,
        };
      }

      return (
        <div className="sta-tabs__nav__progress">
          <span
            className="sta-tabs__nav__progress__indicator"
            style={style}
          />
        </div>
      );
    }

    return null;
  }

  renderTabs() {
    const { type, tabs, active, onChangeTab } = this.props;

    if (type === TAB_TYPE_BUTTON_GROUP) {
      return (
        <nav className="sta-tabs__nav">
          <ButtonGroupField
            options={tabs.map((tab, index) => ({
              text: tab.props.label,
              disabled: tab.props.disabled,
              value: index,
            }))}
            onChange={onChangeTab}
            value={active}
            buttonClass="sta-tabs__nav__tab"
            selectedClass="sta-tabs__nav__tab--active"
          />
        </nav>
      );
    }

    return (
      <nav className="sta-tabs__nav">
        {tabs.map((tab, index) => (
          <div
            key={`${tab.key}-nav`}
            className={classnames('sta-tabs__nav__tab', {
              'sta-tabs__nav__tab--active': active === index,
            })}
            ref={active === index ? this.activeTab : null}
          >
            <Button
              type="link"
              className="button--link--no-decorate"
              onClick={() => onChangeTab(index)}
              disabled={tab.props.disabled}
            >
              {tab.props.label}
            </Button>
          </div>
        ))}
      </nav>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderTabs()}
        {this.renderTabHighlight()}
      </Fragment>
    );
  }
}
