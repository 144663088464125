import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import Select, { Creatable, Async, AsyncCreatable } from 'react-select';

import './_dropdown-field.scss';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  noResults: 'No results found',
};

/**
 * DropDown Component
 * Uses react-select https://github.com/JedWatson/react-select
 * Package.json is now using the forked version https://github.com/louisemccomiskey/react-select
 * As it contains the async pagination features. - https://deploy-preview-1237--react-select.netlify.com/
 * This is due to be merged into main package soon - https://github.com/JedWatson/react-select/pull/1237
 *
 * @param className can specify a modifier class e.g dropdown-field--tags
 * @param dark (default false) css color scheme.
 * @param disabled disable input as well as adding specific styles.
 * @param options object containing label and value keys.
 * @param labelKey Default key is label. Alter if needed
 * @param isLoading When list items are loading
 * @param maxLength max number of characters for input.
 * @param minLength min number of characters for input.
 * @param multi (default false) single or multiple selection.
 * @param onChange callback function when selection is made.
 * @param onBlurResetsInput default true.
 * @param placeholder change the text displayed when no option is selected.
 * @param onInputChange Fired when user types into input. Requires Searchable to be true.
 * @param searchable Boolean whether list can be searched via user input.
 * @param translate (default true), whether option labels need translated.
 * @param value string or number if single. Array if multi.
 * @param valueKey Default key is value. Alter if needed.
 *
 * Server-side Loading and Searching Props
 * @param async If true - Asynchronously load the next set of data and support server-side searching
 * @param autoload If true and aync it will autoload the first set of results onDidMount
 * @param pagination Loads next page of options when bottom of dropdown list is reached.
 * @param loadOptions Method used to load options Asynchronously and return a Promise when loaded.
 * @param uniqueKey Use sparingly. Bit hacky but async has no easy way yet to refresh the currently
 * visible options within loadOptions. As you cannot call loadOptions outside of the react-select.
 * Therefore in order to force a refresh the key can be changed which will trigger
 * and unmount and mount again.
 *
 * Add new item props:
 * @param appendable (default false) Ability to add a new item within the list.
 * @param appendableButtonVisible - Default false = Create new option will show onType of text.
   If true create new button will always be shown at the top of the list.
 * @param appendText pass optional translation key to override the default "Create new" label
   value shown in dropdown input. For e.g `Action Movie` (Modified)
 * @param onAppend callback function when a new item is added.
 * @param isAppending Boolean alerts when the api call is still adding the item.
 */
class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.createNewButtonAdded = false;
  }

  componentWillReceiveProps(nextProps) {
    // clear input when create tag loading changes from true back to false.
    if (
      this.props.isAppending &&
      this.props.isAppending !== nextProps.isAppending
    ) {
      this.clearSelectInput();
    }

    if (this.props.uniqueKey !== nextProps.uniqueKey) {
      this.createNewButtonAdded = false;
    }
  }

  /**
   * Determines the type of react-select to use.
   * Default is the basic Select.
   */
  getDropdownType = () => {
    if (this.props.async) {
      return this.props.appendable ? AsyncCreatable : Async;
    }
    return this.props.appendable ? Creatable : Select;
  };

  getOptionProps = () => {
    if (this.props.async) {
      if (this.props.uniqueKey) {
        return {
          loadOptions: this.loadAsyncOptions,
          key: this.props.uniqueKey,
        };
      }
      return {
        loadOptions: this.loadAsyncOptions,
      };
    }

    return {
      options: this.parseOptions(this.props.options),
    };
  };

  /**
   * Props added for appendable dropdowns.
   */
  getAppendableProps = () => {
    if (this.props.appendable) {
      return {
        onNewOptionClick: this.onAppend,
        isOptionUnique: this.isOptionUnique,
        promptTextCreator: this.props.promptTextCreator,
        isLoading: this.props.isAppending,
      };
    }

    return {};
  };

  /**
   * Aysync and AsycnCreatable use this to return a promise of the parents loadOptions.
   * Reason it is wrapped like so is to also force new options
   * to be run through this.parseOptions() as well.
   */
  loadAsyncOptions = (inputValue, page) =>
    this.props
      .loadOptions(inputValue, page)
      .then((options) => ({ options: this.parseOptions(options) }));

  /**
   * Single selection returns single value (number or string)
   * Multiple selections returns an array of strings or numbers.
   */
  onSelectChange = (selected) => {
    if (this.props.multi) {
      return this.props.onChange(selected.map((item) => item.value));
    }

    if (!selected) {
      return this.props.onChange(null);
    }

    if (this.props.async) {
      return this.props.onChange(selected);
    }

    this.props.onChange(selected.value);
  };

  onAppend = (option) => {
    this.props.onAppend(option.value);
  };

  focusInput = () => {
    this.selectElement.focus();
  };

  /**
   * When a new tag has been uploaded need to clear any text input.
   */
  clearSelectInput = () => {
    this.selectElement.select.selectValue('');
  };

  /**
   * Converting all values to lowercase and comparing them to ensure that they are not the same.
   * E.g you should not be able to create a new tag "Summer promo" if "summer promo" already exists.
   */
  isOptionUnique = ({ option, options, labelKey, valueKey }) =>
    options.filter(
      (existingOption) =>
        existingOption[labelKey].toLowerCase() ===
          option[labelKey].toLowerCase() ||
        existingOption[valueKey].toLowerCase() ===
          option[valueKey].toLowerCase(),
    ).length === 0;

  renderIcon(option) {
    const icon =
      option.value === 'create_new' ? 'icon-plus-circle' : option.icon;

    return icon ? (
      <div className="dropdown-field__option-icon">
        <i className={`icon ${icon}`} />
      </div>
    ) : null;
  }

  /**
   * Rendering a custom element so we have more control over the styling.
   */
  renderDropdownOption = (option) => (
    <div
      className={classnames('dropdown-field__option', {
        'dropdown-field__option--create-new': option.value === 'create_new',
      })}
    >
      {this.renderIcon(option)}
      <div className="dropdown-field__option-content">
        <h5 className="dropdown-field__option-label">
          {option.label}
          {option.subtext && (
            <span className="dropdown-field__option-subtext">
              {option.subtext}
            </span>
          )}
        </h5>
        {option.description && (
          <div className="dropdown-field__option-description">
            {option.description}
          </div>
        )}
      </div>
    </div>
  );

  renderDropdownValue = (option) => {
    const { label, icon } = option;

    return this.renderDropdownOption({ label, icon });
  };

  /**
   * Formatting the optionsList depending on whether the
   * label and subtext need to be translated or not.
   */
  parseOptions = (options) => {
    const { appendableButtonVisible, appendable } = this.props;

    const parsedOptions = [...options];

    // Forces a "Create new" button to be at the top of the list.
    // If Asynchronously loading more into the list via loadOptions -
    // also need to only ever add this create_new once
    if (appendableButtonVisible && appendable && !this.createNewButtonAdded) {
      parsedOptions.unshift({
        value: 'create_new',
        label: 'Create New',
      });
      this.createNewButtonAdded = true;
    }

    return parsedOptions;
  };

  /**
   * Fired when user types into the dropdown input. Not on selection.
   * This must return the inputValue.
   * Otherwise override it will a specific function provided via props.
   * This still needs to return the inputValue
   */
  onInputChange = (inputValue) => {
    if (this.props.onInputChange) {
      return this.props.onInputChange(inputValue);
    }
    return inputValue;
  };

  render() {
    const {
      // react-select Specific
      autoload,
      disabled,
      options,
      labelKey,
      loadOptions,
      maxLength,
      minLength,
      multi,
      pagination,
      placeholder,
      searchable,
      clearable,
      value,
      valueKey,
      onInputChange,
      onFocus,
      onBlurResetsInput,
      isLoading,

      // showtime custom
      appendable,
      appendableButtonVisible,
      async,
      className,
      dark,
      messages,
      ...dropdownProps
    } = this.props;

    const DropDownComponent = this.getDropdownType();

    return (
      <DropDownComponent
        {...dropdownProps}
        name="type_dropdown"
        placeholder={placeholder}
        ref={(ref) => {
          this.selectElement = ref;
        }}
        className={classnames('dropdown-field', className, {
          'dropdown-field--dark': dark,
          'dropdown-field--multi': multi,
          'dropdown-field--disabled': disabled,
          'dropdown-field--create-new-visible':
            appendable && appendableButtonVisible,
        })}
        optionRenderer={this.renderDropdownOption}
        value={value}
        valueRenderer={this.renderDropdownValue}
        onChange={this.onSelectChange}
        onInputChange={this.onInputChange}
        onBlurResetsInput={onBlurResetsInput}
        onFocus={onFocus}
        autoload={autoload}
        isLoading={isLoading}
        searchable={searchable}
        clearable={clearable}
        multi={multi}
        pagination={pagination}
        disabled={disabled}
        noResultsText={messages.noResults}
        maxLength={5}
        inputProps={{
          maxLength,
          minLength,
        }}
        {...this.getOptionProps()}
        {...this.getAppendableProps()}
      />
    );
  }
}

Dropdown.propTypes = {
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  autoload: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  labelKey: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  multi: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      subtext: PropTypes.node,
      description: PropTypes.node,
      icon: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onBlurResetsInput: PropTypes.bool,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  pagination: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
    ),
  ]),
  valueKey: PropTypes.string,
  loading: PropTypes.bool,
  translate: PropTypes.bool,

  async: PropTypes.bool,
  uniqueKey: PropTypes.number,
  loadOptions: PropTypes.func,

  appendable: PropTypes.bool,
  appendableButtonVisible: PropTypes.bool,
  appendText: PropTypes.string,
  onAppend: PropTypes.func,
  isAppending: PropTypes.bool,
  promptTextCreator: PropTypes.func,
  messages: messagesType.isRequired,
  placeholder: PropTypes.node,
};

Dropdown.defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  autoload: true,
  disabled: false,
  isLoading: false,
  labelKey: 'label',
  loading: false,
  maxLength: 500,
  minLength: 0,
  multi: false,
  options: [],
  onBlurResetsInput: true,
  onInputChange: null,
  onFocus: _noop,
  pagination: false,
  searchable: true,
  clearable: false,
  translate: true,
  valueKey: 'value',
  placeholder: null,

  async: false,
  uniqueKey: null,
  loadOptions: _noop,

  appendable: false,
  appendableButtonVisible: false,
  appendText: '',
  onAppend: null,
  isAppending: false,
  promptTextCreator: (label) => label,
};

export default withMessages('dropdown', defaultMessages)(Dropdown);
