import React from 'react';
import PropTypes from 'prop-types';
import './_element-group.scss';

/**
 * This component renders a bordered `<ul>` list of items horizontally.
 * Expects children components to be `<ElementGroupItems`
 * Any elements can be added into each `<li>` 
 */
const ElementGroup = ({ children }) => (
  <ul className="element-group">
    {children}
  </ul>
);

ElementGroup.propTypes = {
  /**
   * Children should be `<ElementGroupItems>`
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ElementGroup;
