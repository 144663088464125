import React from 'react';
import PropTypes from 'prop-types';
import paths from './paths';
import KawaiiFace from '../face/KawaiiFace';
import KawaiiWrapper from '../wrapper/KawaiiWrapper';
import { KAWAII_MOODS } from '../constants';
import './../_kawaii.scss';

const KawaiiFilm = ({
  size,
  color,
  mood,
}) =>
  <KawaiiWrapper
    style={{ width: size, height: size * 0.75 }}
    width={size}
    height={size * 0.75}
    color={color}
  >
    <svg
      width={size}
      height={size * 0.75}
      viewBox="0 0 162 121"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="email-happy">
        <g id="stars" fill={color} fillRule="nonzero">
          <path d={paths.star1} />
          <path d={paths.star2} />
          <path d={paths.star3} />
          <path d={paths.star4} />
          <path d={paths.star5} />
        </g>
        <g transform="translate(35 57)">
          <g id="body" fill={color}>
            <path d={paths.body1} />
            <path d={paths.body2} />
          </g>
          <KawaiiFace
            mood={mood}
            color={color}
            transform="translate(21.5 16)"
          />
        </g>
      </g>
    </svg>
  </KawaiiWrapper>;

KawaiiFilm.propTypes = {
  size: PropTypes.number,
  mood: PropTypes.oneOf(KAWAII_MOODS),
  color: PropTypes.string,
};

KawaiiFilm.defaultProps = {
  size: 200,
  mood: 'happy',
  color: '#C3C9D2',
};

export default KawaiiFilm;
