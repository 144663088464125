import React from 'react';
import PropTypes from 'prop-types';
import paths from './paths';
import KawaiiFace from '../face/KawaiiFace';
import KawaiiWrapper from '../wrapper/KawaiiWrapper';
import { KAWAII_MOODS } from '../constants';
import './../_kawaii.scss';

const KawaiiMagnifier = ({
  size,
  color,
  mood,
}) =>
  <KawaiiWrapper
    style={{ width: size, height: size * 0.75 }}
    width={size}
    height={size * 0.75}
    color={color}
  >
    <svg
      width={size}
      height={size * 0.75}
      viewBox="0 0 162 121"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="kawaii-magnifier">
        <g id="stars" fill={color} fillRule="nonzero">
          <path
            id="Shape"
            d={paths.stars}
          />
        </g>
        <g id="magnifier" transform="translate(34 25)">
          <g fill={color} fillRule="nonzero">
            <path
              d={paths.body}
            />
          </g>
          <KawaiiFace
            mood={mood}
            color={color}
            transform="translate(12 27)"
          />
        </g>
      </g>
    </svg>
  </KawaiiWrapper>;

KawaiiMagnifier.propTypes = {
  size: PropTypes.number,
  mood: PropTypes.oneOf(KAWAII_MOODS),
  color: PropTypes.string,
};

KawaiiMagnifier.defaultProps = {
  size: 200,
  mood: 'happy',
  color: '#C3C9D2',
};

export default KawaiiMagnifier;
