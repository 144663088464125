import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragSource, DropTarget } from 'react-dnd';
import classnames from 'classnames';
import './_sortable.scss';

/**
 * Sortable Item Component
 */
const SortableItem = ({
  className,
  children,
  isDragging,
  connectDragSource,
  connectDropTarget,
}) => (
  connectDragSource(connectDropTarget(
    <li
      className={classnames('spr-sortable__item', className, {
        'spr-sortable__item--is-dragging': isDragging,
      })}
    >
      <div className="sortable__item__inner">
        {children}
      </div>
    </li>,
  ))
);

SortableItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired, // unique id
  key: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  moveItem: PropTypes.func.isRequired,
  findItem: PropTypes.func.isRequired,
  onChangeListOrder: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

SortableItem.defaultProps = {
  children: null,
  className: '',
  key: null,
};

const itemSource = {
  beginDrag(props) {
    return {
      id: props.id,
      originalIndex: props.findItem(props.id).index,
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    const didDrop = monitor.didDrop();

    if (!didDrop) {
      props.moveItem(droppedId, originalIndex);
    }
    props.onChangeListOrder();
  },
};

const itemTarget = {
  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem();
    const { id: overId } = props;

    if (draggedId !== overId) {
      const { index: overIndex } = props.findItem(overId);
      props.moveItem(draggedId, overIndex);
    }
  },
};

export default compose(
  DropTarget(
    'sortableItem',
    itemTarget,
    connect => ({
      connectDropTarget: connect.dropTarget(),
    }),
  ),
  DragSource(
    'sortableItem',
    itemSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  ),
)(SortableItem);
