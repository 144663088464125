import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _bindAll from 'lodash/bindAll';
import _noop from 'lodash/noop';
import _includes from 'lodash/includes';
import Button from '../Button';
import './_list-group-field.scss';

/**
 * Renders a styled list of Button components.
*/
class ListGroup extends Component {
  constructor(props) {
    super(props);

    _bindAll(this, [
      'handleSelection',
      'isSelected',
    ]);
  }

  handleSelection(value) {
    const {
      multiple,
      onChange,
      value: selectedValue,
    } = this.props;
    if (multiple) {
      const index = selectedValue.indexOf(value);
      if (index === -1) {
        onChange([...selectedValue, value]);
      } else {
        const selections = [...selectedValue];
        selections.splice(index, 1);
        onChange(selections);
      }
    } else {
      onChange(value);
    }
  }

  isSelected(value) {
    const {
      multiple,
      value: selectedValue,
    } = this.props;

    if (multiple) {
      return _includes(selectedValue, value);
    }

    return selectedValue === value;
  }

  render() {
    const {
      options,
      size,
      className,
    } = this.props;

    return (
      <ul className={`list-group-field ${className}`}>
        {options.map((option, index) => {
          const icon = (option.icon) ? `icon-${option.icon}` : null;
          const keys = Object.keys(option);

          return (
            <li
              key={index}
              className="list-group-field__item"
            >
              <Button
                type="link"
                onClick={() => {
                  this.handleSelection(option.value);
                }}
                selected={this.isSelected(option.value)}
                icon={icon}
                size={size}
                disabled={!!option.disabled}
              >
                {_includes(keys, 'text') && option.text }
                {(!_includes(keys, 'text') && !_includes(keys, 'icon')) && option.value}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  }
}

ListGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ])),
    ]),
  })).isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

ListGroup.defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  multiple: false,
};

export default ListGroup;
