import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {
  className: '',
};

const componentClass = 'card__header';

const CardHeader = ({ children, className, ...props }) =>
  <div {...props} className={classnames(componentClass, className)}>
    { children }
  </div>

const CardTitle = ({ children, className, ...props }) =>
  <div {...props} className={classnames(`${componentClass}__title`, className)}>
    { children }
  </div>

const CardAside = ({ children, className, ...props }) =>
  <div {...props} className={classnames(`${componentClass}__aside`, className)}>
    { children }
  </div>

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

CardTitle.propTypes = propTypes;
CardTitle.defaultProps = defaultProps;

CardTitle.propTypes = propTypes;
CardTitle.defaultProps = defaultProps;

export {
  CardHeader,
  CardTitle,
  CardAside,
};
