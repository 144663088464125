import { request as axiosRequest, CancelToken } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import { buildQuery } from '../../utility';

let index = 0;
const getUid = () => `sprocket-upload-${+(new Date())}-${++index}}`;


const request = ({ file, filename, onSuccess, onProgress, action, headers = {}, data = {}, method = 'post' }) => {
  let formData;

  if (file || data.useFormEncoding) {
    formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== 'useFormEncoding') {
          formData.append(key, data[key]);
        }
      });
    }
    if (file) {
      formData.append('file', file, encodeURIComponent(filename)); // TODO allow field name to be configurable
    }
  } else {
    formData = data;
  }

  let requestUrl = action;
  if (method.toLowerCase() === 'get' && !_isEmpty(data)) {
    requestUrl = buildQuery(action, data);
  }

  const { token, cancel } = CancelToken.source();

  return {
    request: axiosRequest({
      url: requestUrl,
      method,
      headers,
      data: formData,
      onUploadProgress: (progressEvent) => {
        if (typeof onProgress === 'function') {
          onProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      },
      // maxContentLength: 9999, TODO
      cancelToken: token,
    }),

    cancel,
  };
};


export {
  getUid,
  request,
};
