import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './SortToggle.scss';

const SortToggle = ({ value, label, className, onChange, togglePosition }) => (
  <span
    role="presentation"
    className={classnames(`sort-toggle ${className}`, {
      [`sort-toggle--toggle-pos--${togglePosition}`]: togglePosition,
    })}
    onClick={() => { onChange(value === 'asc' ? 'desc' : 'asc'); }}
  >
    <span>{label}</span>
    <span className={classnames('sort-toggle__icon', { [`sort-toggle__icon--${value}`]: value })} />
  </span>
);

SortToggle.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['asc', 'desc']),
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  togglePosition: PropTypes.oneOf(['left', 'right']),
};

SortToggle.defaultProps = {
  value: null,
  className: '',
  togglePosition: 'right',
};

export default SortToggle;
