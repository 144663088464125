import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import Dropdown from '../Dropdown';

let instanceCount = 0;

/**
 * Used to add a css rule to an existing stylesheet.
 * Note: addRule() is not supported in Firefox.
 * Therefore first check for existance of .insertRule() and add this way.
 * otherwise will use fallback to .addRule()
 */
const addCSSRule = (sheet, selector, rules, index = 0) => {
  if ('insertRule' in sheet) {
    sheet.insertRule(`${selector}{${rules}}`, index);
  } else if ('addRule' in sheet) {
    sheet.addRule(selector, rules, index);
  }
};

const propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  fonts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {
  value: '',
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
};

/**
 * @deprecated -> DO NOT USE! No longer used by any of our products. Needs revisited if similiar component is needed in future.
 *
 * This component comprises of a dropdown with a list of font families to choose from.
 *
 * To style each item with the correct font `.insertRule` and `.addRule` are used to add to an existing stylesheet.
 */
class FontDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.selector = `font-styles-${instanceCount}`;
    instanceCount += 1;
  }

  componentDidMount() {
    this.injectFontStyles();
  }

  componentWillUnmount() {
    document.head.removeChild(this.stylesheet);
  }

  injectFontStyles = (currentFont = this.props.value) => {
    const selector = this.selector;
    const existingElement = document.head.querySelector(`#${selector}`);
    if (existingElement) {
      existingElement.remove();
    }

    this.stylesheet = document.createElement('style');
    this.stylesheet.setAttribute('id', selector);
    document.head.prepend(this.stylesheet);

    this.props.fonts.forEach((font, index) => {
      addCSSRule(this.stylesheet.sheet, `.${selector} .Select-option:nth-child(${index + 1})`, `font-family:${font}`);
    });

    if (currentFont) {
      addCSSRule(this.stylesheet.sheet, `.${selector} .Select-value-label`, `font-family:${currentFont}`);
    }
  }

  handleSelectFont = (font) => {
    // Just pass the selected font name to the onChange callback
    this.props.onChange(font);

    // Replace the dynamic stylesheet to change the font-family of the select label
    this.injectFontStyles(font);
  }

  render() {
    const {
      fonts,
      ...dropdownProps
    } = this.props;

    return (
      <Dropdown
        {...dropdownProps}
        options={fonts.map(font => ({ label: font, value: font }))}
        className={this.selector}
        translate={false}
        onChange={this.handleSelectFont}
      />
    );
  }
}

FontDropdown.propTypes = propTypes;
FontDropdown.defaultProps = defaultProps;

export default FontDropdown;
