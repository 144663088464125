import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import Modal, { MODAL_SIZES } from '../Modal';
import Button, { ButtonPropTypes, ButtonPropDefaults } from '../Button';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  cancel: 'No',
  confirm: 'Yes',
};

/**
 * This component comprises of a button that opens a modal onClick.
 * Its purpose is to offer an additional check to the user, asking user to confirm or cancel their choice.
 */
const ConfirmButton = ({
  autoClose,
  bypass,
  onOpen,
  onClose,
  onConfirm,
  buttonIcon,
  buttonText,
  type,
  size,
  dark,
  className,
  modalTitle,
  modalSize,
  disabled,
  children,
  messages,
  wrapperClassName,
  hasHeader,
  hasCloseAction,
  disableBodyPadding,
}) => {
  const [isModalOpen, setIsModelOpen] = useState(false);

  const handleClick = useCallback(() => {
    const shouldBypass = typeof bypass === 'function' ? bypass() : bypass;
    if (shouldBypass) {
      onConfirm();
      return;
    }
    onOpen();
    setIsModelOpen(true);
  }, [onConfirm, onOpen, bypass, setIsModelOpen]);

  const closeModal = useCallback(() => {
    onClose();
    setIsModelOpen(false);
  }, [onClose, setIsModelOpen]);

  const handleConfirm = useCallback(
    (e) => {
      if (autoClose) {
        closeModal();
      }
      onConfirm(e);
    },
    [autoClose, closeModal, onConfirm],
  );

  return (
    <div className={classnames('button__confirm-wrapper', wrapperClassName)}>
      <Button
        className={className}
        onClick={handleClick}
        icon={buttonIcon}
        type={type}
        size={size}
        dark={dark}
        disabled={disabled}
      >
        {!!buttonText && buttonText}
      </Button>

      <Modal
        title={modalTitle}
        size={modalSize}
        open={isModalOpen}
        onRequestClose={closeModal}
        hasHeader={hasHeader}
        hasCloseAction={hasCloseAction}
        disableBodyPadding={disableBodyPadding}
        actions={[
          <Button key="btn.cancel" onClick={closeModal} type="link" size="md">
            {messages.cancel}
          </Button>,
          <Button
            key="btn.confirm"
            type="primary"
            size="md"
            onClick={handleConfirm}
          >
            {messages.confirm}
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </div>
  );
};

ConfirmButton.propTypes = {
  ...ButtonPropTypes,
  autoClose: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.node,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  size: PropTypes.oneOf(MODAL_SIZES),
  disabled: PropTypes.bool,
  bypass: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onConfirm: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  messages: messagesType.isRequired,
  hasHeader: PropTypes.bool,
  hasCloseAction: PropTypes.bool,
  disableBodyPadding: PropTypes.bool,
};

ConfirmButton.defaultProps = {
  ...ButtonPropDefaults,
  autoClose: true,
  buttonIcon: null,
  buttonText: null,
  wrapperClassName: null,
  modalTitle: '',
  modalSize: 'sm',
  disabled: false,
  bypass: false,
  children: [],
  onOpen: _noop,
  onClose: _noop,
  hasHeader: true,
  hasCloseAction: true,
  disableBodyPadding: false,
};

export default withMessages('confirmButton', defaultMessages)(ConfirmButton);
