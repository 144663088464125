import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import './_checkbox-field.scss';

const Checkbox = ({ label, value, dark, className, onChange, disabled, indeterminate, content, field, hasError }) => (
  <div
    className={classnames('checkbox-field', {
      [`checkbox-field--${className}`]: className,
      'checkbox-field--dark': dark,
      'checkbox-field--checked': value,
      'checkbox-field--disabled': disabled,
      'checkbox-field--indeterminate': !value && indeterminate,
      'checkbox-field--error': hasError,
    })}
  >
    <label className="checkbox-field__label">
      <input
        type="checkbox"
        value={value}
        checked={value}
        onChange={(event) => onChange(!value, event)}
        indeterminate={(!value && indeterminate) ? '' : undefined}
        disabled={disabled}
        {...field}
      />
      {label}
    </label>
    {content && <div className="checkbox-field__content">{content}</div>}
  </div>
);

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dark: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  // field is Injected in by Formik, it is optional and allows the Input to be used in Formik Components
  field: PropTypes.shape({}),
  indeterminate: PropTypes.bool,
  content: PropTypes.node,
};

Checkbox.defaultProps = {
  value: false,
  name: '',
  onChange: _noop,
  dark: false,
  className: '',
  required: false,
  label: null,
  disabled: false,
  hasError: false,
  field: {},
  indeterminate: false,
  content: null,
};

export default Checkbox;
