const paths = {
  body: 'M3.629 112.469c-2.428 0-3.638-1.168-3.629-3.504V4.395C.045 2.083 1.255.926 3.629.926h15.514c.015 9.487 4.8 14.231 14.357 14.231 9.556 0 14.342-4.744 14.357-14.23H63.65c2.233 0 3.35 1.196 3.35 3.59v104.455c0 2.331-1.117 3.497-3.35 3.497h-15.8c-.03-9.486-4.814-14.213-14.35-14.18-9.536.031-14.322 4.758-14.357 14.18H3.629zm3.2-3.56h8.92c2.263-9.448 8.18-14.171 17.751-14.171 9.571 0 15.556 4.723 17.954 14.17h8.966c1.994 0 2.99-1.09 2.99-3.273V7.848c0-2.241-.996-3.362-2.99-3.362h-8.966C49.056 14.05 43.07 18.831 33.5 18.831c-9.571 0-15.488-4.781-17.75-14.345H6.83c-2.12 0-3.2 1.083-3.24 3.248v97.894c-.009 2.187 1.072 3.28 3.24 3.28z',
  separator1: 'M10.757 3H1.243C.9 3 .589 2.832.364 2.56A1.664 1.664 0 0 1 0 1.5C0 .672.556 0 1.243 0h9.514C11.444 0 12 .672 12 1.5S11.444 3 10.757 3z',
  separator2: 'M24.757 3h-9.514c-.343 0-.654-.168-.879-.44A1.664 1.664 0 0 1 14 1.5c0-.828.556-1.5 1.243-1.5h9.514C25.444 0 26 .672 26 1.5S25.444 3 24.757 3z',
  separator3: 'M38.757 3h-9.514c-.343 0-.654-.168-.879-.44A1.664 1.664 0 0 1 28 1.5c0-.828.556-1.5 1.243-1.5h9.514C39.444 0 40 .672 40 1.5S39.444 3 38.757 3z',
  separator4: 'M51.757 3h-9.514c-.343 0-.654-.168-.879-.44A1.664 1.664 0 0 1 41 1.5c0-.828.556-1.5 1.243-1.5h9.514C52.444 0 53 .672 53 1.5S52.444 3 51.757 3z',
  separator5: 'M65.757 3h-9.514c-.343 0-.654-.168-.879-.44A1.664 1.664 0 0 1 55 1.5c0-.828.556-1.5 1.243-1.5h9.514C66.444 0 67 .672 67 1.5S66.444 3 65.757 3z',
  star1: 'M144.5 68.434c-1.397 0-2.5 1.094-2.5 2.48s1.103 2.48 2.5 2.48 2.5-1.094 2.5-2.48-1.103-2.48-2.5-2.48zm0 3.5c-.588 0-1.03-.437-1.03-1.02s.442-1.021 1.03-1.021c.588 0 1.03.438 1.03 1.02 0 .584-.442 1.022-1.03 1.022z',
  star2: 'M131.5 18.844c-1.397 0-2.5 1.094-2.5 2.48 0 1.385 1.103 2.48 2.5 2.48s2.5-1.095 2.5-2.48c0-1.386-1.103-2.48-2.5-2.48zm0 3.5c-.588 0-1.03-.437-1.03-1.02 0-.584.442-1.021 1.03-1.021.588 0 1.03.437 1.03 1.02 0 .584-.442 1.022-1.03 1.022z',
  star3: 'M81.5 0C80.103 0 79 1.094 79 2.48c0 1.385 1.103 2.479 2.5 2.479S84 3.865 84 2.479C84 1.095 82.897 0 81.5 0zm0 3.5c-.588 0-1.03-.437-1.03-1.02 0-.584.442-1.021 1.03-1.021.588 0 1.03.437 1.03 1.02 0 .584-.442 1.021-1.03 1.021z',
  star4: 'M18.5 25.787c-1.397 0-2.5 1.094-2.5 2.48 0 1.385 1.103 2.479 2.5 2.479s2.5-1.094 2.5-2.48c0-1.385-1.103-2.48-2.5-2.48zm0 3.5c-.588 0-1.03-.437-1.03-1.02 0-.584.442-1.022 1.03-1.022.588 0 1.03.438 1.03 1.021 0 .584-.442 1.021-1.03 1.021z',
  star5: 'M36.5 51.574c-1.397 0-2.5 1.094-2.5 2.48 0 1.385 1.103 2.479 2.5 2.479s2.5-1.094 2.5-2.48c0-1.385-1.103-2.48-2.5-2.48zm0 3.5c-.588 0-1.03-.437-1.03-1.02 0-.584.442-1.022 1.03-1.022.588 0 1.03.438 1.03 1.021 0 .584-.442 1.021-1.03 1.021z',
  star6: 'M161.858 36.327a1.035 1.035 0 0 0-.773-.415l-4.294.332-2.749-3.235c-.171-.248-.515-.331-.858-.248-.344.083-.602.331-.602.58l-.944 4.064-4.123 1.659c-.343.083-.515.415-.515.663 0 .332.172.581.43.747l3.692 2.156.258 4.147c0 .332.258.58.515.747.086.083.258.083.344.083.172 0 .43-.083.601-.166l3.264-2.737 4.208 1.078c.343.083.687 0 .859-.249.171-.249.257-.58.171-.83l-1.717-3.814 2.319-3.484c.085-.497.085-.83-.086-1.078zm-4.123 4.644l1.203 2.655-2.92-.664c-.258-.083-.601 0-.773.166l-2.32 1.907-.17-2.902c0-.25-.173-.498-.43-.664l-2.577-1.493 2.834-1.078c.258-.083.43-.332.516-.58l.687-2.82 1.89 2.239c.17.249.429.332.772.332l3.006-.25-1.632 2.489c-.172.166-.172.415-.086.663zM12.597 62.534L15 58.839c0-.284 0-.568-.146-.782a.875.875 0 0 0-.655-.355l-4.442.427-2.84-3.34c-.145-.213-.436-.284-.728-.213-.29.07-.51.284-.51.568L4.66 59.408.437 60.9c-.291.07-.437.355-.437.64 0 .283.146.497.364.639l3.86 2.202.29 4.335c0 .284.219.497.437.64.073.07.219.07.292.07.145 0 .364-.07.51-.142l3.349-2.913 4.369 1.066c.291.07.582 0 .728-.213.146-.214.218-.498.146-.711l-1.748-3.98zm-3.64 2.416a.711.711 0 0 0-.656.142l-2.549 2.203-.218-3.269c0-.213-.146-.426-.364-.568l-2.913-1.706 3.131-1.208c.219-.07.364-.284.437-.497l.728-3.197 2.185 2.558c.145.213.364.284.655.284l3.35-.284-1.748 2.629c-.145.213-.145.426-.073.639l1.311 3.055-3.277-.781z',
};

export default paths;
