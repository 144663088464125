import React, { Component } from 'react';
import isRequiredIf from 'react-proptype-conditional-require';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _has from 'lodash/has';
import _noop from 'lodash/noop';
import './_uploader.scss';
import DragDropProvider from './DragDropProvider';
import FileProvider from './FileProvider';
import { request, getUid } from './utils';
import Spinner from '../Spinner';
import { withMessages, messagesType } from '../../utility';


// TODO move to constants?
export const UPLOAD_PROVIDER_FILE = 'file';
export const UPLOAD_PROVIDER_URL = 'url';

const definedProviders = PropTypes.oneOf([
  UPLOAD_PROVIDER_FILE,
  UPLOAD_PROVIDER_URL,
]);


// TODO messages...

const getProviderComponent = (name) => {
  let component;
  switch (name) {
    case UPLOAD_PROVIDER_FILE:
      // TODO
      component = FileProvider;
      break;

    case UPLOAD_PROVIDER_URL:
      // TODO
      // component = UrlProvider
      break;

    default:
      throw new Error(`Unknown Upload provider: ${name}`);
  }

  return component;
};

const getProviders = (providers, uid) => (
  providers.map((provider, index) => {
    let Provider = provider;
    let props;
    let name;

    if (Array.isArray(provider)) {
      [Provider, props, name] = provider;
    }

    if (typeof Provider === 'string') {
      name = Provider;
      Provider = getProviderComponent(Provider);
      if (!Provider) {
        return null;
      }
    }

    if (!name) {
      name = `provider-${index}`;
    }

    return {
      Provider,
      key: `${uid}-${name}`,
      props: props || {},
    };
  }).filter(val => !!val)
);


class Uploader extends Component {
  static propTypes = {
    accept: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.func,
    dragging: PropTypes.bool,
    transformUploadData: PropTypes.func,
    className: PropTypes.string,
    headers: PropTypes.object,
    multiple: PropTypes.bool,
    onStart: PropTypes.func,
    onSelectFile: isRequiredIf(PropTypes.func, ({ uploadAction }) => !uploadAction),
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onProgress: PropTypes.func,
    providers: PropTypes.arrayOf(PropTypes.oneOfType([
      definedProviders,
      PropTypes.node,
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.oneOfType([
        definedProviders, // first item in array can be the name of a provider...
        PropTypes.node, // ...or a custom provider component
        PropTypes.func, // ...which can be a HOC function
        PropTypes.shape({}), // second item may contain props for a provider component
      ]))
    ])),
    showSpinner: PropTypes.bool,
    uploadAction: PropTypes.oneOfType([
      PropTypes.string, // can provide an endpoint URL
      PropTypes.func, // or a custom function that handle the uploading and return a file object
    ]),
    messages: messagesType.isRequired,
  };

  static defaultProps = {
    accept: [],
    children: undefined,
    dragging: true,
    transformUploadData: undefined,
    className: '',
    headers: {},
    multiple: false,
    onStart: _noop,
    onError: _noop,
    onProgress: _noop,
    onSelectFile: _noop,
    providers: [UPLOAD_PROVIDER_FILE],
    showSpinner: true,
    uploadAction: undefined,
  };


  state = {
    uid: getUid(),
    loading: false,
  };

  /**
   * Object of active upload requests, indexed by unique request IDs
   * @type {{}}
   */
  requests = {};

  onFileSelect = (data) => {
    const {
      transformUploadData,
    } = this.props;
    const uid = getUid();
    const fileData = { ...data, uid };

    if (typeof transformUploadData !== 'function') {
      return this.upload(fileData);
    }

    this.setState({ loading: true });
    Promise.resolve(transformUploadData(fileData))
      .then((transformedData) => {
        if (transformedData !== false) {
          this.upload(transformedData);
        } else {
          delete this.requests[uid];
          this.setState({ loading: false });
        }
      })
    ;
  };

  upload = (fileData) => {
    const {
      onError,
      onStart,
      onSelectFile,
      onSuccess,
      onProgress,
      uploadAction,
      headers,
    } = this.props;

    // Allow using the component as a way of retrieving file information without uploading
    onSelectFile(fileData);
    if (!uploadAction) {
      return;
    }

    this.setState({ loading: true });

    onStart(fileData);
    const { file, filename, uid, method, ...data } = fileData;

    const requestData = {
      file,
      filename,
      method,
      onProgress,
      onError: (e) => {
        delete this.requests[uid];
        onError(e);
      },
      data,
      headers,
    };

    let uploadRequest = request;

    const isCustomRequest = typeof uploadAction === 'function';
    if (isCustomRequest) {
      uploadRequest = uploadAction;
    } else {
      requestData.action = uploadAction;
    }

    const response = uploadRequest(requestData);
    this.requests[uid] = response;

    const callback = (response) => {
      const responseData = isCustomRequest ? response : response.data;
      Promise.resolve(onSuccess(responseData)).then(() => {
        this.setState({ loading: false });
        delete this.requests[uid];
      })
    };

    if (typeof response.then === 'function') {
      response.then(callback);
    } else if (typeof response.request.then === 'function') {
      response.request.then(callback);
    }
  };

  cancel = (uid) => {
    const cancelRequest = (uid) => {
      if (!_has(this.requests, uid)) {
        return;
      }

      const request = this.requests[uid];
      if (typeof request.cancel === 'function') {
        request.cancel();
      }

      delete this.requests[uid];
    };

    // Cancel a single request if given a UID, otherwise cancel all active requests
    if (uid) {
      cancelRequest(uid);
    } else {
      Object.keys(this.requests).forEach(cancelRequest);
    }
  };

  render() {
    const {
      accept,
      className,
      dragging,
      providers,
      showSpinner,
      children,
      messages,
      multiple,
    } = this.props;

    const showLoadingState = showSpinner && this.state.loading;

    const commonProviderProps = {
      accept,
      messages,
      multiple,
      onSelect: this.onFileSelect,
    };

    const parsedProviders = getProviders(providers, this.state.uid);
    let renderedProviders = parsedProviders.map(({ Provider, props, key }) => (
      <Provider
        key={key}
        {...commonProviderProps}
        {...props}
      />
    ));
    if (typeof children === 'function') {
      renderedProviders = children(renderedProviders);
    } else if (!renderedProviders.length) {
      renderedProviders = null;
    }

    return (
      <div className={classnames('spr-uploader', className)}>
        <Spinner overlay show={showLoadingState} />

        {dragging && (
          <DragDropProvider {...commonProviderProps}>
            {renderedProviders}
          </DragDropProvider>
        )}

        {!dragging && renderedProviders}
      </div>
    );
  }
}

export const propTypes = Uploader.propTypes;
export const defaultProps = Uploader.defaultProps;

export default withMessages('uploader')(Uploader);
