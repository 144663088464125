import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { result } from '@showtime/utility';

import Disabled from '../Disabled';
import './_radio-button.scss';

/**
 * Custom RadioOption Component.
 *
 * Note: The radio <input> is still used for semantics and Accessibility
 * however it is hidden and a 'fake' input is shown in order to acheive the custom styling.
 *
 * @param checked     - Boolean to determine whether this option is selected or not
 * @param name        - Unique Radio button group name, used to group radio options together.
 * @param onChange    - Callback function fired when a radio input has changed.
 * @param label       - Label to add beside each option.
 * @param value       - Value of each option
 * @param description - Description for each option to show below label.
 * @param content     - Can pass child content to be shown when radio option is selected
 * @param disabled    - Boolean to determine if radio should be disabled or not
 */
const RadioOption = (props) => {
  const {
    name,
    label,
    value,
    description,
    content,
    onChange,
    checked,
    disabled,
    disabledReason,
    className,
  } = props;
  const radioId = `radio-field-item-${name}-${value}`;
  const labelClass = description ? 'label-bold' : '';
  const isChecked = checked && !disabled;

  return (
    <Disabled disabled={disabled} reason={disabledReason} id={`${radioId}-tooltip`}>
      {getTooltipProps => (
        <Fragment>
          <label
            name={name}
            htmlFor={radioId}
            className={classnames(className, 'radio-option', {
              'radio-option--checked': isChecked,
              'radio-option--disabled': disabled,
            })}
            {...getTooltipProps(value)}
          >
            <div className="radio-option__input">
              <input
                className="radio-option__input--real"
                type="radio"
                value={value}
                name={name}
                onChange={onChange}
                id={radioId}
                checked={isChecked}
                disabled={disabled}
              />
              <span className="radio-option__input--fake" />
            </div>
            <div className="radio-option__label">
              <h4 className={classnames('radio-option__label__heading', labelClass)}>{label}</h4>
              {description && <p className="radio-option__label__description">{description}</p>}
            </div>
          </label>
          {content && isChecked && <div className="radio-option__content">{result(content)}</div>}
        </Fragment>
      )}
    </Disabled>
  );
};

export const RadioOptionPropTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
};

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  ...RadioOptionPropTypes,
};

RadioOption.defaultProps = {
  name: null,
  onChange: null,
  checked: false,
  disabled: false,
  content: null,
  description: null,
  value: '',
  className: null,
};

export default RadioOption;
