import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _kebabCase from 'lodash/kebabCase';
import Button, { ButtonPropTypes, ButtonPropDefaults } from '../Button';
import ButtonGroup from '../ButtonGroup';
import './_popover.scss';
import {
  findAncestor,
} from '../../utility';

let instanceCount = 0;
const instance = () => {
  const out = instanceCount;
  instanceCount += 1;

  return out;
};

const overrideButtonTypes = (children, type = 'popover') => {
  const buttons = children.filter(button => !!button);
  return Children.map(buttons, button => (
    cloneElement(button, Object.assign({}, button.props, {
      type,
      key: `action-${instanceCount}-${Math.random() * 1000}`,
    }))
  ));
};

const optionsType = PropTypes.arrayOf(PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}));

/**
 * @deprecated -> DO NOT USE!
 *
 * See Popoverlay and ElementPopover -> these may fit your requirements better
 *
 * Purpose of component is to show a number of options in a dropdown list and promote one of those into a clickable button that sits beside it.
 *
 */
class Popover extends React.Component {

  state = {
    isOpen: false,
  };

  // Unique classname to identify whether clicked
  instanceClass = `popover--instance-${instance()}`;


  componentDidMount() {
    document.addEventListener('click', this.checkClose);
  }

  componentWillUnmount() {
    // Ensure that we remove event listeners when unmounting.
    document.removeEventListener('click', this.checkClose);
  }

  setOpen = (isOpen = true) => {
    this.setState({ isOpen });
  };

  checkClose = (e) => {
    const clickedWithinInstance = findAncestor(e.target, this.instanceClass);
    const clickedWithinModal = !!findAncestor(e.target, 'js-modal');
    if (this.state.isOpen && !(clickedWithinInstance || clickedWithinModal)) {
      this.close();
    }
  };

  /**
   * Convenience method for use via refs, to prevent having to call setOpen(false)
   */
  close = () => {
    this.setOpen(false);
  };

  renderOptionButton = ({ label, key, onClick, type = this.props.buttonType }) => (
    <Button
      key={key || `popover-option.${_kebabCase(label)}`}
      onClick={() => {
        Promise.resolve(onClick()).then(() => this.props.autoclose && this.close());
      }}
      type={type}
    >
      {label}
    </Button>
  );

  renderOptions = () => {
    const {
      children,
      options,
    } = this.props;

    if (!_isEmpty(children)) {
      return overrideButtonTypes(children);
    }

    return options.map(option => (
      this.renderOptionButton({ ...option, type: 'popover' })
    ));
  };

  renderButtonGroup = (numPromoted, popover) => {
    const {
      children,
      promotedButtonType,
    } = this.props;

    let promotedOptions;
    if (!_isEmpty(children)) {
      const promotedChildren = Children.toArray(children).slice(0, numPromoted);
      promotedOptions = overrideButtonTypes(promotedChildren, promotedButtonType);
    } else {
      promotedOptions = this.props.options.slice(0, numPromoted).map(option => (
        this.renderOptionButton({ ...option, type: promotedButtonType}))
      );
    }

    return (
      <ButtonGroup popover>
        {promotedOptions}
        {popover}
      </ButtonGroup>
    );
  };

  render() {
    const {
      dark,
      light,
      promote,
      text,
      className,
      autoclose,
      autocloseDelay,
      promotedButtonType,
      ...buttonProps
    } = this.props;

    const popover = (
      <ButtonGroup
        dark={dark}
        className={classnames('popover', className, this.instanceClass, {
          'popover--open': this.state.isOpen,
          'popover--light': light,
        })}
      >
        <Button
          {...buttonProps}
          type="link"
          onClick={this.setOpen}
          onBlur={() => {
            if (autoclose) {
              setTimeout(this.close, autocloseDelay);
            }
          }}
          className={classnames('popover__toggle', {
            'popover__toggle--has-text': !!text,
          })}
        >
          {text || (<span>&nbsp;</span>)}
        </Button>
        <div className="popover__menu">
          {this.renderOptions()}
        </div>
      </ButtonGroup>
    );

    return promote ? this.renderButtonGroup(promote, popover) : popover;
  }
}

Popover.propTypes = {
  ...ButtonPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dark: PropTypes.bool,
  light: PropTypes.bool,
  promote: PropTypes.number,
  promotedButtonType: PropTypes.string,
  text: PropTypes.string,
  autoclose: PropTypes.bool,
  autocloseDelay: PropTypes.number,
  options: isRequiredIf(optionsType, ({ children }) => !children),
};

Popover.defaultProps = {
  ...ButtonPropDefaults,
  className: null,
  dark: false,
  light: false,
  promote: 0,
  promotedButtonType: 'secondary',
  text: '',
  autoclose: true,
  autocloseDelay: 500,
};

export default Popover;
