const BACKSPACE_KEY = 8;
const TAB_KEY = 9;
const ENTER_KEY = 13;
const SHIFT_KEY = 16;
const ESC_KEY = 27;
const SPACE_KEY = 32;
const ARROW_LEFT_KEY = 37;
const ARROW_UP_KEY = 38;
const ARROW_RIGHT_KEY = 39;
const ARROW_DOWN_KEY = 40;
const ZERO_KEY = 48; // Start of numerical characters
const A_KEY = 65; // Start of alphabetical characters
const COMMA_KEY = 188;

export {
  BACKSPACE_KEY,
  TAB_KEY,
  ENTER_KEY,
  SHIFT_KEY,
  ESC_KEY,
  SPACE_KEY,
  ARROW_UP_KEY,
  ARROW_RIGHT_KEY,
  ARROW_DOWN_KEY,
  ARROW_LEFT_KEY,
  ZERO_KEY,
  A_KEY,
  COMMA_KEY,
};
