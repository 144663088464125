import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Accordion = ({ dark, children }) => (
  <div
    role="tablist"
    className={classnames('accordion', {
      'accordion--dark': dark,
    })}
  >
    {children}
  </div>
);

Accordion.propTypes = {
  dark: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

Accordion.defaultProps = {
  dark: false,
};

export default Accordion;
