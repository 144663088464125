import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './product-sidebar.scss';

const ProductSidebar = ({ children, className }) => (
  <aside
    className={classnames('product-sidebar', className)}
  >
    { children }
  </aside>
);

ProductSidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProductSidebar.defaultProps = {
  className: '',
};

export default ProductSidebar;
