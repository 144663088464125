import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_navbar.scss';

import showtimeLogo from '../../images/showtime-logo-small.svg';


/**
 * @deprecated -> DO NOT USE.
 *
 * This component should no longer be used as it is out of date now with our products requirements.
 *
 * In future a common new replacement component should be created.
 */
const Navbar = ({ defaultPagePath, logo, headerTitle, altText, renderNavItems, className, link: Link }) => (
  <div className={classnames('navigation-bar', className)}>
    {Link ? (
      <Link to={defaultPagePath} className="navigation-bar__brand navbar__inner_nav">
        <img src={logo} alt={altText} />
        {headerTitle}
      </Link>
    ) : null }

    {renderNavItems ? (
      <nav role="navigation" className="navigation-bar__nav">
        {renderNavItems && renderNavItems()}
      </nav>
    ) : null}
  </div>
);

Navbar.propTypes = {
  /**
   * Optional custom classname
   */
  className: PropTypes.string,
  /**
   * Pass Navigational link component for Logo e.g NavLink
   */
  link: PropTypes.elementType,
  defaultPagePath: PropTypes.string,
  logo: PropTypes.string,
  headerTitle: PropTypes.string,
  altText: PropTypes.string,
  renderNavItems: PropTypes.func,
};

Navbar.defaultProps = {
  className: '',
  link: null,
  defaultPagePath: '/',
  logo: showtimeLogo,
  headerTitle: '',
  altText: 'Showtime logo',
  renderNavItems: null,
};

export default Navbar;
