import React, { Fragment, useCallback, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import _isFunction from 'lodash/isFunction';
import { result } from '@showtime/utility';

import { useContainer } from '../Container';

const propTypes = {
  /**
   * TooltipId per ReactTooltip docs. Unique value is generated if not provided.
   */
  id: PropTypes.string,
  /**
   * Controls wether tooltip should be shown at all.
   */
  disabled: PropTypes.bool,
  /**
   * Content of explanation tooltip.
   * Can be a function of exact same signature ReactTooltip.getContent.
   */
  reason: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * ClassName of wrapper element. Takes efect only when chilren is not a function
   */
  wrapperClassName: PropTypes.string,
  /**
   * Type of wrapper element. Takes efect only when chilren is not a function. Defaults to div.
   */
  wrapperElement: PropTypes.elementType,
  /**
   * When Node, will wrap it in an element bound to the tooltip.
   * When function, getTooltipProps function, that returns tooltip binding,
   * is given as parameter and you're expected to return a bound element yourself.
   *
   * getTooltipProps accepts a string data-tip parameter, compliant with ReactTooltip.getContent.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const defaultProps = {
  id: null,
  disabled: false,
  reason: null,
  children: null,
  wrapperElement: 'div',
  wrapperClassName: null,
};

/**
 * Wraps children in an element with tooltip, for cases where explanation should be provided when
 * children elements are considered disabled.
 *
 * Refer to ReactTooltip docs for accepted props.
 *
 * Note 1: setting getContent, disable and children props (as in the docs) will have no effect
 *
 * Note 2: The disabled element must not have `pointer-events: none`,
 * as it disables tooltip events too.
 */
const Disabled = ({
  disabled,
  reason,
  children,
  id,
  wrapperElement: Wrapper,
  wrapperClassName,
  ...tooltipProps
}) => {
  const [tooltipId, setTooltipId] = useState(null);
  const tooltipContent = useCallback((...args) => result(reason, ...args), [reason]);
  const { container, renderAtContainer } = useContainer();
  const isDisabled = !!reason && disabled;
  let tooltip = null;

  if (tooltipId) {
    tooltip = (
      <ReactTooltip
        key={tooltipId}
        id={tooltipId}
        effect="float"
        type="light"
        place="top"
        {...tooltipProps}
        getContent={tooltipContent}
      />
    );
  }

  const getTooltipProps = useCallback(
    (dataTip = true) => {
      const props = {};

      if (isDisabled && tooltipId) {
        props['data-tip'] = dataTip;
        props['data-for'] = tooltipId;
      }

      return props;
    },
    [isDisabled, tooltipId],
  );

  useEffect(() => {
    // TooltipId assigned after mount, to counteract weird event binding logic of react tooltip.
    // Particularily when rendered in modals, or other popup containers.
    setTooltipId(isDisabled ? id || _uniqueId('disabled_with_reason_') : null);
  }, [container, id, isDisabled]);

  const renderChildren = () => {
    if (_isFunction(children)) {
      return children(getTooltipProps);
    }

    return (
      <Wrapper className={wrapperClassName} {...getTooltipProps()}>
        {children}
      </Wrapper>
    );
  };

  return (
    <Fragment>
      {renderAtContainer(tooltip)}
      {renderChildren()}
    </Fragment>
  );
};

Disabled.propTypes = propTypes;
Disabled.defaultProps = defaultProps;

export default Disabled;
