import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_element-group.scss';

export const GROUP_ITEM_SIZES = ['xs', 'sm', 'md', 'lg', 'auto'];

const ElementGroupItem = ({ children, size }) => (
  <li
    className={classnames('element-group__item', {
      [`element-group__item--${size}`]: size,
    })}
  >
    {children}
  </li>
);

ElementGroupItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.oneOf(GROUP_ITEM_SIZES),
};

ElementGroupItem.defaultProps = {
  size: 'auto',
};

export default ElementGroupItem;
