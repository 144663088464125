import './_fields-map-dialog.scss';

import {
  fuzzySearch,
  isMatchingDateFormat,
} from '@showtime/utility';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _every from 'lodash/every';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import _mapValues from 'lodash/mapValues';
import _map from 'lodash/map';
import _memoize from 'lodash/memoize';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import Dropdown from '../../components/Dropdown';
import FormField from '../../components/FormField';
import Alert from '../../components/Alert';

import { withMessages, messagesType } from '../../utility';

const MAX_PREVIEW_VALUES = 3;

const DATE_TYPES = ['date_of_birth', 'opt_in_datetime', 'opt_out_datetime'];

const DATE_FORMAT_OPTIONS = [
  'YYYY/MM/DD',
  'YYYY-MM-DD',
  'YYYY/DD/MM',
  'YYYY-DD-MM',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'DD-MM-YYYY',
  'DD/MM/YYYY',
];

const DATE_TIME_FORMAT_OPTIONS = [
  ...DATE_FORMAT_OPTIONS,
  'YYYY/MM/DDTHH:mm:ssZ',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY/DD/MMTHH:mm:ssZ',
  'YYYY-DD-MMTHH:mm:ssZ',
  'MM/DD/YYYYTHH:mm:ssZ',
  'MM-DD-YYYYTHH:mm:ssZ',
  'DD/MM/YYYYTHH:mm:ssZ',
  'DD-MM-YYYYTHH:mm:ssZ',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY/DD/MM HH:mm:ss',
  'YYYY-DD-MM HH:mm:ss',
  'MM/DD/YYYY HH:mm:ss',
  'MM-DD-YYYY HH:mm:ss',
  'DD/MM/YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm:ss',
];

const getDateOptions = options => options.map(option => (
  {
    value: option,
    label: option,
  }
));

const DATE_DROPDOWN_OPTIONS = getDateOptions(DATE_FORMAT_OPTIONS);
const DATE_TIME_DROPDOWN_OPTIONS = getDateOptions(DATE_TIME_FORMAT_OPTIONS);

/**
 * Given an array of strings,
 * ensure that each value is trimmed and not surrounded by quotes.
 * @param row
 * @return {*}
 */
const cleanValues = row => (
  row.map((value) => {
    const trimmed = value.trim();
    const [, inner] = trimmed.match(/^(?:"|')(.*)(?:"|')$/) || [];
    return inner || trimmed;
  })
);

const getFieldMap = _memoize((fields) => {
  const result = {};

  fields.forEach((field) => {
    // Ignore falsy values, and special '-1' which means 'Do not import'.
    if (field && field.value >= 0) {
      result[field.value] = field;
    }
  });

  return result;
});

/**
 *  Fields Map Dialog
 *
 *  Currently only showing DATETIME formats for opt_in_datetime/opt_out_datetime
 *  Currently only showing DATE formats for date_of_birth
 *
 *  Current FE validation:
    Cannot upload list if: No email column has been chosen.
    Cannot upload list if: The chosen email column includes invalid email addresses.

    If date_of_birth column was selected:
      - Blank Values are allowed but invalid dates/garbage text are not.

      Cannot upload if:
        1. A date format for this hasn’t been selected (date_of_birth_format = null)
        2. The chosen date of birth column data contains invalid dates.
        3. The chosen date of birth column data doesn’t match the date_of_birth_format selected.

    If opt_out_datetime column was selected:
      - Blank Values are allowed but invalid dates/garbage text are not.

      Cannot upload if:
        1. A corresponding date format hasn’t been selected (opt_out_datetime_format = null)
        2. The chosen opt_out_datetime column data contains any invalid dates.
        3. The chosen opt_out_datetime column data doesn’t match the opt_out_datetime_format selected.

    If opt_in_datetime column was selected:
      - Blank Values, Invalid dates are allowed - BE will override with todays date in the chosen opt_in_datetime_format.
      - If a valid date has been detected - it must be in the format selected.

      Cannot upload if:
        1. A corresponding date format hasn’t been selected (opt_in_datetime_format = null)
 */
class FieldsMapDialog extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
    ).isRequired,
    keepMappingOnCancel: PropTypes.bool,
    autoMapFields: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.node,
      name: PropTypes.string.isRequired,
    })).isRequired,
    requiredFields: PropTypes.arrayOf(PropTypes.string), // array of field names
    defaultValues: PropTypes.objectOf(PropTypes.node),
    validate: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onMappedFieldsChange: PropTypes.func, // Notify selection of field mappings.
    messages: messagesType.isRequired,
    hideCheckbox: PropTypes.bool,
    noHeaders: PropTypes.bool,
    onOutsideClick: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
    onRequestPrev: PropTypes.func,
  };

  static defaultProps = {
    validate: _noop,
    requiredFields: ['email'],
    defaultValues: {},
    keepMappingOnCancel: false,
    autoMapFields: true,
    className: '',
    hideCheckbox: false,
    noHeaders: false,
    onOutsideClick: undefined,
    onRequestPrev: null, // purposely want this to be null if not provided.
    onMappedFieldsChange: _noop,
  };

  static defaultMessages = {
    cancel: 'Cancel',
    import: 'Import',
    dialogTitle: 'Select fields to map',
    defaultColumn: num => `Column ${num}`,
    toggleHeaders: 'Set the first row of the CSV as the column header',
    fieldHeading: 'Column to import',
    mapHeading: 'Map to field',
    noFieldsFound: 'No fields found...',
    requiredField: 'This field is required',
    requiredDateFormatField: 'Date format fields are required',
    formatDatePlaceholder: 'Select a date format',
    requiredMappingField: fieldLabel => `${fieldLabel} field is required`,
    invalidEmailError: 'You cannot map an invalid email address. Please review before importing.',
    invalidDateDetectedError: 'You cannot map an invalid date. Please review before importing.',
    invalidDateFormatDetectedError: 'The date format selected does not match the data. Please review before importing.',
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(false);
  }

  getInitialState = (keepMapping) => {
    const newState = {
      errors: [],
      isSaving: false,
      hasSelectedSubmit: false,
    };

    if (!keepMapping) {
      newState.defaultMappings = {};
      newState.customMappings = {};
      newState.selectedDateFields = [];
      newState.includesHeadings = false;
    }

    return newState;
  };

  componentDidMount() {
    if (this.props.data.length) {
      this.onInitData();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (!_isEqual(this.props.data, nextProps.data)) {
      this.onInitData(nextProps, nextState.includesHeadings);
      this.setState({ isSaving: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset isSaving when dialog closes
    if (!this.props.open && this.props.open !== prevProps.open) {
      this.setState({
        isSaving: false,
        errors: [],
      });
    }

    if (prevProps.fields !== this.props.fields) {
      getFieldMap.cache.delete(prevProps.fields);
    }

    if (
      this.state.customMappings !== prevState.customMappings ||
      this.state.defaultMappings !== prevState.defaultMappings
    ) {
      this.props.onMappedFieldsChange(this.getMappedFieldNames());
    }
  }

  componentWillUnmount() {
    getFieldMap.cache.delete(this.props.fields);
  }

  onInitData = (props = this.props, includesHeadings = this.state.includesHeadings) => {
    const nextState = {};
    const {
      data,
      autoMapFields,
    } = props;

    // First time parsing data, so default includesHeadings based on whether the first row
    // looks like headings (only alphabetical characters or spaces)
    if (typeof includesHeadings === 'undefined' && data.length) {
      nextState.includesHeadings = _every(data[0], value => (/^[a-z\s"'_-]+$/i.test(value)));
    }

    nextState.defaultMappings = this.getDefaultMappings(data, nextState.includesHeadings, autoMapFields);

    this.setState(nextState);
  };

  /**
   * Map the current column index to the selected field value.
   * When the custom mapping has been updated, update the dateFormatField mapping also.
   *
   * @param {number} index - index of data column
   * @param {number} selectedValue one of this.props.fields that this column be mapped to.
   */
  onMap = (index, selectedValue) => {
    this.setState(prevState => ({
      customMappings: {
        ...prevState.customMappings,
        [index]: selectedValue,
      },
    }),
    () => {
      this.updateSelectedDateFields(selectedValue);
    });
  };

  /**
   * Update the format of the selectedDateField
   * @param {string} selectedFormat e.g 'yyyy-mm-dd'
   * @param {number} fieldValue value given to for e.g 'date_of_birth'
   */
  onChangeDateFormatSelection = (selectedFormat, fieldValue) => {
    this.setState((prevState) => {
      const selectedDateFields = _cloneDeep(prevState.selectedDateFields);
      const dateIndex = _findIndex(selectedDateFields, i => i.dateFieldValue === fieldValue);
      selectedDateFields[dateIndex].format = selectedFormat;
      selectedDateFields[dateIndex].errors = [];

      return ({
        selectedDateFields,
      });
    });
  }

  onSave = () => {
    const mappedFields = this.getMappedFields();
    const mappedColumns = this.getMappedFieldNames(mappedFields)
    const mappedData = this.getMappedData(mappedFields);

    this.setState({
      isSaving: true,
      hasSelectedSubmit: true,
    });

    this.validateMappedFields(mappedData, mappedColumns).then(() => {
      const dateErrors = _flatten(this.state.selectedDateFields.map(item => item.errors));

      if (!this.state.errors.length && !dateErrors.length) {
        this.resetErrors();
        this.props.onSave(mappedData, this.setMappedDateFormats());
      } else {
        this.setState({
          isSaving: false,
        });
      }
    });
  };

  /**
   * This Button can be a cancel or a goBack to prev step button depending
   * on where it is being used.
   * If onRequestPrev has been specifed use this otherwise use onRequestClose.
   * Reset the fieldMapDialog only if the user selects back or cancel.
   * Don't reset if user is just proceeding to next step,
   * unless it's specifically allowed to keep mapped data,
   * in which case only control state is reset.
   */
  onCancelModal = () => {
    const { onRequestClose, onRequestPrev } = this.props;
    this.setState(this.getInitialState(this.props.keepMappingOnCancel));
    return _isFunction(onRequestPrev) ? onRequestPrev() : onRequestClose();
  }

  onCloseModal = () => {
    this.setState(this.getInitialState(this.props.keepMappingOnCancel));
    return this.props.onRequestClose();
  }

  getField(fieldValue) {
    const fields = getFieldMap(this.props.fields);
    return _get(fields, fieldValue);
  }

  getFieldName = (fieldValue) => {
    const field = this.getField(fieldValue);

    return _get(field, 'name');
  }

  getMappedFields() {
    const { defaultMappings, customMappings } = this.state;
    const mappings = { ...defaultMappings, ...customMappings };
    const fieldMap = getFieldMap(this.props.fields);

    return _mapValues(mappings, value => _get(fieldMap, value));
  }

  getMappedFieldNames(mappedFields) {
    return _map(mappedFields || this.getMappedFields(), 'name');
  }

  getMappedData(mappedFields) {
    const { defaultValues } = this.props;
    const [, rows] = this.parseData();
    const fields = mappedFields || this.getMappedFields();

    const mappedData = rows.map((row) => {
      const result = { ...defaultValues };

      row.forEach((cell, cellIndex) => {
        const fieldName = _get(fields, [cellIndex, 'name']);
        if (fieldName) {
          result[fieldName] = cell;
        }
      });

      return result;
    });

    return mappedData;
  }

  getDefaultMappings = (data, includesHeadings, autoMapFields) => {
    if (!autoMapFields) {
      return {};
    }

    const [headers, rows] = this.parseData(data, includesHeadings);
    if (!rows.length || !includesHeadings) {
      return {};
    }

    const defaults = {};
    headers.forEach((header, index) => {
      const hasValue = _filter(rows, row => (
        row[index] && !/^\s+$/.test(row[index])
      )).length;

      let value = null;
      const fieldTitle = header.trim();

      const bestMatch = fuzzySearch(this.props.fields, fieldTitle, {
        keys: ['label'],
        threshold: 0.5,
        location: 0,
        shouldSort: true,
      });
      if (hasValue && bestMatch.length) {
        value = bestMatch[0].value;
      }

      defaults[index] = value;
    });

    return defaults;
  };

  getRequiredFields = () => {
    const { fields, requiredFields } = this.props;

    return fields.filter(field => field.value >= 0 && requiredFields.includes(field.name));
  }

  /**
   * Takes current this.state.selectedDateFields and returns:
   * @return {object}
   * Example:
     {
       date_of_birth_format: 'yyyy-MM-dd',
       opt_in_datetime: 'yyyy-MM-dd'
     }
   */
  setMappedDateFormats = () => {
    const dateFormats = {};

    /**
     * Convert YYYY and DD to lowercase.
     * Convert T and Z to 'T' and 'Z'.
     * BE requirement of how these strings should be saved.
     * Initially setting as uppercase to display to user and to
     * make it easier to use with Moment
     */
    const formatFormats = str => (
      str.replace(/YYYY/g, 'yyyy').replace(/DD/g, 'dd').replace(/T/g, "'T'").replace(/Z/g, "'Z'")
    );

    this.state.selectedDateFields.forEach((item) => {
      dateFormats[`${item.dateFieldName}_format`] = formatFormats(item.format);
    });
    return dateFormats;
  }

  /**
   * Returns [] if there are no errors
   * Returns array of error messages if there are errors.
   * Date format field errors:
   * Required: Every date option field must have selected a corresponding date format field.
   * If the wrong format was chosen: Notify user.
   * If date option was mapped to a non-date field - Notify user.
   */
  validateMappedFields = (mappedData, mappedColumns) => {
    return new Promise((resolve) => {
      const modalErrors = [];
      const { messages } = this.props;

      // Check if Required fields were added;
      this.getRequiredFields().forEach((field) => {
        const { name, label } = field;

        if (!mappedColumns.includes(name)) {
          modalErrors.push(messages.requiredMappingField(label || name));
        }
      });

      // This functionality is being removed for now but may come back in the future, so shall leave for now.
      // } else {
      //   mappedData.forEach((customer) => {
      //     // if email field was added ensure all emails are valid.
      //     if (!isValidEmail(customer.email)) {
      //       if (!modalErrors.includes(messages.invalidEmailError)) {
      //         modalErrors.push(messages.invalidEmailError);
      //       }
      //     }
      //   });
      // }

      const selectedDateFields = _cloneDeep(this.state.selectedDateFields);
      selectedDateFields.forEach((dateField) => {
        // Alert error if date_of_birth_format, opt_in_datetime_format
        // or opt_out_datetime_format are empty
        if (_isNull(dateField.format)) {
          if (!dateField.errors.includes(messages.requiredField)) {
            dateField.errors.push(messages.requiredField);
          }

          if (!modalErrors.includes(messages.requiredDateFormatField)) {
            modalErrors.push(messages.requiredDateFormatField);
          }
        } else {
          /**
           *  A format has been selected.
           * Alert error if the the data being mapped contains an invalid date or
           * does not match the format selected.
           *
           * opt_in_datetime, opt_out_datetime and date_of_birth can include blank values,
           * If there is a value, it must be a valid date and in the same format as the format chosen.
           */
          const dateErrors = [];

          let hasInvalidFormatError;
          const allowedFormats = DATE_FORMAT_OPTIONS.concat(DATE_TIME_FORMAT_OPTIONS);

          if (dateField.dateFieldName === 'opt_in_datetime') {
            hasInvalidFormatError = mappedData.some((field) => {
              const value = field[dateField.dateFieldName];
              const isOneOfAllowedDateFormats = moment(value, allowedFormats, true).isValid();
              const hasValue = !!value;
              const isMatchingFormat = isMatchingDateFormat(field[dateField.dateFieldName], dateField.format);

              // Only show error if some fields have a value that is a valid date but doesn't match the format chosen.
              // Invalid dates and Blank Values won't return errors here - as they need to pass through to be overridden to today by BE
              return hasValue && isOneOfAllowedDateFormats && !isMatchingFormat;
            });
          } else {
            hasInvalidFormatError = mappedData.some((field) => {
              const value = field[dateField.dateFieldName];
              const hasValue = !!value;
              const isMatchingFormat = isMatchingDateFormat(field[dateField.dateFieldName], dateField.format);
              // date of birth and opt_out_datetime will return an error if the value is an invalid date and if it doesn't match the format selected.
              // Blank Values are allowed here.
              return hasValue && !isMatchingFormat;
            });
          }

          if (hasInvalidFormatError) {
            dateErrors.push(messages.invalidDateFormatDetectedError);
          }

          if (!_isEmpty(dateErrors)) {
            dateErrors.forEach((error) => {
              if (!dateField.errors.includes(error)) {
                dateField.errors.push(error);
              }

              if (!modalErrors.includes(error)) {
                modalErrors.push(error);
              }
            });
          }
        }
      });

      this.setState({
        selectedDateFields,
        errors: modalErrors,
      }, () => {
        resolve();
      });
    });
  };

  /**
   * When a row has been mapped update the selectedDateFields.
   * @param {number} selectedValue - selected this.props.fields value.
   */
  updateSelectedDateFields = (selectedValue) => {
    const { customMappings, selectedDateFields } = this.state;
    const selectedFieldName = this.getFieldName(selectedValue);
    const isDateOption = this.isDateOption(selectedFieldName);

    // Get array of field row indexes that have already been mapped.
    const currentCustomMappedFieldIds = Object.keys(customMappings).map(key => customMappings[key]);

    // Remove any selectedDateFields that are no longer mapped.
    const currentSelectedDateFields = selectedDateFields.filter(field => (
      currentCustomMappedFieldIds.includes(field.dateFieldValue)
    ));

    // If a new date field option was just selected add it.
    if (isDateOption) {
      currentSelectedDateFields.push({
        errors: [],
        format: null,
        dateFieldName: selectedFieldName,
        dateFieldValue: selectedValue,
      });
    }

    this.setState({
      selectedDateFields: currentSelectedDateFields,
    });
  }

  parseData = (data = this.props.data, includesHeadings = this.state.includesHeadings) => {
    const { noHeaders } = this.props;

    if (!data || !data.length) {
      return [[], []];
    }

    if (!noHeaders) {
      const parsedData = data.map(cleanValues);

      if (!includesHeadings) {
        const defaultHeadings = parsedData[0].map((val, i) => (
          this.props.messages.defaultColumn(i + 1)
        ));

        return [defaultHeadings, parsedData];
      }

      const [headings, ...rows] = parsedData;

      return [headings, rows];
    }

    return data;
  };

  resetErrors = () => {
    this.setState({ errors: [] });
  };

  isDateOption = selectedFieldName => DATE_TYPES.includes(selectedFieldName);

  renderContent = () => {
    const { messages, hideCheckbox } = this.props;

    return (
      <div className="text-left fields-map-dialog fields-map-dialog__content">
        {!!this.state.errors.length && (
          <Alert
            className="fields-map-dialog__alert"
            status="error"
            type="embedded"
          >
            {this.state.errors.map((error, index) => (
              <p key={index} className="modal__p--no-margin">{error}</p>
            ))}
          </Alert>
        )}
        {!hideCheckbox &&
          <div className="fields-map-dialog__checkbox">
            <Checkbox
              value={this.state.includesHeadings}
              label={messages.toggleHeaders}
              onChange={includesHeadings => this.setState({ includesHeadings })}
            />
          </div>
        }

        <div className="fields-map-dialog__head">
          <div className="flex-container between-xs">
            <div className="col-sm-7 bold">{messages.fieldHeading}</div>
            <div className="col-sm-5 bold">{messages.mapHeading}</div>
          </div>
        </div>
        <div className="fields-map-dialog__fields">
          {this.renderFields()}
        </div>
      </div>
    );
  };

  renderDateFormatField = (fieldRowValue) => {
    const { messages } = this.props;
    const fieldName = this.getFieldName(fieldRowValue);
    const isDateOption = this.isDateOption(fieldName);

    if (!isDateOption) {
      return null;
    }

    const selectedDateField = _find(
      this.state.selectedDateFields,
      item => item.dateFieldValue === fieldRowValue,
    );

    const selectedDateErrors = _get(selectedDateField, 'errors');

    const dateDropdownOptions = (fieldName.indexOf('time') !== -1)
      ? DATE_TIME_DROPDOWN_OPTIONS
      : DATE_DROPDOWN_OPTIONS;

    return (
      <FormField
        errorText={selectedDateErrors}
        hasError={!_isEmpty(selectedDateErrors)}
        spaceBottom={false}
      >
        <Dropdown
          options={dateDropdownOptions}
          value={_get(selectedDateField, 'format')}
          placeholder={messages.formatDatePlaceholder}
          onChange={selectedValue => (
            this.onChangeDateFormatSelection(selectedValue, fieldRowValue)
          )}
        />
      </FormField>
    );
  }

  renderFields = () => {
    const { noHeaders, messages } = this.props;
    const [headers, rows] = this.parseData();

    if (rows && !rows.length) {
      return (<div className="text-center cell-row--bordered">{messages.noFieldsFound}</div>);
    }

    const fieldMappings = { ...this.state.defaultMappings, ...this.state.customMappings };
    const mappedValues = Object.values(fieldMappings).filter(val => !!val);

    const columns = noHeaders ? this.parseData() : headers;

    return columns.map((title, i) => {
      const fieldRowValue = fieldMappings[i];

      const mappingOptions = this.props.fields.filter(option => (
        option.value === -1 ||
        option.value === fieldRowValue ||
        mappedValues.indexOf(option.value) === -1
      ));

      // Select a number of preview values to display to the user so they know
      // which column they are mapping.
      const previewValues = _filter(rows, field => (field[i] && !/^\s+$/.test(field[i])))
        .map(field => field[i].trim())
        .slice(0, MAX_PREVIEW_VALUES);

      if (!previewValues.length) {
        previewValues.push('-');
      }

      return (
        <div className="cell-row--bordered" key={i}>
          <div className="flex-container fields-map-dialog__list__item between-xs">
            <div className="col-sm-7">
              {!this.props.noHeaders &&
                <div className="bold fields-map-dialog__list--value">{title}</div>
              }
              {previewValues.map((previewValue, index) => (
                <div
                  key={index}
                  className="fields-map-dialog__list--value"
                >
                  {noHeaders ? title : previewValue}
                </div>
              ))}
            </div>
            <div className="col-sm-5">
              <Dropdown
                options={mappingOptions}
                value={fieldRowValue}
                onChange={selectedValue => this.onMap(i, selectedValue)}
              />
              {this.renderDateFormatField(fieldRowValue)}
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      messages,
      className,
      data,
      noHeaders,
    } = this.props;

    let disabled = false;

    if (data.length === 1 && this.state.includesHeadings && !noHeaders) {
      disabled = true;
    }

    return (
      <Modal
        className={className}
        {...this.props}
        size="lg"
        title={messages.dialogTitle}
        headerContent={this.props.headerContent}
        onOutsideClick={this.props.onOutsideClick}
        onRequestClose={this.onCloseModal}
        hasCloseAction
        actions={[
          <Button
            type="link"
            key="action-cancel"
            onClick={this.onCancelModal}
          >
            {messages.cancel}
          </Button>,
          <Button
            key="action-save"
            onClick={this.onSave}
            type="primary"
            disabled={this.state.isSaving || disabled}
          >
            {messages.import}
          </Button>,
        ]}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

export default withMessages('fieldsMapDialog')(FieldsMapDialog);
