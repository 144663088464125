import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CardContent from './CardContent';
import CardFooter from './CardFooter';
import {CardHeader, CardTitle, CardAside} from './CardHeaders';
import './card.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
};

const componentClass = 'card';

/**
 * Card component
 * @param {node} children
 * @param {string} className  - Able to add custom classnames
 * @param {object} props      - Dynamic props to add to spread across the main div.
 * */
const Card = ({ children, className, ...props }) => (
  <div {...props} className={classnames(componentClass, className)}>
    { children }
  </div>
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Aside = CardAside;
Card.Content = CardContent;
Card.Footer = CardFooter;

export default Card;
