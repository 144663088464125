const buildQuery = (url, params) => {
  if (!params) {
    return url;
  }

  const queryData = [];
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'boolean') {
      params[key] = +params[key];
    }
    if (typeof params[key] === 'string' || typeof params[key] === 'number') {
      queryData.push(key + '=' + params[key]);
    } else if (params[key] && params[key] != null) {
      if (params[key].length > 0) {
        params[key].forEach((data) => {
          queryData.push(key + '=' + data);
        });
      }
    }
  });

  const query = queryData.length > 0 ? `?${queryData.join('&')}` : '';

  return `${url}${query}`;
};

export default buildQuery;
