import React from 'react';
import PropTypes from 'prop-types';
import './_no-result.scss';
import {
  KawaiiEmail,
  KawaiiFilm,
  KawaiiMagnifier,
  KawaiiPopcorn,
  KawaiiTicket,
  KAWAII_MOODS,
  KAWAII_TYPES,
} from '../Kawaii';
import { withMessages, messagesType } from '../../utility';

const defaultMessages = {
  headerText: 'No results found',
  bodyText: 'Sorry, we couldn\'t find what you were looking for.',
};

const kawaiiSizes = {
  xxs: 60,
  xs: 100,
  sm: 120,
  md: 200,
  lg: 250,
};

const kawaiiComponents = {
  email: KawaiiEmail,
  film: KawaiiFilm,
  magnifier: KawaiiMagnifier,
  popcorn: KawaiiPopcorn,
  ticket: KawaiiTicket,
};

const NoResult = ({ kawaiiType, kawaiiMood, size, color, messages }) => {
  const KawaiiComponent = kawaiiComponents[kawaiiType];

  return (
    <div className={`no-result no-result--${size}`}>
      <div className="no-result__placeholder">
        {KawaiiComponent && (
          <KawaiiComponent
            mood={kawaiiMood}
            size={kawaiiSizes[size]}
            color={color}
          />
        )}
        <div className="no-result__header">{messages.headerText}</div>
        {messages.bodyText && (
          <div className="no-result__body">{messages.bodyText}</div>
        )}
      </div>
    </div>
  );
};

NoResult.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  kawaiiType: PropTypes.oneOf(KAWAII_TYPES),
  kawaiiMood: PropTypes.oneOf(KAWAII_MOODS),
  color: PropTypes.string,
  messages: messagesType.isRequired,
};

NoResult.defaultProps = {
  size: 'md',
  kawaiiType: 'magnifier',
  kawaiiMood: 'worried',
  color: undefined,
};

export default withMessages('noResult', defaultMessages)(NoResult);
