import React from 'react';
import PropTypes from 'prop-types';
import './_tabs.scss';

export const TabComponent = ({ children, className }) => (
  <div className={`sta-tabs__container__tab ${className}`}>
    {children}
  </div>
);

TabComponent.propTypes = {
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

TabComponent.defaultProps = {
  disabled: false,
  className: '',
  children: null,
};


export default TabComponent;
