import React from 'react';
import PropTypes from 'prop-types';
import paths from './paths';
import { KAWAII_MOODS } from '../constants';
import './../_kawaii.scss';

const KawaiiFace = ({
  mood,
  color,
  ...rootProps
}) => (
  <g id="kawaii-face" {...rootProps}>
    <defs>
      <path d={paths.defs} id="kawaii-tongue-mouth-path" />
    </defs>
    {(mood === 'lovestruck') &&
    <g id="kawaii-face__mouth">
      <g id="tongue-mouth" transform="translate(13.5 10)">
        <mask id="mask-2" fill="#fff">
          <use xlinkHref="#kawaii-tongue-mouth-path" />
        </mask>
        <use fill={color} xlinkHref="#kawaii-tongue-mouth-path" />
        <path
          id="tongue"
          fill="#F2F4F7"
          d={paths.tongue}
        />
      </g>
    </g>
    }
    {mood === 'happy' &&
      <path
        id="happy-mouth"
        fill={color}
        d={paths.happymouth}
      />
    }
    {mood === 'worried' &&
      <ellipse
        id="worried-mouth"
        cx="23.647"
        cy="18.791"
        fill={color}
        rx="6.04"
        ry="6.711"
      />
    }
    {mood === 'sad' &&
      <path
        id="kawaii-face__mouth__sad"
        fill={color}
        d={paths.sadmouth}
        transform="matrix(1 0 0 -1 0 32.81)"
      />
    }
    <g
      id="kawaii-face__blush"
      fill="#E0E4E8"
      transform="translate(0 9)"
    >
      <ellipse id="Oval" cx="2.061" cy="2.128" rx="2.061" ry="2.128" />
      <ellipse id="Oval" cx="43.286" cy="2.128" rx="2.061" ry="2.128" />
    </g>
    <g
      id="kawaii-face__eyes"
      transform="translate(2)"
      fill={color}
    >
      {(mood === 'happy' || mood === 'sad' || mood === 'worried') &&
        <g
          id="kawaii-face__eyes__circle"
          fill={color}
          transform="translate(2.068)"
        >
          <circle id="Oval-3" cx="3.103" cy="3.103" r="3.103" />
          <circle id="Oval-3" cx="38.956" cy="3.103" r="3.103" />
        </g>
      }
      {mood === 'lovestruck' &&
        <path d={paths.lovestruckEyes} id="Shape" />
       }
    </g>
  </g>
);

KawaiiFace.propTypes = {
  mood: PropTypes.oneOf(KAWAII_MOODS),
  color: PropTypes.string,
};

KawaiiFace.defaultProps = {
  mood: 'happy',
  color: '#C3C9D2',
};

export default KawaiiFace;
