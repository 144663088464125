import React, { createContext } from 'react';

const MessagesContext = createContext({});
const MessagesConsumer = MessagesContext.Consumer;

export {
  MessagesConsumer,
};

export default MessagesContext.Provider;
