import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ElementPopover from '../../components/ElementPopover';

import './_brand-picker.scss';

const componentName = 'brand-picker';

const propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.number.isRequired, // brand id
  dark: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
  dark: false, // CAD
};

const BrandPicker = ({ options, value, dark, onChange, className }) => {
  const onSelectItem = (itemValue) => {
    return onChange(itemValue);
  };

  const renderToggleElement = () => {
    const selected = options.find((item) => item.id === value);

    return (
      <div
        className={classnames(`${componentName}__toggle`, {
          [`${componentName}__toggle--dark`]: dark,
        })}
      >
        <i
          className={classnames(
            `${componentName}__brand-icon`,
            'icon-brand-selector',
          )}
        />
        <span className={`${componentName}__name`}>{selected.name}</span>
        <i
          className={classnames(
            `${componentName}__dropdown-icon`,
            'icon-chevron-down',
          )}
        />
      </div>
    );
  };

  const renderContentElement = (
    <ul className={`${componentName}__list`}>
      {options.map((item) => (
        <li
          key={item.id}
          className={`${componentName}__item`}
          onClick={() => onSelectItem(item.id)}
        >
          {item.name}
          {item.id === value && (
            <i
              className={classnames(
                `${componentName}__selected-icon`,
                'icon-checkmark',
              )}
            />
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <ElementPopover
      className={classnames(className, componentName)}
      toggleElement={renderToggleElement}
      contentElement={renderContentElement}
      contentClassName={`${componentName}__content`}
      attachment={'right'}
    />
  );
};

BrandPicker.propTypes = propTypes;
BrandPicker.defaultProps = defaultProps;

export default BrandPicker;
